import React from 'react';
import styled from 'styled-components/macro';
import { text } from '../../utils';
import { observer } from 'mobx-react-lite';
import themeStore from '../../store/ThemeStore';
import heart from '../Icons/heart.svg';
// import person from '../Icons/person.svg';
import euro from '../Icons/euro.svg';
import { withRouter } from 'react-router';
import appStore from '../../store/AppStore';
import { mediaSmallMax, mediaMediumMin } from '../Styled/media';

const StyledOrderConfirmationInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 20rem);
  grid-gap: 3rem;
  justify-content: space-between;
  grid-auto-flow: rows;
  width: 100%;
  padding: 3rem;

  @media ${mediaSmallMax} {
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    flex-direction: column;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${mediaSmallMax} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
  }

  @media ${mediaMediumMin} {
    min-width: 17rem;
    max-width: 17rem;
    align-items: stretch;

    &:not(:first-child) {
      margin-left: 2rem;
    }
  }
`;

const InfoBoxIconContainer = styled.div`
  background-color: var(--custom-colorMain);
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 100%;

  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media ${mediaSmallMax} {
    width: 40px;
    height: 40px;
    margin-right: 1rem;

    img {
      height: 50%;
    }
  }

  @media ${mediaMediumMin} {
    margin-bottom: 2rem;
  }
`;

const InfoBoxName = styled.h3`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  font-size: 25px;
  line-height: 28px;
  position: relative;

  @media ${mediaSmallMax} {
    font-size: 18px;
    line-height: 21px;

    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 20rem;
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--custom-colorAccent3);
    opacity: 0.5;
    content: '';
  }
`;

const InfoBoxDataContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${mediaMediumMin} {
    width: 100%;
    align-items: stretch;
  }
`;

// const ContactEmail = styled.a`
//   margin-top: 0.75rem;
//   border-bottom: none;
//   font-weight: 300;
//   font-family: var(--custom-fontNameLight);
//   font-size: 18px;
// `;

// const ContactPhone = styled.a`
//   border-bottom: none;
//   font-family: var(--custom-fontNameLight);
//   font-weight: 300;
//   font-size: 18px;
// `;

const OrderConfirmationInfo = () => (
  <StyledOrderConfirmationInfo>
    {/* <InfoBox>
      <InfoBoxIconContainer>
        <img src={person} alt="person-icon" />
      </InfoBoxIconContainer>
      <InfoBoxName className="font-normal">
        {text('customerServiceEngineer')}
      </InfoBoxName>
      <p className="font-normal">Minna Meikäläinen</p>
      <ContactEmail href={`mailto:minna.meikalainen@gbuilder.fi`}>
        minna.meikalainen@gbuilder.fi
      </ContactEmail>
      <ContactPhone href={`tel:0401234567`}>0401234567</ContactPhone>
    </InfoBox> */}
    <InfoBox>
      <InfoBoxIconContainer>
        <img src={heart} alt="heart-icon" />
      </InfoBoxIconContainer>
      <InfoBoxDataContainer>
        <InfoBoxName className="font-normal">{text('yourTheme')}</InfoBoxName>
        <p className="font-light">{`${text('themeBase')}: ${
          themeStore.selectedTheme ? themeStore.selectedTheme.name : ''
        }`}</p>
      </InfoBoxDataContainer>
    </InfoBox>
    <InfoBox>
      <InfoBoxIconContainer>
        <img src={euro} alt="euro-icon" />
      </InfoBoxIconContainer>
      <InfoBoxDataContainer>
        <InfoBoxName className="font-normal">{text('totalPrice')}</InfoBoxName>
        {appStore.apartment && (
          <p className="font-light">
            {appStore.apartment.cost} {appStore.currencySymbol}
          </p>
        )}
      </InfoBoxDataContainer>
    </InfoBox>
  </StyledOrderConfirmationInfo>
);

export default (withRouter as any)(observer(OrderConfirmationInfo));
