import i18n from 'i18next';
import axios from 'axios';
import BundleState from '../store/enums/BundleState';
import { isValidUrl, text } from '../utils';
import confirmationDialogStore, {
  ConfirmationDialogTexts,
} from '../store/ConfirmationDialogStore';
import moment from 'moment';

declare const global: any;

export const baseURL = process.env.REACT_APP_CORE_API_URL
  ? process.env.REACT_APP_CORE_API_URL
  : global.env
  ? global.env.CORE_API_URL
  : '';

const redirectUrl = process.env.REACT_APP_PORTAL_URL
  ? process.env.REACT_APP_PORTAL_URL
  : global.env
  ? global.env.PORTAL_URL
  : '';

const headers = {
  'accept-language': i18n.language || 'en',
  'gb-client': 'CJ2',
};

export const apiV6PrivatePath = (
  organisationId: string,
  tenantId: string,
  projectId: string
) => {
  return `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}`;
};

const instance = axios.create({ baseURL, headers });
const tenantSettingsInstance = axios.create({ baseURL, headers });

export const setAuthToken = (token: string) => {
  instance.defaults.headers.common.Authorization = 'Bearer ' + token;
  tenantSettingsInstance.defaults.headers.common.Authorization =
    'Bearer ' + token;
};
export const removeAuthToken = () => {
  delete instance.defaults.headers.common.Authorization;
  delete tenantSettingsInstance.defaults.headers.common.Authorization;
};

export const fetchTenantSettings = async (
  organisationId: string,
  tenantId: string
) => {
  tenantSettingsInstance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 403 && isValidUrl(redirectUrl)) {
        const redirect = () => window.location.replace(redirectUrl);

        const texts: ConfirmationDialogTexts = {
          confirmationText: text('sessionExpiredText'),
          okText: text('loginAgain'),
        };
        confirmationDialogStore.open(texts, redirect);
      }
      return Promise.reject(error);
    }
  );

  const resp = await tenantSettingsInstance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/settings`
  );

  return resp.data;
};

export const fetchOrganisationSettings = async (organisationId: string) => {
  const resp = await instance.get(
    `/api/v3/public/organisations/${organisationId}`
  );

  return resp.data;
};

export const fetchApartmentInfoForUser = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartmentId: string,
  includeMessageStats: boolean = false
) => {
  const response = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/users/null?includeMessageStats=${includeMessageStats}`
  );
  return response.data;
};

export const fetchApartmentImages = async (
  organisationId: string,
  tenantId: string,
  apartmentId: string
) => {
  const response = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/images/apartment/${apartmentId}`
  );

  return response.data;
};

export const fetchRooms = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartmentId: string
) => {
  const response = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}?rooms=true`
  );
  return response.data;
};

export const fetchRoomMessages = async (roomId: number) => {
  const response = await instance.get(
    `/api/v1/private/rooms/${roomId}/messages/`
  );
  return response.data.data;
};

export const fetchMassMessages = async (apartmentId: number) => {
  const response = await instance.get(
    `/api/v1/private/apartments/${apartmentId}/mass-messages/`
  );
  return response.data.data;
};

export const postRoomMessage = async (
  roomId: number,
  messageType: string,
  messageContent: string,
  locale: string
) => {
  const response = await instance.post(
    `/api/v1/private/rooms/${roomId}/messages/`,
    {
      messageType,
      messageContent,
      locale,
    }
  );

  return response.data;
};

export const fetchApartmentOrRoomBundles = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartment: number,
  roomIds?: number[],
  includeApartmentBundles = false,
  includeRoomBundles = false
) => {
  const response = await instance.post(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartment}/bundles`,
    {
      roomIds: roomIds && !!roomIds.length ? roomIds : undefined,
      includeMaterialOptions: true,
      includeApartmentBundles,
      includeRoomBundles,
    }
  );

  return response.data;
};

export const fetchBundlesByTheme = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartment: number,
  packageIds?: number[],
  sortByDeadlines = false
) => {
  const response = await instance.post(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartment}/bundlesByPackages`,
    {
      includeMaterialOptions: true,
      packageIds,
      sortByDeadlines,
    }
  );

  return response.data;
};

export const selectMaterialForBundle = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartment: number,
  bundleId: number,
  projectMaterialId: number | null
) => {
  const response = await instance.put(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartment}/users/null/bundles/${bundleId}/select/${projectMaterialId}`
  );
  return response.data;
};

export const fetchThemes = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartmentId: string
) => {
  const response = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/packages`
  );

  return response.data;
};

export const fetchProjectName = async (projectId: string) => {
  const response = await instance.get(
    `/api/v3/private/projects/${projectId}/apartments`
  );
  return response.data;
};

export const confirmUserBundleSelection = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartmentId: string,
  state: BundleState,
  bundleIds: number[]
) => {
  const printInfo = {
    printDate: moment().format(),
  };

  const response = await instance.post(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/user-bundle-selections/confirm`,
    {
      bundleIds,
      state,
      printInfo,
    }
  );

  return response.data;
};

export const selectPackage = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartmentId: string,
  packageId: number
) => {
  const response = await instance.put(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/users/null/packages/${packageId}/select`
  );

  return response.data;
};

export const deselectPackagesFromPackageSet = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartmentId: string,
  packageSetId: number
) => {
  const response = await instance.post(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/package_sets/${packageSetId}/deselect`
  );

  return response.data;
};

export const fetchAttachments = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartmentId: string
) => {
  const response = await instance.get(
    `${apiV6PrivatePath(
      organisationId,
      tenantId,
      projectId
    )}/cj/docs-and-links?apartmentId=${apartmentId}`
  );

  return response.data;
};

export const readMassmessage = async (
  apartmentId: string,
  massMessageId: string
) => {
  const response = await instance.put(
    `/api/v3/private/apartments/${apartmentId}/mass-messages/${massMessageId}/received`
  );

  return response.data;
};

export const fetchContacts = async (
  organisationId: string,
  tenantId: string,
  projectId: string
) => {
  const response = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/cj/contacts`
  );

  return response.data;
};

export const changeHasReviewedOptions = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartmentId: string,
  packageId: number,
  hasReviewedOptions: boolean
) => {
  const response = await instance.put(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/packages/${packageId}/has_reviewed_options`,
    {
      hasReviewedOptions,
    }
  );

  return response.data;
};

export const fetchVersionInfo = async () => {
  const resp = await instance.get(`/api/healthcheck`);

  return resp.data;
};

export const fetchEvents = async (
  organisationId: string,
  tenantId: string,
  projectId: number | string
) => {
  const response = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/calendar-events`
  );
  return response.data;
};

export const fetchBundleDeadlineEvents = async (
  organisationId: string,
  tenantId: string,
  projectId: number | string,
  apartmentId: number | string
) => {
  const response = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/bundle-deadline-events`
  );
  return response.data;
};

export const fetchVisualisationUrl = async (
  organisationId: string,
  tenantId: string,
  projectId: number | string,
  apartmentId: number | string,
  lang: string | undefined
) => {
  const response = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/visualisation-url?lang=${lang}`
  );
  return response.data;
};

export const fetchApartmentOffers = async (
  organisationId: string,
  tenantId: string,
  projectId: number | string,
  apartmentId: number | string
) => {
  try {
    const response = await instance.get(
      `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartment/${apartmentId}/offers`
    );
    return response.data;
  } catch (err) {
    const status = Number(err.response?.data?.error?.status);
    if (status === 403) {
      return { data: { items: [] } };
    }
    throw err;
  }
};

export const printOffer = async (
  organisationId: string,
  tenantId: string,
  projectId: number | string,
  offerId: number | string
) => {
  const resp = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/offers/${offerId}/print`,
    { responseType: 'arraybuffer' }
  );
  return resp.data;
};

export interface ApiOfferRowStateFields {
  acceptedRowIds: number[];
  rejectedRowIds: number[];
}

export const updateOfferRowStates = async (
  organisationId: string,
  tenantId: string,
  projectId: number | string,
  offerId: number | string,
  data: Partial<ApiOfferRowStateFields>
) => {
  const resp = await instance.post(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/offers/${offerId}/rows/states`,
    data
  );

  return resp.data;
};

export const getEsignDocumentFileContent = async (esignDocumentId: number) => {
  const resp = await instance.get(
    `/api/v1/private/esign/document/${esignDocumentId}/file`,
    { responseType: 'arraybuffer' }
  );
  return resp.data;
};

export const fetchUserRights = async (
  organisationId: string,
  tenantId: string,
  projectId: number | string
) => {
  const response = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/user-rights`
  );
  return response.data;
};

export const getUserLevel = async (
  organisationId: string,
  tenantId: string,
  projectId: string
) => {
  const response = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}`
  );

  return response.data;
};

export const getAttachmentsBySection = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  apartmentId: string,
  sectionId?: number
) => {
  let path = `${apiV6PrivatePath(
    organisationId,
    tenantId,
    projectId
  )}/attachments//apartment/${apartmentId}/sections/`;

  if (sectionId) path += sectionId;

  const response = await instance.get(path);

  if (response.data.error)
    throw new Error('Failed to get attachments by section');

  return response.data.data;
};

export const signDocument = async (
  organisationId: string,
  tenantId: string,
  projectId: string,
  attachmentId: number,
  xfdf: string
) => {
  const path = `${apiV6PrivatePath(
    organisationId,
    tenantId,
    projectId
  )}/attachments/${attachmentId}/sign`;

  const { data } = await instance.put(path, { xfdf });

  if (data.error) throw new Error('Unable to sign document!');

  return data;
};

export const getZipAttachments = async (
  organisationId: string,
  tenantId: string,
  projectId: string
) => {
  const resp = await instance.get(
    `/api/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/zip`,
    {
      responseType: 'arraybuffer',
    }
  );
  if (resp.status !== 200) {
    throw new Error('Failed to get ZIP file');
  }
  return resp.data;
};

export default instance;
