import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { OfferRow } from '../../store/models/OfferRow';
import appStore from '../../store/AppStore';
import lightBoxStore from '../../store/LightBoxStore';
import { modalView } from '../../services';
import { ModalView } from '../../services/ModalView';
import {
  BundleInfo,
  ConfirmationBundle,
  ImageContainer,
  StyledImageWrapper,
} from '../ConfirmationPhases/BundlesConfirmation';
import { text } from '../../utils';
import { MaterialImage } from '../Materials/MaterialImage';
import Button from '../Button/Button';
import CheckSolid from '../Icons/CheckSolid';
import Close from '../Icons/Close';
import { OffersStore } from '../../store/OffersStore';
import OfferRowState from '../../store/enums/OfferRowState';
import { mediaSmallMax } from '../Styled/media';
import InfoIcon from '../Icons/Info';

const StyledInfoIcon = styled(InfoIcon)`
  margin: 0.5rem 0 0 1rem;
`;

const ButtonsContainer = styled.div`
  margin: auto 24px auto auto;

  @media ${mediaSmallMax} {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 100vw;
    justify-content: space-evenly;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Footer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
`;

const StyledButtonText = styled.p`
  margin: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  white-space: nowrap;

  @media ${mediaSmallMax} {
    font-size: 16px;
  }
`;

const StyledCheck = styled(CheckSolid)<{
  selected: boolean;
  disabled: boolean;
}>`
  fill: ${({ disabled, selected }) =>
    disabled ? 'gray' : selected ? 'white' : '#3F9C35'};
  margin-right: 5px;
  width: 24px;
  height: 24px;
`;

const StyledClose = styled(Close)<{ selected: boolean; disabled: boolean }>`
  fill: ${({ disabled, selected }) =>
    disabled ? 'gray' : selected ? 'white' : 'var(--custom-colorNotif1)'};
  margin-right: 5px;
  width: 24px;
  height: 24px;
`;

const ActionButton = styled(Button)`
  padding: 0 1.5rem;
  height: 48px;
  background: #ffffff;
  border: 1px solid var(--custom-colorAccent2);
  width: 210px;
  box-sizing: border-box;
  margin-left: 1rem;

  @media ${mediaSmallMax} {
    width: 45vw;
    margin: 0;
    padding: 0 0.5rem;
  }
`;

const SelectButton = styled(ActionButton)<{
  selected: boolean;
  disabledStyle?: boolean;
}>`
  border: ${({ disabledStyle, selected }) =>
    disabledStyle
      ? '1px solid gray'
      : selected
      ? '1px solid #3F9C35'
      : '1px solid var(--custom-colorAccent2)'};
  background: ${({ selected }) => (selected ? '#3F9C35' : 'white')};
  color: ${({ disabledStyle, selected }) =>
    disabledStyle ? 'gray' : selected ? 'white' : 'var(--custom-colorAccent)'};
`;

const DiscardButton = styled(ActionButton)<{
  selected: boolean;
  disabledStyle?: boolean;
}>`
  border: ${({ disabledStyle, selected }) =>
    disabledStyle
      ? '1px solid gray'
      : selected
      ? '1px solid var(--custom-colorNotif1)'
      : '1px solid var(--custom-colorAccent2)'};
  background: ${({ selected }) =>
    selected ? 'var(--custom-colorNotif1)' : 'white'};
  color: ${({ disabledStyle, selected }) =>
    disabledStyle ? 'gray' : selected ? 'white' : 'var(--custom-colorAccent)'};
`;

const Header = styled.div<{ rowState: string }>`
  display: flex;
  margin: auto;
  background: ${({ rowState }) =>
    rowState === OfferRowState.ACCEPTED ||
    rowState === OfferRowState.APPLIED_TO_DATABASE
      ? '#3F9C35'
      : rowState === OfferRowState.REJECTED
      ? 'lightgray'
      : 'var(--custom-colorAccent2)'};
  margin-top: 1rem;

  & p {
    color: ${({ rowState }) =>
      rowState === OfferRowState.REJECTED
        ? 'var(--custom-colorAccent2)'
        : 'white'};
  }
`;

const RowName = styled.p`
  font-size: 18px;
  font-weight: 300;
  color: #ffff;
  margin: 1rem 3rem 1rem 3rem;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${mediaSmallMax} {
    margin: 1rem;
  }
`;

const RowDescriptionContainer = styled.div`
  background: #f4f4f4;
  display: flex;
  flex-direction: row;
`;

const ChangesContainer = styled.div`
  padding: 1rem 0 0 0;
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;

  @media ${mediaSmallMax} {
    border-left: none;
  }
`;

const StyledBundleInfo = styled(BundleInfo)`
  margin: auto;
`;

const StyledConfirmationBundle = styled(ConfirmationBundle)`
  padding-left: 1rem;
`;

const StyledSelectedMaterialPrice = styled.p<{
  rejected?: boolean;
  visibleOnMobile: boolean;
}>`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: ${({ rejected }) =>
    rejected ? 'gray' : 'var(--custom-colorAccent2)'};
  text-decoration: ${({ rejected }) => (rejected ? 'line-through' : 'none')};
  display: ${({ visibleOnMobile }) => (!visibleOnMobile ? 'block' : 'none')};
  }
  @media ${mediaSmallMax} {
    display: ${({ visibleOnMobile }) => (visibleOnMobile ? 'block' : 'none')};
    font-size: 16px;
  }
`;
interface OfferRowsProps {
  offersStore: OffersStore;
  row: OfferRow;
  offerId: number | string;
}

const OfferRows = ({ offersStore, row, offerId }: OfferRowsProps) => {
  const acceptedRows = offersStore.getAcceptedRows(offerId);

  const [selected, setSelected] = useState(
    acceptedRows && acceptedRows.includes(row) ? true : false
  );
  const [discarded, setDiscarded] = useState(!selected);

  if (!!row.changes) {
    return (
      <>
        <Header rowState={row.state ? row.state : ''}>
          {row.isAccepted && (
            <StyledCheck
              style={{ margin: 'auto 0 auto 1rem', fill: 'white' }}
            />
          )}
          {row.isRejected && (
            <StyledClose style={{ margin: 'auto 0 auto 1rem' }} />
          )}
          <RowName>{row.name}</RowName>
        </Header>
        <div style={{ position: 'relative' }}>
          {row.description && (
            <RowDescriptionContainer>
              <div style={{ textAlign: 'center', width: '50px' }}>
                <StyledInfoIcon fill="var(--custom-colorAccent)" />
              </div>
              <p style={{ margin: '1rem' }}>{row.description}</p>
            </RowDescriptionContainer>
          )}
          <ChangesContainer>
            {row.changes.map(change => (
              <StyledConfirmationBundle key={row.id + change.id}>
                <ImageContainer>
                  <StyledImageWrapper>
                    {change.projectMaterial && (
                      <MaterialImage
                        style={{ cursor: 'pointer' }}
                        src={change.projectMaterial.imageUrlSmall}
                        onClick={() => {
                          if (change.projectMaterial) {
                            modalView(ModalView.MATERIAL_IMAGE);
                            lightBoxStore.open([
                              change.projectMaterial.imageUrl,
                            ]);
                          }
                        }}
                      />
                    )}
                  </StyledImageWrapper>
                  <StyledSelectedMaterialPrice
                    rejected={row.isRejected}
                    visibleOnMobile={true}
                  >
                    {text('price')}: {row.totalGrossPrice}
                    {appStore.currencySymbol}
                  </StyledSelectedMaterialPrice>
                </ImageContainer>
                <StyledBundleInfo>
                  <p style={{ color: '#294754', fontSize: '18px !important' }}>
                    {change.projectMaterial
                      ? change.bundle.name
                      : text('noSelectionText')}
                  </p>

                  <p className="font-medium" style={{ color: '#294754' }}>
                    {change.projectMaterial
                      ? change.projectMaterial.name
                      : text('noSelectionText')}
                  </p>
                  <p className="font-normal" style={{ color: '#294754' }}>
                    {change.projectMaterial &&
                      change.projectMaterial.description}
                  </p>
                </StyledBundleInfo>
              </StyledConfirmationBundle>
            ))}
          </ChangesContainer>
        </div>
        <Footer>
          <StyledSelectedMaterialPrice
            rejected={row.isRejected}
            className="visible-on-desktop"
            visibleOnMobile={false}
          >
            {text('price')}: {row.totalGrossPrice}
            {appStore.currencySymbol}
          </StyledSelectedMaterialPrice>
          {row.isOpen && (
            <ButtonsContainer>
              <DiscardButton
                disabledStyle={selected}
                selected={discarded}
                onClick={() => {
                  setSelected(false);
                  offersStore.rejectOfferRow(offerId, row);
                  setDiscarded(true);
                }}
              >
                <ButtonContent>
                  <StyledClose disabled={selected} selected={discarded} />
                  <StyledButtonText>
                    {discarded ? text('rejected') : text('discardChange')}
                  </StyledButtonText>
                </ButtonContent>
              </DiscardButton>
              <SelectButton
                disabledStyle={discarded}
                selected={selected}
                onClick={() => {
                  setDiscarded(false);
                  offersStore.acceptOfferRow(offerId, row);
                  setSelected(true);
                }}
              >
                <ButtonContent>
                  <StyledCheck disabled={discarded} selected={selected} />
                  <StyledButtonText>
                    {selected ? text('selected') : text('select')}
                  </StyledButtonText>
                </ButtonContent>
              </SelectButton>
            </ButtonsContainer>
          )}
        </Footer>
      </>
    );
  } else {
    return null;
  }
};

export default observer(OfferRows);
