import React, { Fragment, useState } from 'react';
import styled from 'styled-components/macro';
import { RoomWithKey } from '../../store/MaterialSelectorStore';
import materialSelectorStore from '../../store/MaterialSelectorStore';
import Bundle from '../../store/models/Bundle';
import ConfirmationRoom from './ConfirmationRoom';
import { text, decimalToLocale, isOpen } from '../../utils';
import { observer } from 'mobx-react-lite';
import appStore from '../../store/AppStore';
import { SectionArrow } from '../Styled/SectionTitleDivider';
import {
  RoomDivider,
  ConfirmationPhaseTitle,
  StyledSectionResizer,
  PhaseHeader,
} from './ConfirmationPhasesStyledComponents';
import { FormattedTextSection } from '../Styled/FormattedTextSection';
import confirmationDialogStore from '../../store/ConfirmationDialogStore';
import userRightsStore from '../../store/UserRightsStore';
import { getTotalPrice } from './utils';
import { OrderConfirmationButton } from './styledComponents';

const EsignGuidance = styled(FormattedTextSection)`
  margin: 40px auto 0 auto;
  width: 50%;
  text-align: center;
  line-height: 1.5rem;
`;

const StyledRoomDivider = styled(RoomDivider)<{
  deadlineIsNear?: boolean;
  deadlineGone?: boolean;
  bundlesLocked?: boolean;
}>`
  background-color: ${({ bundlesLocked, deadlineIsNear, deadlineGone }) =>
    bundlesLocked
      ? 'var(--color-lightGray)'
      : !deadlineGone && deadlineIsNear
      ? '#db4d69'
      : !deadlineGone && !deadlineIsNear
      ? 'var(--custom-colorAccent2)'
      : 'var(--color-lightGray)'};
  color: ${({ bundlesLocked, deadlineGone }) =>
    bundlesLocked || deadlineGone ? 'var(--custom-colorAccent2)' : 'white'};

  & svg {
    fill: ${({ bundlesLocked, deadlineGone }) =>
      bundlesLocked || deadlineGone ? 'var(--custom-colorAccent2)' : 'white'};
  }
`;

const StyledBanner = styled.div`
  text-align: center;
  &.sticky {
    background-color: white;
    position: sticky;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    border-top: 1px solid gray;
  }
`;

const RoomsContainer = styled.div`
  transition: all 0.5s ease-in-out;
`;

interface ConfirmationPhaseProps {
  rooms: RoomWithKey[];
  deadline?: string;
  apartmentBundles: Bundle[];
  confirmBy: string;
  confirm?: (ids: number[], totalPrice: string) => void;
  locked?: boolean;
  openAtStart?: boolean;
  deadlineIsNear?: boolean;
  deadlineGone?: boolean;
  bundlesLocked?: boolean;
  lockingByDeadline?: boolean;
  withoutDeadline?: boolean;
}

const ConfirmationPhase = ({
  rooms,
  deadline,
  apartmentBundles,
  confirmBy,
  confirm,
  openAtStart,
  deadlineIsNear,
  deadlineGone,
  bundlesLocked,
  lockingByDeadline = true,
  withoutDeadline = false,
}: ConfirmationPhaseProps) => {
  const [isDropdownOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState('48px');

  // Created this function out of despair since I've been investigating this bug the whole day
  // TODO: Refactor the whole confirmation components logic, since it is a huge mess and has a lot of duplicated logic.
  // If we are NOT locking by deadline, deadlines have no use here.

  const warningModalTexts = {
    confirmationTitle: text('selectionsModal.attention'),
    confirmationText: text('selectionsModal.modalText'),
    okText: text('selectionsModal.doItNow'),
    cancelText: text('selectionsModal.doItLater'),
  };

  const considerBundle = (bundle: Bundle) =>
    !lockingByDeadline ||
    bundle.deadline === deadline ||
    (!bundle.deadline && !deadline);

  const bundles: Bundle[] =
    confirm && !deadlineGone
      ? rooms
          .reduce((pre, cur) => {
            pre.push(
              ...cur.openBundles.filter(b => {
                return (
                  considerBundle(b) &&
                  !b.isGeneralAppearanceMaterial &&
                  !b.isPartOfOffer
                );
              })
            );

            return pre;
          }, [] as Bundle[])
          .concat(apartmentBundles.filter(isOpen))
      : [];

  const bundleIds = bundles.map(b => b.id);
  const totalPrice = getTotalPrice(bundles);

  return (
    <Fragment key={deadline || 'later'}>
      <PhaseHeader style={{ maxHeight: 'auto', minHeight: maxHeight }}>
        <StyledSectionResizer
          effectListeners={[isDropdownOpen, 3]}
          setNewHeight={setMaxHeight}
        >
          <StyledRoomDivider
            deadlineIsNear={deadlineIsNear}
            deadlineGone={deadlineGone}
            bundlesLocked={bundlesLocked}
            open={isDropdownOpen}
            onClick={async () => {
              if (isDropdownOpen && !deadlineGone && !bundlesLocked) {
                confirmationDialogStore.open(
                  warningModalTexts,
                  () => {
                    return null;
                  },
                  () => {
                    setIsOpen(!isDropdownOpen);
                  }
                );
              } else {
                setIsOpen(!isDropdownOpen);
              }
            }}
          >
            <ConfirmationPhaseTitle>{confirmBy}</ConfirmationPhaseTitle>
            <SectionArrow open={isDropdownOpen} />
          </StyledRoomDivider>
          {isDropdownOpen && (
            <RoomsContainer>
              {!!apartmentBundles.length && (
                <ConfirmationRoom
                  key="apartment"
                  bundles={apartmentBundles}
                  roomName={text('generalAppearance')}
                  openAtStart={true}
                />
              )}
              {rooms &&
                rooms.map(room => {
                  const roomBundles = bundlesLocked
                    ? room.lockedBundles.filter(
                        b =>
                          considerBundle(b) &&
                          b.roomIds.length === 1 &&
                          !b.isPartOfOffer
                      )
                    : deadlineGone
                    ? room.deadlineGoneBundles.filter(
                        b =>
                          considerBundle(b) &&
                          b.roomIds.length === 1 &&
                          !b.isPartOfOffer
                      )
                    : withoutDeadline
                    ? room.openBundlesWithNoDl.filter(
                        b =>
                          considerBundle(b) &&
                          b.roomIds.length === 1 &&
                          !b.isPartOfOffer
                      )
                    : room.openBundles.filter(
                        b =>
                          considerBundle(b) &&
                          b.roomIds.length === 1 &&
                          !b.isPartOfOffer
                      );
                  return !!roomBundles.length ? (
                    <ConfirmationRoom
                      key={room.key || `locked-${room.id}`}
                      bundles={roomBundles}
                      roomName={room.name}
                      openAtStart={true}
                    />
                  ) : null;
                })}

              {confirm &&
                !deadlineGone &&
                !!bundleIds.length &&
                !bundlesLocked &&
                userRightsStore.check(
                  'bundles:browse:options:selection:can_set_to:state:locked'
                ) && (
                  <StyledBanner className={!lockingByDeadline ? 'sticky' : ''}>
                    <Fragment>
                      {appStore.tenantSettings
                        .esign_requireForMaterialLocking &&
                        !lockingByDeadline && (
                          <EsignGuidance>{text('esignGuidance')}</EsignGuidance>
                        )}
                      <OrderConfirmationButton
                        className="larger-mobile"
                        disabled={materialSelectorStore.fetchingBundles}
                        onClick={() =>
                          confirm(
                            bundleIds,
                            `${decimalToLocale(totalPrice)} ${
                              appStore.currencySymbol
                            }`
                          )
                        }
                      >
                        {lockingByDeadline
                          ? text('confirmTheSelections')
                          : text('confirmAllSelections')}
                      </OrderConfirmationButton>
                    </Fragment>
                  </StyledBanner>
                )}
            </RoomsContainer>
          )}
        </StyledSectionResizer>
      </PhaseHeader>
    </Fragment>
  );
};

export default observer(ConfirmationPhase);
