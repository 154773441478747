import { observable, action, computed } from 'mobx';
import axios, {
  fetchTenantSettings,
  fetchProjectName,
  fetchApartmentInfoForUser,
  fetchApartmentImages,
  fetchOrganisationSettings,
  fetchVisualisationUrl,
} from '../axios';
import Decimal from 'decimal.js';
import QuantityType from './interfaces/QuantityType';
import VisibilityLevel from './interfaces/VisibilityLevel';
import { d, text, preloadImages } from '../utils';
import getSymbolFromCurrency from 'currency-symbol-map';
import Apartment from './models/Apartment';
import OrganisationCustomisation from './models/OrganisationCustomisation';
import OrganisationSettings, {
  OrganisationSettingsInterface,
} from './models/OrganisationSettings';
import alertStore from './AlertStore';
import {
  StartUrlParameters,
  StartUrlParametersArray,
} from './enums/UrlParameters';
import { getParams, changeParams } from './RoutingStore';
import { ParsedQuery } from 'query-string';
import authStore from './AuthStore';
import ImageInterface from './interfaces/ImageInterface';
import messagesStore from './MessagesStore';
import organisationCustomisations from '../organisationCustomisations';
import themeStore from './ThemeStore';
import TenantSettings, {
  TenantSettingsInterface,
} from './models/TenantSettings';
import { triggerGAError, initializeGA, initializeHJ } from '../services';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import { Tracker } from 'react-ga';
import OrganisationTracking from './interfaces/OrganisationTracker';
import roomsStore from './RoomsStore';
import calendarStore from './CalendarStore';
import offersStore from './OffersStore';
import userRightsStore from './UserRightsStore';

export class AppStore {
  @observable public apartmentId: string = '';
  @observable public tenantId: string = '';
  @observable public organisationId: string = '';
  @observable public projectId: string = '';
  @observable public accessToken: string = '';
  @observable public gbToken: string = '';
  @observable public lang: string = '';

  @observable public currencyCode: string = 'EUR';
  @observable public environmentLocale: string = navigator.language;
  @observable public materialPriceCommission: Decimal = new Decimal(0);
  @observable public materialPriceVat: Decimal = new Decimal(0);
  @observable public organisationName: string = '';
  @observable public quantityTypes: QuantityType[] = [];
  @observable public timeZone: string = 'Europe/Helsinki';
  @observable public useMessaging: boolean = false;
  @observable public useTheme: boolean = false;
  @observable public visibilityLevels: VisibilityLevel[] = [];
  @observable public projectName = '';
  @observable public apartment?: Apartment;
  @observable public apartmentImages?: ImageInterface[];
  @observable public bimVaultApiUrl: string = '';
  @observable public gb4dUrl: string = '';

  @observable public defaultGATracker?: Tracker;
  @observable public GATrackers: Tracker[] = [];
  @observable public hotjarIds: number[] = [];
  @observable public giosgIds: number[] = [];

  @observable public tenantSettingsFetched = false;
  @observable public organisationSettingsFetched = false;
  @observable public apartmentInfoFetched = false;
  @observable public apartmentImagesFetched = false;
  @observable public projectNameFetched = false;
  @observable public trackersInitialized = false;
  @observable public userRightsFetched = false;

  @observable public userHasAcceptedCookiesKey = 'cj2_userHasAcceptedCookies';
  @observable public userHasAcceptedCookies = false;

  @observable
  public organisationCustomisation: OrganisationCustomisation = new OrganisationCustomisation(
    organisationCustomisations.gbDefaultCustomisation
  );
  @observable
  public organisationSettings: OrganisationSettingsInterface = new OrganisationSettings(
    {}
  );
  @observable
  public tenantSettings: TenantSettingsInterface = new TenantSettings({});

  @observable public apartments: Apartment[] = [];

  constructor() {
    this.apartmentId =
      window.localStorage.getItem(StartUrlParameters.apartmentId) || '';

    this.tenantId =
      window.localStorage.getItem(StartUrlParameters.tenantId) || '';
    this.organisationId =
      window.localStorage.getItem(StartUrlParameters.organisationId) || '';
    this.projectId =
      window.localStorage.getItem(StartUrlParameters.projectId) || '';

    this.accessToken =
      window.localStorage.getItem(StartUrlParameters.accessToken) || '';

    this.gbToken =
      window.localStorage.getItem(StartUrlParameters.gbToken) || '';

    this.lang = window.localStorage.getItem(StartUrlParameters.lang) || 'en';
  }

  @action
  public start = async (params: ParsedQuery) => {
    const startParams = StartUrlParametersArray.reduce((pre, cur) => {
      pre[cur] = params[cur];
      return pre;
    }, {});

    const password = startParams[StartUrlParameters.password];
    const username = startParams[StartUrlParameters.username];

    this.tenantId = startParams[StartUrlParameters.tenantId];
    this.organisationId = startParams[StartUrlParameters.organisationId];
    this.accessToken = startParams[StartUrlParameters.accessToken];
    this.gbToken = startParams[StartUrlParameters.gbToken];

    if (password !== undefined && username !== undefined) {
      delete startParams[StartUrlParameters.password];
      delete startParams[StartUrlParameters.username];

      try {
        const { data } = await axios.post('/api/v1/public/accounts/login', {
          username,
          password,
          schema: this.tenantId,
        });

        authStore.setToken(data.token);
        window.localStorage.setItem(StartUrlParameters.accessToken, data.token);
      } catch (e) {
        console.error(e);
        alertStore.show(text('errors.loginFailed'));
      }
    } else if (this.accessToken) {
      // accessToken corresponds to auth0 token so it has to be exchanged into a gbToken, if any change is done here
      // check that gb-portal redirection and visitor links generated from CC are still working properly

      try {
        const { data } = await axios.get(
          `/api/v6/public/organisations/${this.organisationId}/tenants/${this.tenantId}/token-exchange`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        );
        authStore.setToken(data.data.token);
        window.localStorage.setItem(
          StartUrlParameters.accessToken,
          data.data.token
        );
      } catch (e) {
        triggerGAError('token exchange failed', e.toString());
        console.error(e);
        alertStore.show(text('errors.loginFailed'));
      }

      delete startParams[StartUrlParameters.accessToken];
    } else if (this.gbToken) {
      window.localStorage.setItem(StartUrlParameters.accessToken, this.gbToken);
      authStore.setToken(this.gbToken);
    }

    this.apartmentId = startParams[StartUrlParameters.apartmentId];
    this.projectId = startParams[StartUrlParameters.projectId];

    for (const key of Object.keys(startParams)) {
      if (startParams[key]) window.localStorage.setItem(key, startParams[key]);
    }

    changeParams(
      {
        [StartUrlParameters.gbToken]: undefined,
        [StartUrlParameters.accessToken]: undefined,
        [StartUrlParameters.apartmentId]: undefined,
        [StartUrlParameters.organisationId]: undefined,
        [StartUrlParameters.projectId]: undefined,
        [StartUrlParameters.password]: undefined,
        [StartUrlParameters.tenantId]: undefined,
        [StartUrlParameters.username]: undefined,
        [StartUrlParameters.lang]: undefined,
      },
      true
    );
  };

  @action
  public setup = async () => {
    const params = getParams();
    if (StartUrlParametersArray.some(p => !!params[p])) {
      await this.start(params);
    }

    if (Cookies.get(this.userHasAcceptedCookiesKey) === 'true') {
      this.userHasAcceptedCookies = true;
    }

    if (this.appIsReady) {
      if (!this.tenantSettingsFetched) await this.fetchTenantSettings();
      if (!this.organisationSettingsFetched)
        await this.fetchOrganisationSettings();
      if (!this.userRightsFetched) await userRightsStore.fetchUserRights();
      await userRightsStore.getUserLevel();
      if (!this.trackersInitialized) await this.initializeTrackers();
      if (!this.apartmentInfoFetched) await this.fetchApartmentInfo();
      if (!this.projectNameFetched) await this.fetchProjectName();
      if (!this.apartmentImagesFetched) await this.fetchApartmentImages();
      if (this.tenantSettings.customerJourney2_showFooter)
        messagesStore.fetchMessagesForAllRooms();
      calendarStore.fetchCalendarEvents();
      offersStore.fetchApartmentOffers();
    }

    // Set language
    if (!i18next.language) {
      i18next.changeLanguage(this.parseLangCode);
      window.location.reload();
    }
  };

  @action
  public fetchTenantSettings = async () => {
    try {
      const { data } = await fetchTenantSettings(
        this.organisationId,
        this.tenantId
      );

      this.tenantSettings = new TenantSettings(data);
      this.tenantSettingsFetched = true;

      this.bimVaultApiUrl = data.bimVaultApiUrl;
      this.gb4dUrl = data.gb4dUrl;
      this.currencyCode = data.currencyCode;
      this.environmentLocale = data.environmentLocale;
      this.materialPriceCommission = d(data.materialPriceCommission || 0);
      this.materialPriceVat = d(data.materialPriceVat || 0);
      this.organisationName = data.organisationName;
      this.quantityTypes = Object.values(data.quantityTypes);
      this.timeZone = data.timeZone;
      this.useMessaging = data.useMessaging;
      this.useTheme = data.useTheme;
      this.visibilityLevels = data.visibilityLevels;

      const defaultGATracker: OrganisationTracking = data.organisationTracking.find(
        (t: OrganisationTracking) =>
          t.isDefault && t.type === 'GOOGLE_ANALYTICS'
      );

      this.defaultGATracker = defaultGATracker
        ? {
            trackingId: defaultGATracker.trackingId,
          }
        : undefined;

      const otherGATrackers: OrganisationTracking[] = data.organisationTracking.filter(
        (t: OrganisationTracking) =>
          !t.isDefault && t.type === 'GOOGLE_ANALYTICS'
      );

      this.GATrackers = otherGATrackers.map(t => ({
        trackingId: t.trackingId,
        gaOptions: {
          name: t?.organisationTextId,
        },
      }));

      this.hotjarIds = data.organisationTracking.reduce(
        (pre: number[], t: OrganisationTracking) => {
          if (t.type === 'HOTJAR') pre.push(Number(t.trackingId));
          return pre;
        },
        [] as number[]
      );

      this.giosgIds = data.organisationTracking.reduce(
        (pre: number[], t: OrganisationTracking) => {
          if (t.type === 'GIOSG') pre.push(Number(t.trackingId));
          return pre;
        },
        [] as number[]
      );
    } catch (e) {
      triggerGAError('fetching tenant settings failed', e.toString());
      alertStore.show(text('errors.fetchingTenantSettingsFailed'));
    }
  };

  @action
  public fetchOrganisationSettings = async () => {
    const organisation = this.organisationId.toLowerCase();
    const { data: settings } = await fetchOrganisationSettings(
      this.organisationId
    );

    this.organisationSettingsFetched = true;
    const customisation = organisationCustomisations[organisation] || {};

    this.organisationSettings = new OrganisationSettings(settings);
    this.organisationCustomisation = new OrganisationCustomisation(
      customisation
    );
  };

  @action
  public fetchProjectName = async () => {
    if (this.apartment) {
      try {
        const { data } = await fetchProjectName(this.projectId);
        this.projectNameFetched = true;
        this.projectName = data.projectName;
      } catch (e) {
        triggerGAError('fetching project name failed', e.toString());
        alertStore.show(text('errors.fetchingProjectNameFailed'));
        console.error(e);
      }
    }
  };

  @action
  public fetchApartmentInfo = async () => {
    try {
      const { data } = await fetchApartmentInfoForUser(
        this.organisationId,
        this.tenantId,
        this.projectId,
        this.apartmentId,
        false
      );

      await roomsStore.fetchRooms();

      this.apartment = data && new Apartment(data);
      this.apartmentInfoFetched = true;
    } catch (e) {
      triggerGAError('fetching apartment info failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.fetchingApartmentInfoFailed'));
    }
  };

  @action fetchThreeDUrl = async () => {
    try {
      if (!this.apartment?.threeDUrl) {
        const lang = i18next.language;
        const { data: visualisationData } = await fetchVisualisationUrl(
          this.organisationId,
          this.tenantId,
          this.projectId,
          this.apartmentId,
          lang
        );
        const threeDUrl = visualisationData.url.shortLink;
        this.apartment?.setThreeDUrl(threeDUrl);
      }
    } catch (e) {
      triggerGAError('fetching 3D URL failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.fetchingApartmentInfoFailed'));
    }
  };

  @action
  public fetchApartmentImages = async () => {
    try {
      const { data } = await fetchApartmentImages(
        this.organisationId,
        this.tenantId,
        this.apartmentId
      );

      this.apartmentImages = data.images.sort((a: any, b: any) => {
        return a.sortNo - b.sortNo;
      });

      if (this.apartmentImages)
        preloadImages(this.apartmentImages.map(i => i.thumbnailUrl));

      this.apartmentImagesFetched = true;
    } catch (e) {
      triggerGAError('fetching apartment images failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.fetchingApartmentImagesFailed'));
    }
  };

  @action
  public initializeTrackers = async () => {
    try {
      if (this.defaultGATracker) {
        initializeGA([this.defaultGATracker, ...this.GATrackers]);
      } else {
        console.error(
          'Default GA tracker not set, GA trackers will not be initialized!'
        );
      }
      initializeHJ(this.hotjarIds);
      this.trackersInitialized = true;
    } catch (e) {
      triggerGAError('initializing trackers failed', e.toString());
      console.error(e);
      this.trackersInitialized = false;
    }
  };

  // Sets the cookie with values 'cj2_userHasAcceptedCookie', 'true'
  // and default expiration of 365 days
  @action setCookie = (key: string, value: string, expires: number = 365) => {
    Cookies.set(key, value, { expires });
    if (key === this.userHasAcceptedCookiesKey && value === 'true') {
      this.userHasAcceptedCookies = true;
    }
  };

  @computed
  get parseLangCode() {
    if (!this.lang) {
      return 'en';
    } else {
      return (this.lang.replace('_', '-').indexOf('-') === -1
        ? this.lang
        : this.lang.substring(0, 2)
      ).toLowerCase();
    }
  }

  @computed
  get currencySymbol() {
    return getSymbolFromCurrency(this.currencyCode);
  }

  @computed
  get apartmentName() {
    return `${this.projectName ? this.projectName : ''} ${
      this.apartment ? this.apartment.name : ''
    }`;
  }

  @computed
  get appIsReady() {
    return !!(this.apartmentId && this.tenantId && this.organisationId);
  }

  // Temporary to get floorplan from apartment images
  @computed
  get floorPlan() {
    const floorPlan =
      this.apartmentImages &&
      this.apartmentImages.find(
        i => i.name === 'pohjakuva' || i.name === 'Pohjakuva'
      );

    return floorPlan ? floorPlan.url : undefined;
  }

  @computed
  get headerImg() {
    return this.tenantSettings.customerJourney2_mainImage;
  }

  @computed
  get projectIdentifier(): string {
    return `${this.tenantId}_${this.projectId}`;
  }

  @computed
  get noThemesToSelect(): boolean {
    return themeStore.themesFetched && !themeStore.themeSets.length;
  }

  @computed
  get allGATrackers(): string[] {
    return this.GATrackers.map(t => t.gaOptions?.name).filter(
      i => !!i
    ) as string[];
  }
}

const appStore = new AppStore();

export default appStore;
