import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import {
  StyledFooter,
  FooterLinksContainer,
  FooterBtnContainer,
} from '../../components/Footer/FooterStyledComponents';
import Button from '../Button/Button';
import { text } from '../../utils';
import Theme from '../../store/models/Theme';
import { observer } from 'mobx-react-lite';
import debounce from 'lodash.debounce';
import modalStore from '../../store/ModalStore';

interface ConfirmationFooterProps {
  onButtonClick: () => void;
  selectedTheme: Theme;
  liftUp?: boolean;
}

export default observer(
  ({ onButtonClick, selectedTheme, liftUp }: ConfirmationFooterProps) => {
    const [footerHidden, setFooterHidden] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    useEffect(() => {
      const checkDirection = debounce((ev: Event) => {
        const st = window.pageYOffset || document.documentElement.scrollTop;

        const scrollingDown = st > lastScrollTop;

        const scrolledToBottom =
          window.innerHeight + window.scrollY >= document.body.offsetHeight;

        setFooterHidden(!scrolledToBottom && scrollingDown);

        setLastScrollTop(st <= 0 ? 0 : st);
      }, 50);

      window.addEventListener('scroll', checkDirection);
      return () => window.removeEventListener('scroll', checkDirection);
    });

    useEffect(() => {
      setFooterHidden(modalStore.isOpen);
    }, [modalStore.isOpen]); // eslint-disable-line

    return (
      <StyledFooter
        className={classnames({ hidden: footerHidden, 'lift-up': !!liftUp })}
      >
        <FooterLinksContainer>
          <FooterBtnContainer>
            <Button
              onClick={onButtonClick}
              style={
                liftUp
                  ? { marginTop: '0.5rem', marginBottom: '0.5rem' }
                  : undefined
              }
            >
              {text('confirmThemeMaterials', {
                theme: selectedTheme.name,
              })}
            </Button>
          </FooterBtnContainer>
        </FooterLinksContainer>
      </StyledFooter>
    );
  }
);
