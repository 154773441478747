import { action, observable, computed, observe } from 'mobx';
import {
  fetchApartmentOrRoomBundles,
  selectMaterialForBundle,
  confirmUserBundleSelection,
  fetchBundlesByTheme,
} from '../axios';
import appStore from './AppStore';
import Bundle, { BundleData } from './models/Bundle';
import routingStore, { changeParams, getParams } from './RoutingStore';
import alertStore from './AlertStore';
import { text, isLocked, isOpen } from '../utils';
import roomStore, { RoomId } from './RoomsStore';
import roomsStore from './RoomsStore';
import Room from './models/Room';
import BundleState from './enums/BundleState';
import confirmationDialogStore from './ConfirmationDialogStore';
import themeStore from './ThemeStore';
import { scrollToMaterialSelector } from '../components/Materials/Materials';
import { bundlesFilteredByShowRooms } from './storeUtils';
import { triggerGAError } from '../services';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { Offer } from './models/Offer';
import { getBundleRooms } from '../components/ConfirmationPhases/utils';

export type RoomWithKey = Room & { key?: string };
export interface RoomsWithDl {
  deadline: string;
  rooms: RoomWithKey[];
}

class MaterialSelectorStore {
  @observable public bundles: Map<RoomId, Bundle[]> = new Map<
    RoomId,
    Bundle[]
  >();

  @observable public bundlesByTheme: Map<number, Bundle[]> = new Map<
    number,
    Bundle[]
  >();

  @observable public confirmingBundles = false;
  @observable public allBundlesFetched = false;
  @observable public allThemeBundlesFetched: number[] = [];
  @observable public selectingMaterialDone = true;
  @observable public showingSpinner = false;

  @observable public orderConfirmed = false;

  @observable public materialsOpen = false;
  @observable public fetchingBundles = false;
  @observable public fetchingBundlesByTheme = false;
  @observable public targetedMatId: any = undefined;

  constructor() {
    observe(routingStore, 'location', value => {
      const { bundleId, roomId } = getParams(value.newValue.search);

      if (
        bundleId &&
        (!this.selectedBundle || this.selectedBundle.id !== Number(bundleId))
      ) {
        this.selectBundle(roomId as RoomId, Number(bundleId), false);
      } else if (!bundleId) {
        this.selectBundle(roomId as RoomId, undefined, false);
      }

      if (
        roomId &&
        (!roomsStore.selectedRoom ||
          roomsStore.selectedRoom.id !== Number(roomId))
      ) {
        this.selectRoom(roomId ? Number(roomId) : undefined, false);
      }
    });
  }

  @action
  public fetchBundles = debounce(
    async (bundleId?: number, roomId?: number, scrollToMaterials = true) => {
      if (appStore.apartmentId && !this.fetchingBundles) {
        this.fetchingBundles = true;
        this.showingSpinner = true;
        try {
          const requests: Array<Promise<any> | undefined> = [
            fetchApartmentOrRoomBundles(
              appStore.organisationId,
              appStore.tenantId,
              appStore.projectId,
              Number(appStore.apartmentId),
              [],
              true,
              true
            ),
            !roomStore.roomsFetched ? roomsStore.fetchRooms(roomId) : undefined,
            !themeStore.themesFetched ? themeStore.fetchThemeSets() : undefined,
          ];

          const [{ data }] = await Promise.all(requests);
          const oldSelectedBundle = this.selectedBundle;

          const bundles: Bundle[] = data
            .map((b: BundleData) => {
              if (!b.ownerCanSeeBundle) return undefined;
              const nb = new Bundle(b);
              if (
                bundleId === nb.id ||
                (oldSelectedBundle && oldSelectedBundle.id === nb.id)
              )
                nb.toggleSelected(true);
              return nb;
            })
            .filter((b?: Bundle) => !!b);

          this.bundles = bundles.reduce((pre, cur) => {
            const rooms: Array<number | undefined> = !!cur.roomIds.length
              ? cur.roomIds
              : [cur.roomId || undefined];

            rooms.forEach(id => {
              const preRoomBundles = pre.get(id);

              if (preRoomBundles) {
                pre.set(id, [...preRoomBundles, cur]);
              } else pre.set(id, [cur]);
            });

            return pre;
          }, new Map() as Map<RoomId, Bundle[]>);

          // Set general appearance bundles.
          this.bundles.set(
            undefined,
            this.allBundles.reduce((pre, cur) => {
              if (
                (cur.roomIds.length > 1 &&
                  !pre.map(i => i.id).includes(cur.id)) ||
                cur.roomIds.length === 0
              ) {
                pre.push(cur);
              }
              return pre;
            }, [] as Bundle[])
          );

          this.allBundlesFetched = true;
          if (scrollToMaterials) scrollToMaterialSelector();
        } catch (e) {
          triggerGAError('fetching materials failed', e.toString());
          console.error(e);
          alertStore.show(text('errors.fetchingMaterialsFailed'));
        } finally {
          this.showingSpinner = false;
          this.fetchingBundles = false;
        }
      }
    }
  );

  @action
  public fetchBundlesByTheme = debounce(
    async (themeIds: number[], force = false, scrollToMaterials = true) => {
      if (appStore.apartmentId && !this.fetchingBundlesByTheme) {
        this.showingSpinner = true;
        this.fetchingBundlesByTheme = true;
        try {
          const requests: Array<Promise<any> | undefined> = [
            fetchBundlesByTheme(
              appStore.organisationId,
              appStore.tenantId,
              appStore.projectId,
              Number(appStore.apartmentId),
              themeIds
            ),
            !roomStore.roomsFetched ? roomsStore.fetchRooms() : undefined,
          ];

          const [{ data }] = await Promise.all(requests);

          interface BundlesByThemeData {
            packageId: number;
            bundles: BundleData[];
          }

          this.bundlesByTheme = data.packages.reduce(
            (pre: Map<number, Bundle[]>, cur: BundlesByThemeData) => {
              pre.set(
                cur.packageId,
                cur.bundles
                  .map(b => {
                    if (!b.ownerCanSeeBundle) return undefined;
                    return new Bundle(b);
                  })
                  .filter((b?: Bundle) => !!b) as Bundle[]
              );
              return pre;
            },
            this.bundlesByTheme
          );

          if (scrollToMaterials) scrollToMaterialSelector();

          this.allThemeBundlesFetched = themeIds;
        } catch (e) {
          triggerGAError('fetching materials failed', e.toString());
          console.error(e);
          alertStore.show(text('errors.fetchingMaterialsFailed'));
        } finally {
          this.fetchingBundlesByTheme = false;
          this.showingSpinner = false;
        }
      }
    },
    500
  );

  @action
  public selectRoom = (id?: number, pushUrlState = true) => {
    roomsStore.selectRoom(id);

    if (pushUrlState)
      changeParams({
        roomId: roomsStore.selectedRoom
          ? roomsStore.selectedRoom.id
          : undefined,
        bundleId: undefined,
      });
  };

  @action
  public selectBundle = (
    selectedRoom: RoomId,
    id?: number,
    pushUrlState = true
  ) => {
    // const oldBundles = this.bundles.get(selectedRoom);
    // if (oldBundles) {
    // const bundles = oldBundles.map(b => {
    //   if (b.selected) b.toggleSelected(false);
    //   else if (b.id === id) b.toggleSelected();
    //   return b;
    // });
    // this.bundles.set(selectedRoom, bundles);

    this.bundlesToShow.forEach(b => {
      if (b.id === id) b.toggleSelected(true);
      if (b.selected && b.id !== id) b.toggleSelected(false);
    });

    if (pushUrlState)
      changeParams({
        roomId: roomsStore.selectedRoom
          ? roomsStore.selectedRoom.id
          : undefined,
        bundleId: this.selectedBundle ? this.selectedBundle.id : undefined,
      });
    // }
  };

  @action
  public selectTargetedMaterial = async () => {
    const { roomId } = getParams();
    if (this.selectedBundle && appStore.apartmentId) {
      const selectedMaterial = this.selectedBundle.selectedMaterial;

      try {
        this.selectingMaterialDone = false;
        const bundleId = this.selectedBundle.id;
        const materialId =
          this.targetedMatId !== undefined
            ? this.targetedMatId
            : selectedMaterial;

        this.selectedBundle.changeSelectedMaterial(materialId);
        this.selectBundle(roomsStore.selectedRoomId, undefined);
        await selectMaterialForBundle(
          appStore.organisationId,
          appStore.tenantId,
          appStore.projectId,
          Number(appStore.apartmentId),
          bundleId,
          materialId
        );
        await appStore.fetchApartmentInfo();
        this.selectRoom(Number(roomId));
      } catch (e) {
        triggerGAError('selecting material failed', e.toString());
        if (this.selectedBundle)
          this.selectedBundle.changeSelectedMaterial(selectedMaterial || null);
        console.error(e);
        alertStore.show(text('errors.selectingMaterialFailed'));
      } finally {
        this.selectingMaterialDone = true;
      }
    }
  };

  @action
  public targetMaterial = (projectMaterialId: number | null) => {
    if (this.selectedBundle)
      this.selectedBundle.targetMaterial(projectMaterialId);
    this.targetedMatId = projectMaterialId;
  };

  @action
  public resetTargetedMatId = () => {
    this.targetedMatId = null;
  };

  @action
  public confirmBundles = (bundleIds: number[], totalPrice: string) => {
    const confirm = async () => {
      try {
        this.confirmingBundles = true;
        const requiredSelections = this.allBundles
          .filter(b => bundleIds.includes(b.id))
          .filter(b => !(b.isAccessory || b.selectedMaterialOption));

        if (appStore.apartmentId && requiredSelections.length < 1) {
          const resp = await confirmUserBundleSelection(
            appStore.organisationId,
            appStore.tenantId,
            appStore.projectId,
            appStore.apartmentId,
            BundleState.LOCKED,
            bundleIds
          );

          if (!resp.error) {
            this.allBundles.forEach(b => {
              if (bundleIds.includes(b.id)) b.changeState(BundleState.LOCKED);
            });
          } else throw new Error(resp.error);

          this.setOrderConfirmed(true);

          await themeStore.fetchThemeSets();
        } else if (!!requiredSelections.length) {
          alertStore.show(
            text('errors.requiredSelectionsMissing', {
              selections: requiredSelections.map(rs => rs.name).join(', '),
            }),
            ''
          );
        }
      } catch (e) {
        triggerGAError('confirming materials failed', e.toString());
        console.error(e);
        alertStore.show(text('errors.confirmMaterialsFailed'));
      } finally {
        this.confirmingBundles = false;
      }
    };

    confirmationDialogStore.open(
      {
        okText: text('confirm'),
        cancelText: text('cancel'),
        confirmationTitle: text('confirmTitle'),
        confirmationText: text('confirmText', {
          count: bundleIds.length,
          price: totalPrice,
        }),
      },
      confirm,
      () => {},
      undefined,
      'var(--custom-colorNotif3)'
    );
  };

  @action
  public setOrderConfirmed = (orderConfirmed: boolean) => {
    this.orderConfirmed = orderConfirmed;
  };

  @action
  public toggleMaterialsOpen = (open?: boolean) => {
    if (open !== undefined) this.materialsOpen = open;
    else {
      this.materialsOpen = !this.materialsOpen;
    }
  };

  @action showSpinnerFlag = (showSpinner: boolean) => {
    this.showingSpinner = showSpinner;
  };

  @computed
  get bundlesToShow() {
    const { allSelectedThemeIdsFromSelectedSets } = themeStore;
    const selectedRoom = roomStore.selectedRoom;

    const getBundlesForTheme = (themeId?: number | null) =>
      themeId ? this.bundlesByTheme.get(themeId) || [] : [];

    const bundlesForSelectedRoom =
      this.bundles.get(selectedRoom ? selectedRoom.id : undefined) || [];

    const showRooms = appStore.tenantSettings.customerJourney2_showRooms;
    const showOnlyPackageBundles =
      appStore.tenantSettings.customerJourney2_showOnlyPackageBundles;

    const returnValue = showOnlyPackageBundles
      ? bundlesFilteredByShowRooms(
          allSelectedThemeIdsFromSelectedSets.reduce(
            (bundlesForTheme: Bundle[], id: number) => {
              return [...bundlesForTheme, ...getBundlesForTheme(id)];
            },
            []
          ),
          showRooms
        )(bundlesForSelectedRoom.map(b => b.id))
      : bundlesForSelectedRoom;

    return returnValue;
  }

  @computed
  get selectedBundle() {
    return this.bundlesToShow
      ? this.bundlesToShow.find(b => b.selected)
      : undefined;
  }

  @computed
  get accessoryBundles() {
    return this.bundlesToShow
      ? this.bundlesToShow.filter(b => b.isAccessory)
      : [];
  }

  @computed
  get defaultBundles() {
    return this.bundlesToShow
      ? this.bundlesToShow.filter(b => !b.isAccessory)
      : [];
  }

  @computed
  get targetedMaterial() {
    return this.selectedBundle
      ? this.selectedBundle.targetedMaterial
      : undefined;
  }

  @computed
  get apartmentBundles() {
    return this.bundles.get(undefined) || [];
  }

  @computed
  get apartmentBundlesExcludingOffers() {
    return this.apartmentBundles.filter(b => !b.isPartOfOffer);
  }

  @computed
  get apartmentBundlesWithoutDL() {
    return this.apartmentBundles.filter(b => !b.deadline);
  }

  @computed
  get apartmentBundlesWithoutDLExcludingOffers() {
    return this.apartmentBundlesWithoutDL.filter(b => !b.isPartOfOffer);
  }

  @computed
  get apartmentDeadlineNear() {
    return this.apartmentBundles.some(
      b => b.deadlineNear && !b.deadlineGone && !isLocked(b)
    );
  }

  @computed
  get allBundles(): Bundle[] {
    return Array.from(this.bundles).reduce(
      (pre, cur) =>
        pre
          .filter(b => !cur[1].map(i => i.id).includes(b.id))
          .concat(...cur[1]),
      [] as Bundle[]
    );
  }

  @computed
  get deadlines() {
    return this.allBundles.reduce((pre, cur) => {
      if (cur.deadline && !pre.includes(cur.deadline)) pre.push(cur.deadline);
      return pre;
    }, [] as string[]);
  }

  @computed
  get upComingDeadlines() {
    //  RETURN THOSE DEADLINES FROM NON CONFIRMED BUNDLES
    return this.allBundles.reduce((pre, cur) => {
      if (
        cur.deadline &&
        cur.state === 'OPEN' &&
        new Date(cur.deadline).getTime() > new Date().getTime() &&
        !pre.includes(cur.deadline)
      )
        pre.push(cur.deadline);
      return pre;
    }, [] as string[]);
  }

  @computed
  get lastDeadline() {
    return this.deadlines
      .sort((a, b) => {
        const aDate = new Date(a);
        const bDate = new Date(b);

        return aDate < bDate ? 1 : -1;
      })
      .filter(dl => dl)[0];
  }

  @computed
  get roomsByDeadlines(): RoomsWithDl[] {
    const rooms = roomsStore.rooms as RoomWithKey[];

    const roomsWithDeadlines = this.deadlines.reduce((pre, cur) => {
      const roomsWithDl: RoomWithKey[] = rooms.filter(room =>
        room.roomDeadlines.includes(cur)
      );

      if (roomsWithDl)
        pre.push({
          deadline: cur,
          rooms: roomsWithDl.map(r => {
            r.key = `${cur}${r.name}`;
            return r;
          }),
        });
      return pre;
    }, [] as RoomsWithDl[]);

    return roomsWithDeadlines.sort((a, b) => {
      const aBundleRooms = getBundleRooms(a);
      const bBundleRooms = getBundleRooms(b);

      const aHasOpenRoomBundles = aBundleRooms.some(
        br => !!br.openBundles.length
      );

      const bHasOpenRoomBundles = bBundleRooms.some(
        br => !!br.openBundles.length
      );

      if (aHasOpenRoomBundles && !bHasOpenRoomBundles) return -1;
      if (!aHasOpenRoomBundles && bHasOpenRoomBundles) return 1;

      const aDate = new Date(a.deadline);
      const bDate = new Date(b.deadline);

      return aDate < bDate ? -1 : 1;
    });
  }

  @computed
  get roomsWithoutDeadlines(): Room[] {
    return roomStore.rooms.filter(r => !!r.bundlesWithNoDl.length);
  }

  @computed
  get roomsWithoutDeadlinesExcludingOffers(): Room[] {
    return roomStore.rooms.filter(
      r => !!r.bundlesWithNoDl.filter(b => !b.isPartOfOffer).length
    );
  }

  @computed
  get roomsWithOpenAndLockableBundles(): Room[] {
    return roomStore.rooms.filter(r => !!r.openAndLockableBundles.length);
  }

  @computed
  get roomsWithOpenAndLockableBundlesExcludingOffers(): Room[] {
    return roomStore.rooms.filter(r =>
      r.openAndLockableBundles.some(b => !b.isPartOfOffer)
    );
  }

  @computed
  get roomsWithOpenAndLockableBundlesWithoutDl(): Room[] {
    return roomStore.rooms.filter(r => !!r.openBundlesWithNoDl.length);
  }

  @computed
  get roomsWithOpenAndLockableBundlesWithoutDlExcludingOffers(): Room[] {
    return roomStore.rooms.filter(
      r => !!r.openBundlesWithNoDl.filter(b => !b.isPartOfOffer).length
    );
  }

  @computed
  get roomsWithOpenAndLockableBundlesWithDl(): Room[] {
    return roomStore.rooms.filter(r => !!r.openBundlesWithDl.length);
  }

  @computed
  get roomsWithOpenAndLockableBundlesWithDlExcludingOffers(): Room[] {
    return roomStore.rooms.filter(
      r => !!r.openBundlesWithDl.filter(b => !b.isPartOfOffer).length
    );
  }

  @computed
  get roomsWithLockedBundles(): Room[] {
    return roomsStore.rooms.filter(r => !!r.lockedBundles.length);
  }

  @computed
  get roomsWithLockedBundlesExcludingOffers(): Room[] {
    return roomsStore.rooms.filter(
      r => !!r.lockedBundles.filter(b => !b.isPartOfOffer).length
    );
  }

  @computed
  get apartmentOpenBundles() {
    return this.apartmentBundles.filter(isOpen);
  }

  @computed
  get someBundlesAreOpen(): boolean {
    return (
      !!this.apartmentOpenBundles.length ||
      !!this.roomsWithOpenAndLockableBundles.length
    );
  }

  @computed
  get someBundlesAreOpenExcludingOffers(): boolean {
    return (
      !!this.apartmentOpenBundles.filter(b => !b.isPartOfOffer).length ||
      !!this.roomsWithOpenAndLockableBundlesExcludingOffers.length
    );
  }

  @computed
  get someBundlesAreLocked(): boolean {
    return (
      this.apartmentBundles.some(b => !isOpen(b)) ||
      !!this.roomsWithLockedBundles.length
    );
  }

  @computed
  get someBundlesAreLockedExcludingOffers(): boolean {
    return (
      this.apartmentBundles.some(b => !isOpen(b) && !b.isPartOfOffer) ||
      !!this.roomsWithLockedBundlesExcludingOffers.length
    );
  }

  @computed
  get someBundlesWithoutDlAreOpenExcludingOffers(): boolean {
    const openBundlesWithoutDlExcludingOffers = this.apartmentOpenBundles.filter(
      b => !b.deadline && !b.isPartOfOffer
    );
    return (
      !!openBundlesWithoutDlExcludingOffers.length ||
      !!this.roomsWithOpenAndLockableBundlesWithoutDlExcludingOffers.length
    );
  }

  @computed
  get allBundlesLocked() {
    const states = [
      BundleState.LOCKED,
      BundleState.DEADLINE_GONE,
      BundleState.CONFIRMED,
    ];
    return (
      this.allBundlesFetched &&
      this.allBundles.every(b => states.includes(b.state))
    );
  }

  @computed
  get bundleOffersAppliedToDb(): Offer[] {
    return this.allBundles.reduce((pre, cur) => {
      if (cur.isPartOfOffer && cur.hasAppliedToDbOffer)
        if (pre.find(offer => offer.id === cur.offers[0].id) === undefined)
          pre.push(cur.offers[0]);
      return pre;
    }, [] as Array<Offer>);
  }

  @computed
  get pendingBundleOffers(): Offer[] {
    return this.allBundles.reduce((pre, cur) => {
      if (cur.isPartOfOffer && cur.hasPendingOffers)
        if (pre.find(offer => offer.id === cur.offers[0].id) === undefined)
          pre.push(cur.offers[0]);
      return pre;
    }, [] as Array<Offer>);
  }

  @computed
  get bundleDeadlines(): Array<{
    bundleName: string;
    deadline: string;
    deadlineAsDate: string;
    deadlineEvent: string;
  }> {
    return this.allBundles
      .reduce((pre, cur) => {
        if (cur.deadline)
          pre.push({
            bundleName: cur.name,
            deadline: cur.deadline!,
            deadlineAsDate: cur.deadlineAsDate!,
            deadlineEvent: cur.deadlineEvent,
          });
        return pre;
      }, [] as Array<{ bundleName: string; deadline: string; deadlineAsDate: string; deadlineEvent: string }>)
      .sort((a, b) => (moment(a.deadline).isAfter(b.deadline) ? 1 : -1));
  }

  @computed
  get bundlesGroupedByDeadline() {
    const bundlesByDate = this.bundleDeadlines.reduce((pre, bundle) => {
      if (!pre.has(bundle.deadlineAsDate)) {
        pre.set(bundle.deadlineAsDate, []);
      }

      const bundlesForDate = pre.get(bundle.deadlineAsDate);
      bundlesForDate.push(bundle);

      return pre;
    }, new Map());
    return bundlesByDate;
  }

  @computed
  get bundlesGroupedByDeadlineExcludingEventDeadlines() {
    const bundlesByDateExcludingEvents = this.bundleDeadlines.reduce(
      (pre, bundle) => {
        if (!pre.has(bundle.deadlineAsDate) && bundle.deadlineEvent === null) {
          pre.set(bundle.deadlineAsDate, []);
        }

        const bundlesForDate = pre.get(bundle.deadlineAsDate);
        if (bundle.deadlineEvent === null) {
          bundlesForDate.push(bundle);
        }

        return pre;
      },
      new Map()
    );
    return bundlesByDateExcludingEvents;
  }
}

const materialSelectorStore = new MaterialSelectorStore();

export default materialSelectorStore;
