import { observable, action, computed } from 'mobx';
import { saveAs } from 'file-saver';
import {
  fetchAttachments,
  getAttachmentsBySection,
  getZipAttachments,
} from '../axios';
import appStore from './AppStore';
import { triggerGAError } from '../services';
import Phase from '../store/models/Phase';
import { SignableAttachment } from './interfaces/Attachment';
import userRightsStore from './UserRightsStore';
import { UserRight } from './enums/UserRights';
import { text } from '../utils';

class AttachmentsStore {
  @observable public phases: Phase[] = [];
  @observable public attachmentsFetched = false;

  @observable public signableAttachments: SignableAttachment[] = [];
  @observable public signableAttachmentsFetched = false;

  @observable public signableDocument: unknown;

  @observable public fetchingFailed: boolean = false;

  @action public fetchAttachments = async () => {
    try {
      this.fetchingFailed = false;
      const { data } = await fetchAttachments(
        appStore.organisationId,
        appStore.tenantId,
        appStore.projectId,
        appStore.apartmentId
      );
      this.phases = data.phases.map((p: any) => new Phase(p));

      await this.fetchSignableAttachments();
    } catch (e) {
      triggerGAError('fetching attachments failed', e.toString());
      console.error(e);
      this.fetchingFailed = true;
    } finally {
      this.attachmentsFetched = true;
    }
  };

  @action public fetchSignableAttachments = async () => {
    const { organisationId, tenantId, projectId, apartmentId } = appStore;
    try {
      this.signableAttachmentsFetched = false;

      const attachments = await getAttachmentsBySection(
        organisationId,
        tenantId,
        projectId,
        apartmentId
      );
      this.signableAttachments = attachments.filter(
        (a: any) => a.signable || a.signed
      );

      this.setSignableAttachmentsToPhaseSections();
    } catch (e) {
      triggerGAError('Fetching signable attachments failed', e.toString());
      console.error(e);
    } finally {
      this.signableAttachmentsFetched = true;
    }
  };

  @computed
  public get phasesWithAttachments(): Phase[] {
    return this.phases.filter(a => a.hasAttachments);
  }

  private async setSignableAttachmentsToPhaseSections() {
    for (const phase of this.phasesWithAttachments) {
      for (const section of phase.sections) {
        section.signableAttachments = this.signableAttachments.filter(
          attachment => {
            return attachment.sections.some(s => s.sectionId === section.id);
          }
        );

        // duplicate attachments filtered out
        section.attachments = section.attachments?.filter(attachment => {
          return !section.signableAttachments?.some(
            signableAttachment => signableAttachment.id === attachment.id
          );
        });
      }
    }
  }

  public getUnsignedDocumentsCount() {
    if (!userRightsStore.check(UserRight.SIGN_DOCUMENT)) return 0;

    return this.signableAttachments.filter(a => !a.signed && a.signable).length;
  }

  @action public downloadZip = async () => {
    try {
      const blob = await getZipAttachments(
        appStore.organisationId,
        appStore.tenantId,
        appStore.projectId
      );
      const tempURL = URL.createObjectURL(
        new Blob([blob], { type: 'application/zip' })
      );
      await saveAs(tempURL, text('attachmentsFilename') + '.zip');
    } catch (err) {
      throw new Error('Failed to zip attachments');
    }
  };
}

const attachmentsStore = new AttachmentsStore();

export default attachmentsStore;
