import { observable, action, computed } from 'mobx';
import Room, { RoomData } from './models/Room';
import alertStore from './AlertStore';
import { text } from '../utils';
import { fetchRooms } from '../axios';
import appStore from './AppStore';
import { changeParams } from './RoutingStore';
import { triggerGAError } from '../services';

export type RoomId = number | string | undefined;

export class RoomsStore {
  @observable public rooms: Room[] = [];
  @observable public roomsFetched = false;

  @action
  public fetchRooms = async (selectedRoom?: string | number) => {
    if (appStore.apartmentId) {
      try {
        this.roomsFetched = true;
        const { data } = await fetchRooms(
          appStore.organisationId,
          appStore.tenantId,
          appStore.projectId,
          appStore.apartmentId
        );
        this.rooms = data.rooms.map((r: RoomData) => {
          const room = new Room(r);
          if (room.id === Number(selectedRoom)) room.toggleSelected(true);
          return room;
        });
      } catch (e) {
        triggerGAError('fetching rooms failed', e.toString());
        console.error(e);
        alertStore.show(text('errors.fetchingRoomsFailed'));
      }
    }
  };

  @action
  public selectRoom = (id?: RoomId, pushUrlState = false) => {
    this.rooms = this.rooms.map(r => {
      if (r.id === id) r.toggleSelected(true);
      else if (r.selected) r.toggleSelected(false);
      return r;
    });

    if (pushUrlState) {
      if (this.selectedRoom) changeParams({ roomId: this.selectedRoomId });
      else changeParams({ roomId: undefined });
    }
  };

  @computed
  get selectedRoom() {
    return this.rooms.find(r => r.selected);
  }

  @computed
  get selectedRoomId(): RoomId {
    return this.selectedRoom ? this.selectedRoom.id : undefined;
  }

  @computed
  get roomsWithMessages() {
    return this.rooms.filter(room => room.messages > 0);
  }

  @computed
  get roomsWithLockedBundles() {
    return this.rooms.filter(
      room => room.lockedBundles && !!room.lockedBundles.length
    );
  }
}

const roomsStore = new RoomsStore();

export default roomsStore;
