import React from 'react';
import styled from 'styled-components/macro';
import Bundle from '../../store/models/Bundle';
import { observer } from 'mobx-react-lite';
import {
  MaterialImageWrapper,
  MaterialImage,
} from '../Materials/MaterialImage';
import { mediaSmallMax, mediaMediumMin } from '../Styled/media';
import appStore from '../../store/AppStore';
import lightBoxStore from '../../store/LightBoxStore';
import { text, decimalToLocale } from '../../utils';
import { modalView } from '../../services';
import { ModalView } from '../../services/ModalView';
import { FormattedTextSection } from '../Styled/FormattedTextSection';

const StyledBundlesConfirmation = styled.div`
  padding: 0.5rem 3rem;
  display: flex;
  flex-direction: column;

  @media ${mediaSmallMax} {
    padding: 0 1rem;
  }
`;

export const ConfirmationBundle = styled.div`
  display: flex;
  min-height: 6rem;
  padding: 1rem 0;
  position: relative;

  &:not(:last-child):after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background: var(--custom-colorAccent3);
    opacity: 0.5;
    width: 100%;
    content: '';
  }

  @media ${mediaSmallMax} {
    justify-content: space-between;
  }
`;

export const BundleInfo = styled(FormattedTextSection)`
  max-height: 12rem;
  overflow: hidden;
  flex-grow: 1;
  padding-right: 1rem;

  p {
    &.font-light,
    &.font-normal {
      line-height: 16px;
      font-size: 12px;

      @media ${mediaSmallMax} {
        line-height: 13px;
        font-size: 12px;
      }
    }
    &.font-medium {
      font-size: 18px;

      @media ${mediaSmallMax} {
        line-height: 15px;
        font-size: 14px;
      }
    }
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  @media ${mediaSmallMax} {
    padding-left: 1rem;
  }
`;

export const SelectedMaterialPrice = styled.p`
  position: absolute;
  top: 2rem;
  right: 1rem;

  @media ${mediaSmallMax} {
    &.visible-on-desktop {
      display: none;
    }

    position: relative;
    top: 0;
    right: 0;
    font-size: 12px;
    line-height: 13px;
  }

  @media ${mediaMediumMin} {
    &:not(.visible-on-desktop) {
      display: none;
    }
  }
`;

export const StyledImageWrapper = styled(MaterialImageWrapper)`
  min-width: 6rem;
  max-width: 6rem;
  min-height: 6rem;
  max-height: 6rem;
  margin-right: 2rem;

  @media ${mediaSmallMax} {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`;

export const ImageContainer = styled.div`
  text-align: center;
`;

interface BundlesConfirmationProps {
  bundles: Bundle[];
}

const BundlesConfirmation = ({ bundles }: BundlesConfirmationProps) => (
  <StyledBundlesConfirmation>
    {bundles.map(b => {
      const materialOption = b.selectedMaterialOption;

      const materialPrice =
        b.hasAppliedToDbOffer && b.offers[0].formatedPrice
          ? b.offers[0].formatedPrice
          : materialOption
          ? materialOption.price
          : null;

      return (
        <ConfirmationBundle key={b.id}>
          <ImageContainer>
            <StyledImageWrapper>
              {materialOption && (
                <MaterialImage
                  style={{ cursor: 'pointer' }}
                  src={materialOption.imageUrlSmall}
                  onClick={() => {
                    if (materialOption) {
                      modalView(ModalView.MATERIAL_IMAGE);
                      lightBoxStore.open([materialOption.imageUrlLarge]);
                    }
                  }}
                />
              )}
            </StyledImageWrapper>
            {materialPrice && (
              <SelectedMaterialPrice>
                {`${decimalToLocale(materialPrice.toDP(2, 2))} `}
                {appStore.currencySymbol}
              </SelectedMaterialPrice>
            )}
          </ImageContainer>
          <BundleInfo>
            <p className="font-light">{b.name}</p>
            <p className="font-medium">
              {materialOption ? materialOption.name : text('noSelectionText')}
            </p>
            <p className="font-normal">
              {materialOption && materialOption.description}
            </p>
          </BundleInfo>
          {materialPrice && (
            <SelectedMaterialPrice className="visible-on-desktop">
              {`${decimalToLocale(materialPrice.toDP(2, 2))} `}
              {appStore.currencySymbol}
            </SelectedMaterialPrice>
          )}
        </ConfirmationBundle>
      );
    })}
  </StyledBundlesConfirmation>
);

export default observer(BundlesConfirmation);
