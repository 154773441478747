import React from 'react';
import Material from '../../../store/models/Material';
import styled from 'styled-components/macro';
import Button from '../../Button/Button';
import { text } from '../../../utils';
import lightBoxStore from '../../../store/LightBoxStore';
import ZoomButton from '../../ZoomButton/ZoomButton';

import { NoImage, MaterialImageWrapper, MaterialImage } from '../MaterialImage';
import { mediaMediumMax, mediaSmallMax } from '../../Styled/media';
import Close from '../../Icons/Close';
import Lock, { StyledLockContainer } from '../../Icons/Lock';
import InfoIcon from '../../Icons/Info';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { Element } from 'react-scroll';
import { modalView } from '../../../services';
import { ModalView } from '../../../services/ModalView';
import { FormattedTextSection } from '../../Styled/FormattedTextSection';

const StyledTargetedMaterial = styled(Element)`
  display: flex;
  flex-direction: column;
  min-width: 27.5rem;
  max-width: 27.5rem;
  padding: 2.5rem 5rem 5rem 2.5rem;

  &.locked {
    padding: 0 5rem 5rem 0;
  }

  flex-grow: 1;
  overflow: hidden auto;

  @media ${mediaMediumMax} {
    position: relative;
    margin-left: 0;
    min-width: 22.5rem;
    max-width: 22.5rem;
    padding-left: 0;
  }

  @media ${mediaSmallMax} {
    padding: 0;
    width: 100%;
    max-width: 20rem;
    min-width: 0;
    padding-left: 1rem;
    padding-right: 1rem;

    ${Button} {
      min-height: 40px;
    }
  }
`;

const MaterialDescriptionWrapper = styled(FormattedTextSection)`
  margin: 2rem 0;
  overflow: hidden;
  flex-shrink: 0;

  @media ${mediaSmallMax} {
    margin: 1rem 0;
  }
`;

const MaterialName = styled.h3`
  font-size: 24px;
  font-weight: 700;
  font-family: var(--custom-fontNameBold);
  margin-bottom: 1rem;
  @media ${mediaSmallMax} {
    font-size: 18px;
    line-height: 19px;
    margin-bottom: 0.5rem;
  }
`;

const MaterialDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  font-family: var(--custom-fontNameLight);
`;

const MaterialUrl = styled.div`
  margin-top: 1rem;
  text-align: center;

  a {
    text-decoration: none;
  }
`;

const StyledClose = styled(Close)`
  width: 7rem;
  height: 7rem;
  fill: var(--color-lightGray);
`;

const NoSelectionImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
`;

const LockContainer = styled(StyledLockContainer)`
  bottom: 1rem;
  left: 1rem;
`;

const StyledZoomButton = styled(ZoomButton)`
  @media ${mediaSmallMax} {
    width: 2.5rem;
    height: 2.5rem;
    right: 2rem;
    bottom: 1rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

interface TargetedMaterialProps {
  material?: Material;
  onSelect: () => void;
  noSelection?: string;
  locked?: boolean;
  selected?: boolean;
}

const TargetedMaterial = ({
  material,
  onSelect,
  noSelection,
  locked,
  selected,
}: TargetedMaterialProps) => (
  <StyledTargetedMaterial
    name="targeted-material"
    className={classnames({ locked })}
  >
    {material && (
      <>
        <MaterialImageWrapper
          onClick={() => {
            modalView(ModalView.MATERIAL_IMAGE);
            lightBoxStore.open([material.imageUrlLarge]);
          }}
        >
          {material.imageUrlLarge ? (
            <MaterialImage src={material.imageUrlLarge} alt={material.name} />
          ) : (
            <NoImage />
          )}
          {locked && (
            <LockContainer>
              <Lock fill="var(--custom-colorAccent)" />
            </LockContainer>
          )}
          <StyledZoomButton
            onClick={() => {
              modalView(ModalView.MATERIAL_IMAGE);
              lightBoxStore.open([material.imageUrlLarge]);
            }}
          />
        </MaterialImageWrapper>
        <MaterialDescriptionWrapper>
          <MaterialDescription>{material.manufacturer}</MaterialDescription>
          <MaterialName>{material.name}</MaterialName>
          <MaterialDescription>{material.description}</MaterialDescription>
          {material.url && (
            <MaterialUrl>
              <a href={material.url} target="_blank" rel="noopener noreferrer">
                <InfoIcon fill="var(--custom-colorAccent)" />
              </a>
            </MaterialUrl>
          )}
        </MaterialDescriptionWrapper>
        {!locked && (
          <Button
            invertColors
            onClick={onSelect}
            color={selected ? 'var(--custom-colorNotif3)' : undefined}
            className="larger-mobile"
          >
            {selected ? text('selected') : text('select')}
          </Button>
        )}
      </>
    )}
    {noSelection && (
      <>
        <NoSelectionImage>
          <StyledClose />
        </NoSelectionImage>
        <MaterialDescriptionWrapper>
          <MaterialName>{noSelection}</MaterialName>
        </MaterialDescriptionWrapper>
        <Button invertColors onClick={onSelect}>
          {text('select')}
        </Button>
      </>
    )}
  </StyledTargetedMaterial>
);

export default observer(TargetedMaterial);
