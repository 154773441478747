enum OfferState {
  ACCEPTED_BY_CUSTOMER = 'ACCEPTED_BY_CUSTOMER',
  APPLIED_TO_DATABASE = 'APPLIED_TO_DATABASE',
  SENT_TO_CUSTOMER = 'SENT_TO_CUSTOMER',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  WAITING_FOR_SIGNATURE = 'WAITING_FOR_SIGNATURE',
}

export default OfferState;
