import { observable } from 'mobx';
import header from '../../components/Header/yit-header.png';

export const gbDefaultLogo =
  'https://gb19.blob.core.windows.net/logo/GBWhiteHoriz.png';

export interface TenantSettingsInterface {
  customerJourney2_showOnlyPackageBundles?: boolean;
  customerJourney2_usePackageBundlesConfirm?: boolean;
  customerJourney2_usePackageSets?: boolean;
  customerJourney2_showFooter?: boolean;
  customerJourney2_showEventsBox?: boolean;
  customerJourney2_showRooms?: boolean;
  customerJourney2_showRoomBasedSummary?: boolean;
  customerJourney2_showRoomPrices?: boolean;
  customerJourney2_showPackageBasedSummary?: boolean;
  customerJourney2_isLockingByDeadlineEnabled?: boolean;
  customerJourney2_isLockingByBundleEnabled?: boolean;
  customerJourney2_showHeader?: boolean;
  customerJourney2_mainImage?: string;
  customerJourney2_instructionsUrl?: string;
  customerJourney2_useGiosg?: boolean;
  customerJourney2_alwaysShowConfirmAllSelections?: boolean;
  userlaneCj2?: boolean;
  esign_requireForMaterialLocking?: boolean;
  gb4dIsActive?: boolean;
  logoUrls?: {
    organisationLogoLightUrl?: string;
    organisationLogoDarkUrl?: string;
  };
  userlaneKey?: string;
  enableInvoicing?: boolean;
}

const gbDefaultTenantSettings: TenantSettingsInterface = {
  customerJourney2_showOnlyPackageBundles: false,
  customerJourney2_usePackageBundlesConfirm: false,
  customerJourney2_usePackageSets: false,
  customerJourney2_showFooter: true,
  customerJourney2_showEventsBox: true,
  customerJourney2_showRooms: true,
  customerJourney2_showRoomBasedSummary: true,
  customerJourney2_showRoomPrices: false,
  customerJourney2_showPackageBasedSummary: true,
  customerJourney2_isLockingByDeadlineEnabled: true,
  customerJourney2_isLockingByBundleEnabled: true,
  customerJourney2_showHeader: false,
  customerJourney2_mainImage: header,
  customerJourney2_instructionsUrl: undefined,
  customerJourney2_useGiosg: false,
  customerJourney2_alwaysShowConfirmAllSelections: false,
  userlaneCj2: false,
  esign_requireForMaterialLocking: false,
  gb4dIsActive: undefined,
  logoUrls: {
    organisationLogoLightUrl: gbDefaultLogo,
    organisationLogoDarkUrl: gbDefaultLogo,
  },
  userlaneKey: undefined,
  enableInvoicing: false,
};

export default class TenantSettings {
  @observable public customerJourney2_showOnlyPackageBundles =
    gbDefaultTenantSettings.customerJourney2_showOnlyPackageBundles;
  @observable public customerJourney2_usePackageBundlesConfirm =
    gbDefaultTenantSettings.customerJourney2_usePackageBundlesConfirm;
  @observable public customerJourney2_usePackageSets =
    gbDefaultTenantSettings.customerJourney2_usePackageSets;
  @observable public customerJourney2_showFooter =
    gbDefaultTenantSettings.customerJourney2_showFooter;
  @observable public customerJourney2_showEventsBox =
    gbDefaultTenantSettings.customerJourney2_showEventsBox;
  @observable public customerJourney2_showRooms =
    gbDefaultTenantSettings.customerJourney2_showRooms;
  @observable public customerJourney2_showRoomBasedSummary =
    gbDefaultTenantSettings.customerJourney2_showRoomBasedSummary;
  @observable public customerJourney2_showRoomPrices =
    gbDefaultTenantSettings.customerJourney2_showRoomPrices;
  @observable public customerJourney2_showPackageBasedSummary =
    gbDefaultTenantSettings.customerJourney2_showPackageBasedSummary;
  @observable
  public customerJourney2_isLockingByDeadlineEnabled =
    gbDefaultTenantSettings.customerJourney2_isLockingByDeadlineEnabled;
  @observable public customerJourney2_isLockingByBundleEnabled =
    gbDefaultTenantSettings.customerJourney2_isLockingByBundleEnabled;
  @observable public customerJourney2_showHeader =
    gbDefaultTenantSettings.customerJourney2_showHeader;
  @observable public customerJourney2_mainImage =
    gbDefaultTenantSettings.customerJourney2_mainImage;
  @observable public customerJourney2_instructionsUrl =
    gbDefaultTenantSettings.customerJourney2_instructionsUrl;
  @observable public customerJourney2_useGiosg =
    gbDefaultTenantSettings.customerJourney2_useGiosg;
  @observable public customerJourney2_alwaysShowConfirmAllSelections =
    gbDefaultTenantSettings.customerJourney2_alwaysShowConfirmAllSelections;
  @observable public userlaneCj2 = gbDefaultTenantSettings.userlaneCj2;
  @observable public esign_requireForMaterialLocking =
    gbDefaultTenantSettings.esign_requireForMaterialLocking;
  @observable public gb4dIsActive = gbDefaultTenantSettings.gb4dIsActive;
  @observable public logoUrls = gbDefaultTenantSettings.logoUrls;
  @observable public userlaneKey = gbDefaultTenantSettings.userlaneKey;
  @observable public enableInvoicing = gbDefaultTenantSettings.enableInvoicing;

  constructor(settings: TenantSettingsInterface) {
    this.customerJourney2_showRooms =
      settings.customerJourney2_showRooms !== undefined
        ? settings.customerJourney2_showRooms
        : gbDefaultTenantSettings.customerJourney2_showRooms;
    this.customerJourney2_showFooter =
      settings.customerJourney2_showFooter !== undefined
        ? settings.customerJourney2_showFooter
        : gbDefaultTenantSettings.customerJourney2_showFooter;
    this.customerJourney2_showEventsBox =
      settings.customerJourney2_showEventsBox !== undefined
        ? settings.customerJourney2_showEventsBox
        : gbDefaultTenantSettings.customerJourney2_showEventsBox;
    this.customerJourney2_showOnlyPackageBundles =
      settings.customerJourney2_showOnlyPackageBundles !== undefined
        ? settings.customerJourney2_showOnlyPackageBundles
        : gbDefaultTenantSettings.customerJourney2_showOnlyPackageBundles;
    this.customerJourney2_usePackageBundlesConfirm =
      settings.customerJourney2_usePackageBundlesConfirm !== undefined
        ? settings.customerJourney2_usePackageBundlesConfirm
        : gbDefaultTenantSettings.customerJourney2_usePackageBundlesConfirm;
    this.customerJourney2_usePackageSets =
      settings.customerJourney2_usePackageSets !== undefined
        ? settings.customerJourney2_usePackageSets
        : gbDefaultTenantSettings.customerJourney2_usePackageSets;
    this.customerJourney2_showRoomBasedSummary =
      settings.customerJourney2_showRoomBasedSummary !== undefined
        ? settings.customerJourney2_showRoomBasedSummary
        : gbDefaultTenantSettings.customerJourney2_showRoomBasedSummary;
    this.customerJourney2_showRoomPrices =
      settings.customerJourney2_showRoomPrices !== undefined
        ? false && settings.customerJourney2_showRoomPrices
        : gbDefaultTenantSettings.customerJourney2_showRoomPrices;
    this.customerJourney2_showPackageBasedSummary =
      settings.customerJourney2_showPackageBasedSummary !== undefined
        ? settings.customerJourney2_showPackageBasedSummary
        : gbDefaultTenantSettings.customerJourney2_showPackageBasedSummary;
    this.customerJourney2_isLockingByDeadlineEnabled =
      settings.customerJourney2_isLockingByDeadlineEnabled !== undefined
        ? settings.customerJourney2_isLockingByDeadlineEnabled
        : gbDefaultTenantSettings.customerJourney2_isLockingByDeadlineEnabled;
    this.customerJourney2_isLockingByBundleEnabled =
      settings.customerJourney2_isLockingByBundleEnabled !== undefined
        ? settings.customerJourney2_isLockingByBundleEnabled
        : gbDefaultTenantSettings.customerJourney2_isLockingByBundleEnabled;
    this.customerJourney2_showHeader =
      settings.customerJourney2_showHeader !== undefined
        ? settings.customerJourney2_showHeader
        : gbDefaultTenantSettings.customerJourney2_showHeader;
    this.customerJourney2_mainImage =
      settings.customerJourney2_mainImage !== undefined
        ? settings.customerJourney2_mainImage
        : gbDefaultTenantSettings.customerJourney2_mainImage;
    this.customerJourney2_instructionsUrl =
      settings.customerJourney2_instructionsUrl !== undefined
        ? settings.customerJourney2_instructionsUrl
        : gbDefaultTenantSettings.customerJourney2_instructionsUrl;
    this.customerJourney2_useGiosg =
      settings.customerJourney2_useGiosg !== undefined
        ? settings.customerJourney2_useGiosg
        : gbDefaultTenantSettings.customerJourney2_useGiosg;
    this.customerJourney2_alwaysShowConfirmAllSelections =
      settings.customerJourney2_alwaysShowConfirmAllSelections !== undefined
        ? settings.customerJourney2_alwaysShowConfirmAllSelections
        : gbDefaultTenantSettings.customerJourney2_alwaysShowConfirmAllSelections;
    this.userlaneCj2 =
      settings.userlaneCj2 !== undefined
        ? settings.userlaneCj2
        : gbDefaultTenantSettings.userlaneCj2;
    this.esign_requireForMaterialLocking =
      settings.esign_requireForMaterialLocking !== undefined
        ? settings.esign_requireForMaterialLocking
        : gbDefaultTenantSettings.esign_requireForMaterialLocking;
    this.gb4dIsActive =
      settings.gb4dIsActive !== undefined
        ? settings.gb4dIsActive
        : gbDefaultTenantSettings.gb4dIsActive;
    this.logoUrls = settings.logoUrls
      ? settings.logoUrls
      : gbDefaultTenantSettings.logoUrls;
    this.userlaneKey = settings.userlaneKey;
    this.enableInvoicing = settings.enableInvoicing;
  }
}
