import React from 'react';
import Modal from '../../components/Modal/Modal';
import { changeParams } from '../../store/RoutingStore';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { text } from '../../utils';

const Content = styled.div`
  padding: 3rem;
`;

const SubTitle = styled.h3`
  margin: 2.5rem 0 2rem 0;
  font-size: 25px;
`;

const Description = styled.p`
  white-space: pre-wrap;
`;

const TermsAndConditions = () => {
  return (
    <Modal
      closeBtnOffset="3rem"
      onClose={() => changeParams({ offersTermsAndConditions: undefined })}
      overflow="auto"
      style={{ height: '93vh !important' }}
    >
      <Content>
        <SubTitle>{text('linksAndTermsTitle1')}</SubTitle>
        <Description>{text('linksAndTermsDesc1')}</Description>

        <SubTitle>{text('linksAndTermsTitle2')}</SubTitle>
        <Description>{text('linksAndTermsDesc2')}</Description>

        <SubTitle>{text('linksAndTermsTitle3')}</SubTitle>
        <Description>{text('linksAndTermsDesc3')}</Description>
      </Content>
    </Modal>
  );
};

export default observer(TermsAndConditions);
