import React from 'react';

interface VersionListItemProps {
  caption: string;
  text: React.ReactNode;
}

export const MISSING_CAPTION = '(missing)';

export const VersionListItem: React.FC<VersionListItemProps> = ({
  caption,
  text
}) => (
  <li>
    {caption}: {!!text ? text : MISSING_CAPTION}
  </li>
);

interface VersionListProps {
  title: React.ReactNode;
}

export const VersionList: React.FC<VersionListProps> = ({
  title,
  children
}) => (
  <ul>
    <h5>{title}</h5>
    {children}
  </ul>
);
