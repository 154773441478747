import React, { useState, useRef, useEffect } from 'react';
import { Languages as languages } from '../../i18n';
import i18next from 'i18next';
import styled from 'styled-components/macro';
import Globe from '../Icons/Globe';
import { observer } from 'mobx-react-lite';
import { mediaSmallMax } from '../Styled/media';
import { triggerGlobalEvent } from '../../services';
import { EventCategory } from '../../services/EventCategory';
import { EventAction } from '../../services/EventAction';
import modalStore from '../../store/ModalStore';

const StyledGlobe = styled(Globe)`
  @media ${mediaSmallMax} {
    width: 20px;
    height: 20px;
  }
`;

const StyledLanguageSelector = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;

  padding: 1rem;
  font-size: 16px;
  cursor: pointer;
`;

const GlobeContainer = styled.div``;

const DropDown = styled.div`
  position: absolute;
  bottom: 0;
  right: 1rem;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 1rem;
  border-radius: 0.5rem;
  animation: dropdown-appear 0.5s ease-in-out;
  transform: translateY(100%);

  @keyframes dropdown-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media ${mediaSmallMax} {
    right: 0.5rem;

    padding: 0.5rem;
    border-radius: 4px;

    background-color: rgba(255, 255, 255, 1);
  }
`;

const DropDownItem = styled.div`
  padding: 0.25rem 0;
  transition: font-family 0.5s ease-in-out;

  &:hover {
    font-family: var(--custom-fontName);
    font-weight: 400;
  }

  @media ${mediaSmallMax} {
    font-size: 12px;
  }
`;

const useClickOutside = (ref: any, onClickOutside: () => void) => {
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const LanguageSelector = () => {
  const [showLanguages, setShowLanguages] = useState(false);

  const element = useRef(null);
  const onClickOutside = () => setShowLanguages(false);
  useClickOutside(element, onClickOutside);

  return !modalStore.isOpen ? (
    <StyledLanguageSelector
      className="font-light"
      ref={element}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <GlobeContainer
        onClick={() => {
          if (!showLanguages)
            triggerGlobalEvent({
              category: EventCategory.USER,
              action: EventAction.OPEN_LANGUAGE_SELECTOR,
            });
          setShowLanguages(!showLanguages);
        }}
      >
        <StyledGlobe fill="rgb(200,200,200)" />
      </GlobeContainer>

      {showLanguages && (
        <DropDown>
          {languages.map(lang => {
            return (
              <DropDownItem
                key={lang.code}
                onClick={e => {
                  e.stopPropagation();
                  triggerGlobalEvent({
                    category: EventCategory.USER,
                    action: EventAction.SELECT_LANGUAGE,
                  });
                  i18next.changeLanguage(lang.code);
                  window.location.reload();
                }}
              >
                {lang.title}
              </DropDownItem>
            );
          })}
        </DropDown>
      )}
    </StyledLanguageSelector>
  ) : null;
};

export default observer(LanguageSelector);
