import ReactGA, { Tracker, EventArgs, TrackerNames } from 'react-ga';
import { EventAction } from './EventAction';
import { EventCategory } from './EventCategory';
import { ModalView } from './ModalView';
import { hotjar } from 'react-hotjar';
import appStore from '../store/AppStore';

export const initializeGA = (trackers: Tracker[] = []) => {
  ReactGA.initialize(trackers, {
    alwaysSendToDefaultTracker: true,
    debug: false,
  });

  pageView(window.location.pathname + window.location.search);
};

export const initializeHJ = (hotjarIds: number[] = []) => {
  for (let i = 0, length = hotjarIds.length; i < length; i++) {
    hotjar.initialize(hotjarIds[i], 6);
  }
};

export const modalView = (
  modalName: ModalView,
  trackerNames: TrackerNames = appStore.allGATrackers
) => ReactGA.modalview(modalName, trackerNames);

export const pageView = (
  path: string,
  trackers: TrackerNames = appStore.allGATrackers
) => ReactGA.pageview(path, trackers);

interface GAEvent extends EventArgs {
  action: EventAction | string;
  category: EventCategory;
}

/**
 * Trigger event to all known trackers
 * @param gaEvent
 */
export const triggerGlobalEvent = (gaEvent: GAEvent) =>
  ReactGA.event(gaEvent, appStore.allGATrackers);

/**
 * Trigger event to GB tracker and explicitly listed trackers
 * @param gaEvent
 * @param trackers
 */
export const triggerEvent = (gaEvent: GAEvent, trackers: string[] = []) =>
  ReactGA.event(gaEvent, trackers);

// These are internal
export const triggerGAError = (action: string, label?: string) =>
  triggerEvent({ category: EventCategory.ERROR, action, label });
