import React from 'react';

export default (props: any) => (
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.0696 2.44708C20.5567 2.44708 25.8821 7.88883 25.8821 14.6815C25.8821 21.4382 20.5987 26.9158 14.0696 26.9158C7.54842 26.9158 2.25708 21.4465 2.25708 14.6815C2.25708 7.92972 7.54 2.44708 14.0696 2.44708ZM14.0696 0.634583C6.57974 0.634583 0.50708 6.92639 0.50708 14.6815C0.50708 22.4411 6.57974 28.7283 14.0696 28.7283C21.5594 28.7283 27.6321 22.4411 27.6321 14.6815C27.6321 6.92639 21.5594 0.634583 14.0696 0.634583ZM12.1008 20.119H12.7571V13.3221H12.1008C11.7384 13.3221 11.4446 13.0178 11.4446 12.6424V12.1893C11.4446 11.8139 11.7384 11.5096 12.1008 11.5096H14.7258C15.0882 11.5096 15.3821 11.8139 15.3821 12.1893V20.119H16.0383C16.4007 20.119 16.6946 20.4233 16.6946 20.7986V21.2518C16.6946 21.6271 16.4007 21.9315 16.0383 21.9315H12.1008C11.7384 21.9315 11.4446 21.6271 11.4446 21.2518V20.7986C11.4446 20.4233 11.7384 20.119 12.1008 20.119ZM14.0696 6.52521C13.1031 6.52521 12.3196 7.3367 12.3196 8.33771C12.3196 9.33872 13.1031 10.1502 14.0696 10.1502C15.0361 10.1502 15.8196 9.33872 15.8196 8.33771C15.8196 7.3367 15.0361 6.52521 14.0696 6.52521Z" />
  </svg>
);
