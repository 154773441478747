import { action, observable, computed } from 'mobx';
import { fetchContacts } from '../axios';
import appStore from './AppStore';
import alertStore from './AlertStore';
import { text } from '../utils';
import { triggerGAError } from '../services';
import Phase from '../store/models/Phase';

export class ContactStore {
  @observable public phases: Phase[] = [];
  @observable public contactsFetched: boolean = false;

  @action public fetchContacts = async () => {
    try {
      const { data } = await fetchContacts(
        appStore.organisationId,
        appStore.tenantId,
        appStore.projectId
      );
      this.phases = data.phases.map((s: any) => new Phase(s));

      this.contactsFetched = true;
    } catch (e) {
      triggerGAError('fetching contacts failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.fetchingContactsFailed'));
    }
  };

  @computed
  public get phasesWithContacts(): Phase[] {
    return this.phases.filter(p => p.hasContacts);
  }
}

const contactStore = new ContactStore();

export default contactStore;
