import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import alertStore from '../../store/AlertStore';
import Notification from '../Notification/Notification';

const topPosition = '15px';
const StyledAlert = styled.div`
  position: fixed;
  top: ${topPosition};
  left: 50vw;
  transform: translateX(-50%);
  background: white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);

  animation: alert-appear 0.3s ease-in-out;
  @keyframes alert-appear {
    from {
      top: 0vh;
      opacity: 0;
    }

    to {
      top: ${topPosition};
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  min-width: 100%;
`;

const ClickOutsideHandler = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
`;

const Alert = () =>
  alertStore.isOpen ? (
    <ClickOutsideHandler onClick={alertStore.hide}>
      <StyledAlert>
        <Notification color={alertStore.color} icon={alertStore.icon}>
          <Content>
            {alertStore.title && (
              <p className="font-medium">{alertStore.title}</p>
            )}
            {alertStore.message && alertStore.message.length && (
              <p className="font-normal">{alertStore.message}</p>
            )}
          </Content>
        </Notification>
      </StyledAlert>
    </ClickOutsideHandler>
  ) : null;

export default observer(Alert);
