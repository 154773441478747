import { observable, action, computed } from 'mobx';
import Material, { MaterialData } from './Material';
import moment from 'moment';
import { text } from '../../utils';
import BundleState from '../enums/BundleState';
import roomsStore from '../RoomsStore';
import themeStore from '../ThemeStore';
import offersStore from '../OffersStore';
import Room from './Room';
import { Offer } from './Offer';
import OfferState from '../../store/enums/OfferState';
import { getFormattedDateBasedOnLocale } from '../../utils/dates';
import DateFormat from '../../store/enums/DateFormat';

export enum SelectionOrigin {
  THEME = 'THEME',
  USER = 'USER',
  DEFAULT = 'DEFAULT',
}

export interface BundleData {
  name: string;
  canIgnore: boolean;
  deadline: string | null;
  deadlineEvent: string;
  description: string | null;
  id: number;
  ignoreIsDefault: boolean;
  ignoreText: string;
  materialOptions?: MaterialData[];
  state: BundleState;
  selectedMaterial?: MaterialData;
  isIgnored?: boolean;
  selectionOrigin?: SelectionOrigin;
  ownerCanSeeBundle: boolean;
  roomIds?: number[];
  roomId: number | null;
  url: string | null;
  showInApartmentLevel?: boolean;
  showInRoomLevel?: boolean;
  isApartmentLevel?: boolean;
  offers: Offer[];
  isPartOfOffer?: boolean;
}

export default class Bundle {
  @observable public name: string;
  @observable public canIgnore: boolean;
  @observable public deadline: string | null;
  @observable public deadlineEvent: string;
  @observable public description: string | null;
  @observable public id: number;
  @observable public ignoreIsDefault: boolean;
  @observable public ignoreText: string;
  @observable public materialOptions: Material[];
  @observable public state = BundleState.OPEN;
  @observable public selectedMaterial?: number | null;
  @observable public offerSelectedMaterial?: Material;
  @observable public isIgnored?: boolean;
  @observable public selectionOrigin?: SelectionOrigin;
  @observable public roomIds: number[] = [];
  @observable public roomId: null | number = null;
  @observable public url: string | null;
  @observable public showInApartmentLevel?: boolean;
  @observable public showInRoomLevel?: boolean;
  @observable public isApartmentLevel?: boolean;
  @observable public offers: Offer[];
  @observable public isPartOfOffer?: boolean;
  @observable public selected = false;

  constructor(data: BundleData) {
    this.name = data.name;
    this.canIgnore = data.canIgnore;
    this.deadline = data.deadline;
    this.deadlineEvent = data.deadlineEvent;
    this.description = data.description;
    this.id = data.id;
    this.ignoreIsDefault = data.ignoreIsDefault;
    this.ignoreText =
      data.ignoreText && data.ignoreText !== ''
        ? data.ignoreText
        : text('noSelectionText');
    this.materialOptions = (data.materialOptions || []).map(
      m => new Material(m)
    );

    this.roomId = data.roomId;
    this.roomIds = data.roomIds || [];

    if (data.roomId && !(data.roomIds || []).includes(data.roomId))
      this.roomIds.push(data.roomId);

    this.selectedMaterial = data.selectedMaterial
      ? data.selectedMaterial.projectMaterialId
      : undefined;
    this.offerSelectedMaterial =
      data.isPartOfOffer && data.selectedMaterial
        ? new Material(data.selectedMaterial)
        : undefined;
    this.isIgnored = data.isIgnored;
    this.selectionOrigin = data.selectionOrigin;
    this.state =
      !this.deadlineGone &&
      data.state !== BundleState.CONFIRMED &&
      data.state !== BundleState.LOCKED
        ? data.state
        : BundleState.DEADLINE_GONE;

    this.url = data.url;
    this.isApartmentLevel = data.isApartmentLevel;
    this.showInApartmentLevel = data.showInApartmentLevel;
    this.showInRoomLevel = data.showInRoomLevel;
    this.offers = data.offers;
    this.isPartOfOffer = data.isPartOfOffer;
    if (data.selectedMaterial) {
      this.targetMaterial(data.selectedMaterial.projectMaterialId);
    }
  }

  @action
  public changeSelectedMaterial(selectedMaterial: number | null) {
    this.selectedMaterial =
      selectedMaterial !== undefined ? selectedMaterial : undefined;
  }

  @action
  public toggleSelected = (selected?: boolean) => {
    this.selected = selected !== undefined ? selected : !this.selected;
  };

  @action
  public targetMaterial = (materialId: number | null) => {
    this.materialOptions = this.materialOptions.map(m => {
      if (materialId === m.projectMaterialId) m.toggleTargeted(true);
      else if (m.targeted) m.toggleTargeted(false);
      return m;
    });
  };

  @action
  public changeState = (state: BundleState) => {
    this.state = state;
  };

  @computed get rooms(): Room[] {
    return roomsStore.rooms.filter(room => this.roomIds.includes(room.id));
  }

  @computed
  get selectedMaterialOption() {
    if (this.isPartOfOffer && !!this.offers.length) {
      return !!this.offerSelectedMaterial && this.selectedMaterial
        ? this.offerSelectedMaterial
        : offersStore.getFirstMaterial(this.offers[0].id, this.id);
    }
    if (!this.selectedMaterial && this.isIgnored === true) {
      return undefined;
    }
    const selectedMaterial = this.materialOptions.find(
      m => m.projectMaterialId === this.selectedMaterial
    );

    return selectedMaterial;
  }

  @computed
  public get isGeneralAppearanceMaterial(): boolean {
    return this.roomIds.length > 1 || this.roomIds.length === 0;
  }

  @computed
  public get materialOptionsFilteredBySelectedSubPackages(): Material[] {
    const { allSelectedSubThemeIdsFromSelectedSets } = themeStore;
    if (!allSelectedSubThemeIdsFromSelectedSets.length) {
      return this.materialOptions;
    }

    const filteredMaterialOptions = this.materialOptions.filter(
      (material: Material) =>
        allSelectedSubThemeIdsFromSelectedSets.some(
          subThemeId => subThemeId && material.isInPackages.includes(subThemeId)
        ) || !material.isInPackages.length
    );

    return !!filteredMaterialOptions.length
      ? filteredMaterialOptions
      : this.materialOptions;
  }

  @computed
  get defaultMaterialOption() {
    return (
      this.defaultThemeMaterialOption ||
      this.materialOptionsFilteredBySelectedSubPackages.find(
        (material: Material) => material.isDefault
      ) ||
      this.materialOptionsFilteredBySelectedSubPackages.find(
        (material: Material) => material.isDefaultInProject
      )
    );
  }

  @computed
  get defaultThemeMaterialOption() {
    const { selectedTheme } = themeStore;

    const defaultThemeMaterial = this.materialOptionsFilteredBySelectedSubPackages.find(
      (material: Material) =>
        selectedTheme?.id &&
        material.isDefaultInPackages &&
        material.isDefaultInPackages.includes(selectedTheme.id)
    );

    return defaultThemeMaterial;
  }

  @computed
  get targetedMaterial() {
    if (this.hasAppliedToDbOffer) {
      return this.selectedMaterialOption;
    }
    return this.materialOptions.find(m => m.targeted);
  }

  @computed
  get deadlineAsDate() {
    return (
      this.deadline &&
      getFormattedDateBasedOnLocale(this.deadline, DateFormat.COMPACT)
    );
  }

  @computed
  get deadlineNear() {
    return this.deadline
      ? moment(this.deadline).add(-2, 'weeks').isBefore(moment())
      : false;
  }

  @computed
  get deadlineGone() {
    return this.deadline ? moment(this.deadline).isBefore(moment()) : false;
  }

  @computed
  get defaultMaterialId() {
    return (
      this.defaultMaterialOption && this.defaultMaterialOption.projectMaterialId
    );
  }

  @computed
  get samePricedMaterials() {
    return this.materialOptionsFilteredBySelectedSubPackages.filter(
      m =>
        m.price === null ||
        (m.price.equals(0) && m.projectMaterialId !== this.defaultMaterialId)
    );
  }

  @computed
  get differentPricedMaterials() {
    return this.materialOptionsFilteredBySelectedSubPackages.filter(
      m =>
        m.price !== null &&
        !m.price.equals(0) &&
        m.projectMaterialId !== this.defaultMaterialId
    );
  }

  @computed
  get isAccessory() {
    return this.canIgnore;
  }

  @computed
  get hasOpenOffer() {
    return this.isPartOfOffer && this.offers.length === 0 ? true : false;
  }

  @computed
  get hasAppliedToDbOffer() {
    return this.isPartOfOffer && !!this.offers.length ? true : false;
  }

  @computed
  get hasPendingOffers() {
    return this.isPartOfOffer &&
      !!this.offers.length &&
      !!this.offers.find(o => {
        return o.state === OfferState.SENT_TO_CUSTOMER ? true : false;
      })
      ? true
      : false;
  }

  @computed
  get canSelectionModalBeOpened() {
    return !!this.selectedMaterialOption ||
      (!!this.materialOptions.length && !this.deadlineGone)
      ? true
      : true;
  }

  @computed
  get isPartOfNonDraftOffer() {
    return this.isPartOfOffer && !!this.offers.length;
  }
}
