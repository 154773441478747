import React, { useState } from 'react';
import { UserRight } from '../../store/enums/UserRights';
import userRightsStore from '../../store/UserRightsStore';
import { text } from '../../utils';
import attachmentsStore from '../../store/AttachmentsStore';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';

const DownloadZipButton = ({ ...props }: ButtonProps) => {
  const { downloadZip } = attachmentsStore;
  const [showSpinner, setShowSpinner] = useState(false);
  const setLoading = async (asyncFn: Promise<void>) => {
    setShowSpinner(true);
    await asyncFn;
    setShowSpinner(false);
  };
  return (
    <>
      {userRightsStore.check(UserRight.ZIP_ATTACHMENT) && (
        <>
          <div
            style={{
              marginTop: '1em',
            }}
          >
            <Button
              endIcon={<ArchiveIcon />}
              onClick={async (
                evt: React.MouseEvent<HTMLSpanElement, MouseEvent>
              ) => {
                evt.preventDefault();
                evt.stopPropagation();
                await setLoading(downloadZip());
              }}
              color="primary"
              variant="contained"
              style={{ float: 'left' }}
              {...props}
            >
              {text('zipDownload')}
            </Button>
            {showSpinner && (
              <CircularProgress
                style={{
                  float: 'left',
                  marginLeft: '.4em',
                  marginTop: '.3em',
                  height: '25px',
                  width: '25px',
                  color: 'white',
                }}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DownloadZipButton;
