/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import 'normalize.css';
import styled, { createGlobalStyle } from 'styled-components/macro';
import OrganisationCustomisation from './store/models/OrganisationCustomisation';
import Footer from './components/Footer/Footer';
import { Switch, Route, withRouter } from 'react-router';
import OrderConfirmation from './routes/OrderConfirmation/OrderConfirmation';
import appStore from './store/AppStore';
import MaterialSelectorPage from './routes/MaterialSelectorPage/MaterialSelectorPage';
import LightBox from './components/LightBox/LightBox';
import ConfirmationDialog from './components/ConfirmationDialog/ConfirmationDialog';
import Alert from './components/Alert/Alert';
import {
  footerHeight,
  footerHeightMobile,
} from './components/Footer/FooterStyledComponents';
import HeaderBanner from './components/HeaderBanner/HeaderBanner';
import { getParams } from './store/RoutingStore';
import Schedule from './routes/Schedule/Schedule';
import Messages from './routes/Messages/Messages';
import schedules from './routes/Schedule/schedules';
import Contact from './routes/Contact/Contact';
import Documents from './routes/Documents/Documents';
import AttachmentModal from './routes/AttachmentModal/AttachmentModal';
import { mediaSmallMax } from './components/Styled/media';
import LanguageSelector from './components/LanguageSelector/LanguageSelector';
import './fonts/baseFonts.css';
import { Element } from 'react-scroll';
import Admin from './routes/Admin';
import CookiePopUp from './components/CookiePopUp/CookiePopUp';
import calendarStore from './store/CalendarStore';
import OrganisationStyle from './OrganisationStyle';
import setUpGiosg from './services/giosg';
import setUpUserlane from './services/userlane';
import TermsAndConditions from './components/OffersConfirmation/TermsAndConditions';

interface RootProps {
  customisation?: OrganisationCustomisation;
}

function addCustomisation(customisation?: OrganisationCustomisation) {
  if (customisation) {
    const fontLight = `.font-light {
      font-family: ${
        customisation.fontLight || customisation.fontNormal
      }, sans-serif;
      font-weight: 100;
    }`;

    const fontNormal = `.font-normal {
      font-family: ${
        customisation.fontNormal || customisation.fontNormal
      }, sans-serif;
      font-weight: 400;
    }`;

    const fontMedium = `.font-medium {
      font-family: ${
        customisation.fontMedium || customisation.fontNormal
      }, sans-serif;
      font-weight: 400;
    }`;

    const fontBold = `.font-bold {
      font-family: ${
        customisation.fontBold || customisation.fontNormal
      }, sans-serif;
      font-weight: 700;
    }`;

    const fontSrc = customisation.fontSrc
      ? `@import url(${customisation.fontSrc});`
      : '';

    const styles = `
      ${fontSrc ? fontSrc : ''}
      --custom-fontName: ${customisation.fontNormal}, sans-serif;
      --custom-colorMain: ${customisation.colorMain};
      --custom-colorHover: ${customisation.colorHover};
      --custom-colorAccent: ${customisation.colorAccent};
      --custom-colorAccent2: ${customisation.colorAccent2};
      --custom-colorAccent3: ${customisation.colorAccent3};
      --custom-colorNotif1: ${customisation.colorNotif1};
      --custom-colorNotif2: ${customisation.colorNotif2};
      --custom-colorNotif3: ${customisation.colorNotif3};
      --custom-colorNotif4: ${customisation.colorNotif4};
      --custom-colorLoadingBackground: ${customisation.colorLoadingBackground};
      --custom-colorLoadingSpinner: ${customisation.colorLoadingSpinner};
      --custom-colorNavLinks: ${
        customisation.colorNavLinks || customisation.colorAccent2
      };
      --custom-fontNameLight: ${
        customisation.fontLight || customisation.fontNormal
      }, sans-serif;
      --custom-fontNameMedium: ${
        customisation.fontMedium || customisation.fontNormal
      }, sans-serif;
      --custom-fontNameBold: ${
        customisation.fontBold || customisation.fontNormal
      }, sans-serif;
      ${fontLight}
      ${fontNormal}
      ${fontMedium}
      ${fontBold}
    `;

    return styles;
  } else return '';
}

const GlobalStyle = createGlobalStyle`


  body, #root {
    margin-bottom: ${footerHeight};
    font-size: 18px;

    @media ${mediaSmallMax} {
      margin-bottom: ${footerHeightMobile};
    }
  }
  h1 {
    font-family: var(--custom-fontNameLight);
    font-size: 55px;
    line-height: 59px;
    font-weight: 300;
  }
  h2 {
    font-size: 35px;
    font-weight: 400;

    @media ${mediaSmallMax} {
      font-size: 18px;
      line-height: 21px;
    }
  }
  h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;

    @media ${mediaSmallMax} {
      font-size: 18px;
      line-height: 21px;
    }
  }
  p {
    font-family: var(--custom-fontNameLight);
    line-height: 22px;
    font-weight: 100;

    @media ${mediaSmallMax} {
      font-size: 14px;
      line-height: 17px;
    }
  }
  small {
    font-family: var(--custom-fontNameLight);
    font-size: 14px;
    font-weight: 100;
  }

  h1, h2, h3, p, small {
    margin: 0;
  }

  a {
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    border-bottom: 1px solid var(--custom-colorAccent2);
    color: var(--custom-colorAccent2);
    font-weight: 100;
    line-height: 22px;
    font-family: var(--custom-fontNameLight);

    &:hover {
      border-color: var(--custom-colorHover);
    }

    @media ${mediaSmallMax} {
      font-size: 14px;
      line-height: 17px;
    }
  }

  button {
    border: 0;
    background: none;
    cursor: pointer;
  }
`;

interface RootProps {
  customisation?: OrganisationCustomisation;
}

const Root = styled(Element)<RootProps>`
  ${({ customisation }) => addCustomisation(customisation)}
  --color-darkGray: #333333;
  --color-lightGray: #f0f0f0;
  --color-gray: #bdbdbd;

  font-family: var(--custom-fontName);
  color: var(--custom-colorAccent2);

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }
`;

const termsOfUseLink =
  'https://gbuilder.blob.core.windows.net/legal/GBuilder%20Terms%20of%20Use.pdf';
const privacyPolicyLink =
  'https://gbuilder.blob.core.windows.net/legal/GBuilder%20Privacy%20Policy.pdf';

const App = () => {
  useEffect(() => {
    appStore.setup();
  }, []);

  useEffect(() => {
    if (appStore.tenantSettings.customerJourney2_useGiosg) {
      return setUpGiosg(appStore.giosgIds);
    }
    return;
  }, [
    appStore.tenantSettings.customerJourney2_useGiosg,
    appStore.giosgIds.toString(),
  ]);

  useEffect(() => {
    if (
      appStore.tenantSettings.userlaneCj2 &&
      !!appStore.tenantSettings.userlaneKey
    )
      setUpUserlane(appStore.tenantSettings.userlaneKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appStore.tenantSettings.userlaneCj2,
    appStore.tenantSettings.userlaneKey,
  ]);

  const {
    help,
    schedule,
    messages,
    contact,
    documents,
    termsOfUse,
    privacyPolicy,
    offersTermsAndConditions,
  } = getParams();

  return (
    <Root
      name="root"
      id="root"
      customisation={appStore.organisationCustomisation}
    >
      <CookiePopUp />
      <LanguageSelector />
      {appStore.tenantSettingsFetched && (
        <>
          {appStore.tenantSettings.customerJourney2_showHeader && (
            <HeaderBanner />
          )}
          <Switch>
            <Route path="/order-confirmation" component={OrderConfirmation} />
            <Route component={MaterialSelectorPage} />
          </Switch>
          <GlobalStyle />
          <OrganisationStyle organisation={appStore.organisationId} />
          {appStore.tenantSettings.customerJourney2_showFooter && <Footer />}
          <LightBox />
          <ConfirmationDialog />
          <Alert />
        </>
      )}
      {appStore.tenantSettings.customerJourney2_showFooter && (
        <>
          <Switch>
            <Route path="/version" component={Admin} />
          </Switch>
          {schedule &&
            (!!schedules[appStore.projectIdentifier] ||
              !!calendarStore.calendarEvents.length) && <Schedule />}
          {messages && <Messages />}
          {contact && <Contact />}
          {documents && <Documents />}
          {help && appStore.tenantSettings.customerJourney2_instructionsUrl && (
            <AttachmentModal
              param="help"
              embed={appStore.tenantSettings.customerJourney2_instructionsUrl}
            />
          )}
          {termsOfUse && (
            <AttachmentModal param="termsOfUse" embed={termsOfUseLink} />
          )}
          {privacyPolicy && (
            <AttachmentModal param="privacyPolicy" embed={privacyPolicyLink} />
          )}
          {offersTermsAndConditions && <TermsAndConditions />}
        </>
      )}
    </Root>
  );
};

export default (withRouter as any)(observer(App));
