import React from 'react';

export default (props: any) => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M40.8916 39.4681L30.9827 29.5614C33.6408 26.5063 35.2501 22.5201 35.2501 18.1634C35.2501 8.57305 27.4477 0.781494 17.8644 0.781494C8.27192 0.781494 0.478638 8.58209 0.478638 18.1634C0.478638 27.7446 8.28096 35.5452 17.8644 35.5452C22.2221 35.5452 26.2091 33.9363 29.265 31.2788L39.1738 41.1855C39.4089 41.4205 39.7253 41.5471 40.0327 41.5471C40.3401 41.5471 40.6565 41.4296 40.8916 41.1855C41.3617 40.7155 41.3617 39.9381 40.8916 39.4681ZM2.91065 18.1634C2.91065 9.91985 9.61902 3.22201 17.8553 3.22201C26.1006 3.22201 32.8 9.92889 32.8 18.1634C32.8 26.3978 26.1006 33.1137 17.8553 33.1137C9.61902 33.1137 2.91065 26.4069 2.91065 18.1634Z" />
  </svg>
);
