import React from 'react';
import styled from 'styled-components/macro';
import { mediaSmallMax } from '../Styled/media';

const CloseIcon = (props: any) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.773438 23.729L11.4152 13.2829L0.969078 2.64116L2.70464 0.9375L13.1507 11.5792L23.7925 1.13314L25.4961 2.8687L14.8544 13.3148L25.3005 23.9565L23.5649 25.6602L13.1188 15.0185L2.47709 25.4646L0.773438 23.729Z" />
  </svg>
);

export default CloseIcon;

export const ResponsiveCloseIcon = styled(CloseIcon)`
  @media ${mediaSmallMax} {
    width: 14px;
    height: 14px;
  }
`;
