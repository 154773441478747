export const Languages = [
  {
    code: 'en-US',
    title: 'English (US)',
  },
  {
    code: 'en-GB',
    title: 'English (GB)',
  },
  {
    code: 'fi',
    title: 'Finnish',
  },
  {
    code: 'de',
    title: 'German',
  },
  {
    code: 'ru',
    title: 'Russian',
  },
  {
    code: 'et',
    title: 'Estonian',
  },
  {
    code: 'nb',
    title: 'Norwegian Bokmål',
  },
  {
    code: 'es',
    title: 'Spanish',
  },
  {
    code: 'cs',
    title: 'Czech',
  },
  {
    code: 'sk',
    title: 'Slovak',
  },
  {
    code: 'sv',
    title: 'Swedish',
  },
  {
    code: 'lt',
    title: 'Lithuanian',
  },
  {
    code: 'fr',
    title: 'French',
  },
  {
    code: 'nl',
    title: 'Dutch',
  },
];
