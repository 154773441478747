import { observable, action, computed } from 'mobx';
import {
  fetchRoomMessages,
  postRoomMessage,
  fetchMassMessages,
} from '../axios';
import Message, { MessageData } from './models/Message';
import appStore from './AppStore';
import MessageTypes from './enums/MessageTypes';
import alertStore from './AlertStore';
import { text } from '../utils';
import roomsStore, { RoomId } from './RoomsStore';
import { triggerGlobalEvent, triggerGAError } from '../services';
import { EventAction } from '../services/EventAction';
import { EventCategory } from '../services/EventCategory';

export class MessagesStore {
  @observable public messageType: MessageTypes = MessageTypes.OPEN;
  @observable public messages: Map<RoomId, Message[]> = new Map<
    RoomId,
    Message[]
  >();
  @observable public massMessages: Message[] = [];
  @observable public messagesFetched: boolean = false;
  @observable public messageInput: string = '';

  @action
  public changeMessageType = (messageType: MessageTypes) => {
    this.messageType = messageType;
    if (messageType === MessageTypes.MASS) {
      triggerGlobalEvent({
        action: EventAction.OPEN_BULLETIN,
        category: EventCategory.USER,
      });
    }
  };

  @action
  public fetchMessages = async () => {
    if (roomsStore.selectedRoom) {
      try {
        const resp = await fetchRoomMessages(roomsStore.selectedRoom.id);
        this.messages.set(
          roomsStore.selectedRoom.id,
          resp.map((m: any) => new Message(m))
        );
      } catch (e) {
        triggerGAError('fetching messages failed', e.toString());
        console.error(e);
        alertStore.show(text('errors.fetchingMessagesFailed'));
      }
    }
  };

  @action
  public fetchMessagesForAllRooms = async () => {
    try {
      const roomsWithMessages: Array<{
        id: RoomId;
        messages: Message[];
      }> = await Promise.all(
        roomsStore.roomsWithMessages.map(async r => {
          const resp = await fetchRoomMessages(r.id);

          return { id: r.id, messages: resp.map((m: any) => new Message(m)) };
        })
      );

      this.messages = roomsWithMessages.reduce((pre, cur) => {
        const messages = cur.messages;
        messages.sort((a: Message, b: Message) =>
          a.sendTime > b.sendTime ? 1 : -1
        );
        return pre.set(cur.id, messages);
      }, new Map<RoomId, Message[]>());
    } catch (e) {
      triggerGAError('Token exchange failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.fetchingMessagesFailed'));
    }
  };

  @action
  public fetchMassMessages = async () => {
    try {
      if (appStore.apartmentId) {
        const resp = await fetchMassMessages(Number(appStore.apartmentId));
        this.massMessages = resp.map((m: any) => new Message(m));
      }
    } catch (e) {
      triggerGAError('fetching messages failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.fetchingMessagesFailed'));
    }
  };

  @action
  public fetchAllMessages = async () => {
    try {
      if (!this.messagesFetched) {
        await this.fetchMessagesForAllRooms();
      }

      if (this.messageType === MessageTypes.MASS) {
        await this.fetchMassMessages();
      }
      this.messagesFetched = true;
    } catch (e) {
      triggerGAError('fetching messages failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.fetchingMessagesFailed'));
    }
  };

  @action
  public changeMessageInput = (event: any) => {
    this.messageInput = event.target.value;
  };

  @action
  public clearMessageInput = () => (this.messageInput = '');

  @action
  public sendMessage = async () => {
    const message = this.messageInput.trim();
    this.clearMessageInput();
    triggerGlobalEvent({
      action: EventAction.SEND_MESSAGE,
      category: EventCategory.USER,
    });

    if (roomsStore.selectedRoom && message) {
      const oldMessages = this.messages.get(roomsStore.selectedRoom.id) || [];
      const messageExample = oldMessages.find(msg => msg.isFromUser === 1);
      const userName = messageExample ? messageExample.userName : 'You';
      const messageData: MessageData = {
        id: Date.now(),
        content: message,
        userName,
        isFromUser: 1,
        discussionEnd: 0,
        sendTime: Date.now() / 1000,
        received: 0,
        attachmentFileDisplayName: '',
        attachmentFilePath: '',
      };

      const newMessage = new Message(messageData);
      newMessage.changeSending(true);

      this.messages.set(roomsStore.selectedRoom.id, [
        ...oldMessages,
        newMessage,
      ]);

      try {
        await postRoomMessage(
          roomsStore.selectedRoom.id,
          'customer',
          message,
          appStore.environmentLocale
        );
        newMessage.changeSending(false);
        this.messages.set(roomsStore.selectedRoom.id, [
          ...oldMessages,
          newMessage,
        ]);
        await this.fetchMessages();
      } catch (e) {
        triggerGAError('sending message failed', e.toString());
        this.messages.set(roomsStore.selectedRoom.id, oldMessages);
        console.error(e);
        alertStore.show(text('errors.sendingMessageFailed'));
      }
    }
  };

  @action
  public selectRoom = (roomId?: RoomId) => {
    roomsStore.selectRoom(roomId, true);
  };

  @computed
  public get unreadMessages() {
    const unreadMessages = roomsStore.rooms.reduce((pre, cur) => {
      return pre + cur.unreadMessages;
    }, 0);

    return unreadMessages;
  }

  @computed
  get selectedRoom() {
    return roomsStore.selectedRoom;
  }

  @computed
  get openMessages() {
    const filteredMessages = new Map<RoomId, Message[]>();
    this.messages.forEach((msg, key) => {
      const messages = msg.filter(message => message.discussionEnd === 0);
      filteredMessages.set(key, messages);
    });
    return filteredMessages;
  }

  @computed
  get closedMessages() {
    const filteredMessages = new Map<RoomId, Message[]>();
    this.messages.forEach((msg, key) => {
      const messages = msg.filter(message => message.discussionEnd === 1);
      filteredMessages.set(key, messages);
    });
    return filteredMessages;
  }

  @computed
  get filteredMessages() {
    switch (this.messageType) {
      case MessageTypes.OPEN:
        return this.openMessages;
      case MessageTypes.CLOSED:
        return this.closedMessages;
    }

    return this.messages;
  }

  @computed
  get filteredRoomMessages() {
    return this.selectedRoom ? this.messages.get(this.selectedRoom.id) : [];
  }
}

const messagesStore = new MessagesStore();

export default messagesStore;
