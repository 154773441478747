import React, { useState } from 'react';
import {
  SectionTitleDivider,
  SectionTitle,
  SectionArrow,
} from '../Styled/SectionTitleDivider';
import styled from 'styled-components/macro';
import BundlesConfirmation from './BundlesConfirmation';
import Bundle from '../../store/models/Bundle';
import { observer } from 'mobx-react-lite';
import SectionResizer from '../SectionResizer/SectionResizer';
import { mediaSmallMax } from '../Styled/media';

export const RoomDivider = styled(SectionTitleDivider)`
  margin: 0 3rem;
  padding: 0;
  background: none;
  height: 3rem;

  &:hover {
    background: none;
  }

  &:before {
    left: 0;
    width: 100%;
    display: block;
  }

  @media ${mediaSmallMax} {
    margin: 0 1rem;
    padding: 1rem 0;

    &:before {
      opacity: 0.5;
    }
  }
`;

const RoomName = styled.h3`
  font-size: 24px;

  @media ${mediaSmallMax} {
    font-size: 18px;
    line-height: 21px;
  }
`;

const ConfirmationRoomContainer = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
`;

interface ConfirmationRoomProps {
  bundles: Bundle[];
  roomName: string;
  openAtStart?: boolean;
}

const ConfirmationRoom = ({
  bundles,
  roomName,
  openAtStart,
}: ConfirmationRoomProps) => {
  const [maxHeight, setMaxHeight] = useState('48px');
  const [isOpen, setIsOpen] = useState(!!openAtStart);

  return (
    <ConfirmationRoomContainer style={{ maxHeight, minHeight: maxHeight }}>
      <SectionResizer
        effectListeners={[isOpen, bundles.length]}
        setNewHeight={setMaxHeight}
      >
        <RoomDivider open={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <SectionTitle>
            <RoomName>{roomName}</RoomName>
          </SectionTitle>
          <SectionArrow open={isOpen} />
        </RoomDivider>
        {isOpen && <BundlesConfirmation bundles={bundles} />}
      </SectionResizer>
    </ConfirmationRoomContainer>
  );
};

export default observer(ConfirmationRoom);
