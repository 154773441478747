import React, { useEffect, Fragment, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { changeParams } from '../../store/RoutingStore';
import { text } from '../../utils';
import Modal from '../../components/Modal/Modal';
import styled from 'styled-components/macro';
import Message from './Message';
import RoomCard from './RoomCard';
import MessageModel from '../../store/models/Message';
import messagesStore from '../../store/MessagesStore';
import MessageTypes from '../../store/enums/MessageTypes';
import MessagesBackground from './MessagesBackground.png';
import SendIcon from '../../components/Icons/send.svg';
import { withRouter } from 'react-router';
import ArrowRight from '../../components/Icons/ArrowRight';
import ModalHeader, {
  modalHeaderHeight,
  StyledModalHeader,
} from '../../components/Modal/ModalHeader';
import roomsStore from '../../store/RoomsStore';
import TrackVisibility from 'react-on-screen';
import CircleBadge from '../../components/CircleBadge/CircleBadge';
import { mediaSmallMax } from '../../components/Styled/media';
import { triggerGlobalEvent } from '../../services';
import { EventAction } from '../../services/EventAction';
import { EventCategory } from '../../services/EventCategory';
import {
  TooltipContainer,
  Tooltip,
} from '../../components/utils/styledComponents';

const StyledTooltipContainer = styled(TooltipContainer)`
  border: 1px solid rgba(0, 159, 218, 0.8);
  top: 50%;
`;

const StyledCircle = styled(CircleBadge)`
  background-color: var(--custom-colorNotif3);
  position: absolute;
  top: 2rem;
  right: 50%;
  transform: translateX(5rem);
`;

const StyledMessages = styled.section`
  height: 100%;

  @media ${mediaSmallMax} {
    ${StyledModalHeader} {
      display: none;
    }
  }
`;

const Content = styled.div`
  padding-top: 0.7rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;

  @media ${mediaSmallMax} {
    padding: 1rem;
  }
`;

const MessagesMenu = styled.div``;

const ButtonRow = styled.div<{ hideOnMobile?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.7rem;
  text-align: center;

  @media ${mediaSmallMax} {
    margin-top: 2rem;
    margin-bottom: 0.25rem;

    ${({ hideOnMobile }) => (hideOnMobile ? 'display: none;' : '')}
  }
`;

const StyledButton = styled.div<{ selected: boolean }>`
  cursor: pointer;
  position: relative;
  border-bottom: ${({ selected }) =>
    selected ? '4px solid #3F9C35' : '1px solid #B9C9DD'};
  padding: 1rem 0;
  font-size: 24px;

  @media ${mediaSmallMax} {
    font-size: 18px;
    line-height: 21px;
  }
`;

const RoomList = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
`;

const ChatContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  position: relative;
  margin-top: 2.9rem;
  max-height: calc(100vh - ${modalHeaderHeight} - 180px - 2.9rem - 0.7rem);

  @media ${mediaSmallMax} {
    margin-top: 0;
    max-height: calc(100vh - 2rem);
    height: calc(100vh - 2rem);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: 3rem;
  p {
    margin-left: 1.7rem;
    display: inline;
    align-self: center;
  }

  @media ${mediaSmallMax} {
    display: none;
  }
`;

const RoomName = styled.div`
  padding-bottom: 1.4rem;
  position: relative;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0.5;
    background-color: var(--custom-colorAccent3);
  }
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(-180deg);
`;

const MessagesContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: auto;
  min-height: 20rem;

  @media ${mediaSmallMax} {
    margin: 1rem 0;
    min-height: 0;
  }
`;

const MessageInputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 3rem;
  flex-grow: 1;

  img {
    cursor: pointer;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }

  @media ${mediaSmallMax} {
    img {
      bottom: 1rem;
      right: 1rem;
      width: 24px;
    }
    margin-bottom: 0;
    max-height: 7rem;
    height: 60px;
  }
`;

const MessageInput = styled.textarea`
  color: var(--custom-colorAccent2);
  width: 100%;
  min-height: 10rem;
  max-height: 20rem;
  resize: vertical;
  font-size: 18px;
  line-height: 21px;
  padding: 1.5rem 1rem;
  outline: none;
  border: 1px solid var(--custom-colorAccent3);

  @media ${mediaSmallMax} {
    min-height: 60px;
    height: 100%;
    padding: 0.5rem;
    font-size: 12px;
    line-height: 13px;
    padding-right: 3rem;
  }
`;

const Messages = () => {
  const messagesEnd = useRef<HTMLDivElement>(null);

  const {
    fetchAllMessages,
    messageType,
    massMessages,
    unreadMessages,
    selectedRoom,
    filteredRoomMessages,
    changeMessageType,
    messagesFetched,
    selectRoom,
    messageInput,
    changeMessageInput,
    sendMessage,
  } = messagesStore;

  useEffect(() => {
    fetchAllMessages();
  }, [messageType, fetchAllMessages]);

  const messages =
    messageType === MessageTypes.MASS ? massMessages : filteredRoomMessages;

  useEffect(() => {
    messagesEnd?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <Modal
      onClose={() => {
        changeParams({ messages: undefined });
      }}
      overflow="scroll"
    >
      <StyledMessages>
        <ModalHeader
          bgImage={MessagesBackground}
          title={text('messages')}
          description={text('description')}
        />
        <Content>
          <MessagesMenu>
            <ButtonRow
              hideOnMobile={selectedRoom && messageType !== MessageTypes.MASS}
            >
              <StyledButton
                onClick={() => changeMessageType(MessageTypes.OPEN)}
                selected={messageType === MessageTypes.OPEN}
              >
                {text('messages')}
                {unreadMessages > 0 && <StyledCircle>!</StyledCircle>}
              </StyledButton>
              <StyledButton
                onClick={() => changeMessageType(MessageTypes.MASS)}
                selected={messageType === MessageTypes.MASS}
              >
                {text('bulletin')}
              </StyledButton>
            </ButtonRow>
            {messagesFetched &&
              !selectedRoom &&
              messageType !== MessageTypes.MASS && (
                <RoomList>
                  {roomsStore.rooms.map(room => {
                    const roomMessages = messagesStore.messages.get(room.id);
                    const lastMessage =
                      roomMessages && roomMessages[roomMessages.length - 1];

                    return (
                      <RoomCard
                        key={room.id}
                        name={room.name}
                        message={lastMessage}
                        unreadMessages={room.unreadMessages}
                        onClick={() => {
                          selectRoom(room.id);
                          triggerGlobalEvent({
                            action: EventAction.OPEN_MESSAGE_ROOM,
                            category: EventCategory.USER,
                          });
                        }}
                      />
                    );
                  })}
                </RoomList>
              )}
          </MessagesMenu>
          {(selectedRoom || messageType === MessageTypes.MASS) && (
            <ChatContainer>
              {selectedRoom && messageType !== MessageTypes.MASS && (
                <>
                  <BackButton onClick={() => selectRoom()}>
                    <ArrowLeft width={18} height={31} fill={'#294754'} />
                    <p>{text('back')}</p>
                  </BackButton>
                  <RoomName>{selectedRoom.name}</RoomName>
                </>
              )}
              <MessagesContainer>
                {messages &&
                  messages.map((message: MessageModel) => (
                    <Fragment key={message.id}>
                      <TrackVisibility>
                        {({ isVisible }) => (
                          <Message
                            message={message}
                            mass={messageType === MessageTypes.MASS}
                            read={
                              isVisible &&
                              messageType === MessageTypes.MASS &&
                              message.received === 0
                            }
                          />
                        )}
                      </TrackVisibility>
                    </Fragment>
                  ))}
                {messages?.some(m => m.discussionEnd === 1) && (
                  <StyledTooltipContainer>
                    <Tooltip>{text('conversationEndedText')}</Tooltip>
                  </StyledTooltipContainer>
                )}
                <div style={{ visibility: 'hidden' }} ref={messagesEnd} />
              </MessagesContainer>
              {messageType === MessageTypes.OPEN && (
                <MessageInputContainer>
                  <MessageInput
                    value={messageInput}
                    onChange={changeMessageInput}
                    onKeyPress={event => {
                      if (event.key === 'Enter' && !event.shiftKey)
                        sendMessage();
                    }}
                  />
                  <img
                    src={SendIcon}
                    alt="Send"
                    tabIndex={0}
                    onClick={sendMessage}
                    onKeyPress={event => {
                      if (event.key === 'Enter') event.currentTarget.click();
                    }}
                  />
                </MessageInputContainer>
              )}
            </ChatContainer>
          )}
        </Content>
      </StyledMessages>
    </Modal>
  );
};

export default (withRouter as any)(observer(Messages));
