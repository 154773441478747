import React from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { Element } from 'react-scroll';

const GlobalStyles = createGlobalStyle`
  body, html {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
`;

const StyledBackdrop = styled(Element)`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  animation: backdrop-appear 0.3s ease;

  @keyframes backdrop-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

interface BackdropProps {
  children?: React.ReactNode;
  [x: string]: any;
}

const Backdrop = ({ children, ...rest }: BackdropProps) => (
  <>
    <GlobalStyles />
    <StyledBackdrop name="backdrop" {...rest}>
      {children}
    </StyledBackdrop>
  </>
);

export default Backdrop;
