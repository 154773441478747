import React from 'react';

export default (props: any) => (
  <svg
    width="67"
    height="67"
    viewBox="0 0 67 67"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.43667 31.2245L31.5717 31.4855L31.8328 3.35042L36.4213 3.39299L36.1603 31.5281L64.2954 31.7891L64.2528 36.3776L36.1177 36.1166L35.8567 64.2517L31.2682 64.2091L31.5292 36.074L3.3941 35.813L3.43667 31.2245Z" />
  </svg>
);
