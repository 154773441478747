import { observable, action, computed } from 'mobx';
import themeStore from '../ThemeStore';
import { preloadImages } from '../../utils';
import ImageInterface from '../interfaces/ImageInterface';
import MaterialSelectionMode from '../enums/MaterialSelectionMode';
import materialSelectorStore from '../MaterialSelectorStore';
import appStore from '../AppStore';

interface ThemeData {
  id: number | null;
  packageSetId: number;
  name: string;
  displayName: string;
  cost: string;
  description: string;
  images: ImageInterface[];
  selected: boolean;
  canSelect: boolean | null;
  roomIds: number[];
  hasReviewedOptions?: boolean;
  preRequisites?: any[];
}

export default class Theme {
  @observable public id: number | null;
  @observable public packageSetId: number;
  @observable public name: string;
  @observable public cost: string = '';
  @observable public description: string;
  @observable public images: ImageInterface[];
  @observable private canSelect: null | boolean = null;
  @observable public roomIds: number[] = [];
  @observable public hasReviewedOptions?: boolean;
  @observable public preRequisites?: any[] = [];

  @observable public selected = false;

  constructor(data: ThemeData) {
    this.id = data.id;
    this.packageSetId = data.packageSetId;
    this.name = data.displayName;
    this.cost = data.cost;
    this.description = data.description;
    this.images = data.images.sort((a, b) => {
      return a.sortNo - b.sortNo;
    });
    this.selected = data.selected;
    this.canSelect = data.canSelect !== null ? data.canSelect : null;
    this.roomIds = data.roomIds;
    this.hasReviewedOptions = data.hasReviewedOptions;
    this.preRequisites = data.preRequisites;

    preloadImages([...this.imageUrls, ...this.thumbImageUrls]);
  }

  @action public select = () => {
    this.selected = true;
    this.hasReviewedOptions = false;
  };

  @action public deselect = () => {
    this.hasReviewedOptions = false;
    this.selected = false;
    if (!themeStore.selectedTheme) {
      if (materialSelectorStore.materialsOpen)
        materialSelectorStore.toggleMaterialsOpen(false);
      themeStore.toggleThemesOpen(true, false);
    }
  };

  @computed
  get canSelectComputed(): boolean {
    if (
      !this.preRequisites?.length ||
      appStore.tenantSettings.customerJourney2_showRooms
    ) {
      return !!this.canSelect;
    }

    return !!this.preRequisites?.some(pre => {
      if (
        themeStore.allSelectedRegularThemeIdsFromSelectedSets.includes(pre.id)
      ) {
        return true;
      }
      return false;
    });
  }

  @computed
  get masterImage(): ImageInterface | undefined {
    return !!this.images.length ? this.images[0] : undefined;
  }

  @computed
  get imageUrls(): string[] {
    return this.images.map(i => i.url);
  }

  @computed
  get thumbImageUrls(): string[] {
    return this.images.map(i => i.thumbnailUrl);
  }

  @computed
  get thumbImages(): ImageInterface[] | undefined {
    return this.images.length > 2
      ? this.images.slice(2, this.images.length)
      : undefined;
  }

  @computed
  get looseSelection(): boolean {
    const msm = themeStore.themeSets.find(
      ts => !!ts.themes.find(t => t.id === this.id)
    )!.materialSelectionMode;
    return (
      msm === MaterialSelectionMode.FREE || msm === MaterialSelectionMode.LOOSE
    );
  }

  @computed
  get isSubTheme(): boolean {
    return !!themeStore.subThemes.find(sT => sT.id === this.id);
  }

  @computed
  get isNoSelectionTheme(): boolean {
    return this.id === null;
  }

  public onSelect = (firstSelection = false) => {
    if (this.isSubTheme && this.id) {
      return themeStore.onSelectSubTheme(this.id);
    } else if (this.isNoSelectionTheme) {
      return themeStore.deselectSubThemesFromSubThemeSet(this.packageSetId);
    } else if (!this.isSubTheme && this.id) {
      return themeStore.onSelectTheme(this.id, firstSelection, true);
    }
    return undefined;
  };
}
