import { observable, action, computed } from 'mobx';
import materialSelectorStore from '../MaterialSelectorStore';
import BundleState from '../enums/BundleState';
import messagesStore from '../MessagesStore';
import { isLocked, isOpen, intlCurrencyFormat } from '../../utils';
import appStore from '../AppStore';

interface RoomInterface {
  demourl: null | string;
  discussionEnd: number;
  id: number;
  ifcfilename: null | string;
  ifcguid: string | null;
  lastMessageFromUser: number;
  messages: number;
  name: string;
  comment: string | null;
  apartmentId?: number;
  promoImage?: null | string;
  cost?: number;
}

interface ApiRoom {
  apartment_id: number;
  promo_image: null | string;
}

export type RoomData = RoomInterface & ApiRoom;

export default class Room implements RoomInterface {
  @observable public name: string;
  @observable public id: number;
  @observable public demourl: string | null;
  @observable public ifcfilename: string | null;
  @observable public ifcguid: string | null;
  @observable public lastMessageFromUser: number;
  @observable public messages: number;
  @observable public apartmentId: number;
  @observable public promoImage: null | string;
  @observable public discussionEnd: number;
  @observable public comment: string | null;
  @observable public cost?: number;

  @observable public selected = false;

  constructor(data: RoomData) {
    this.id = data.id;
    this.name = data.name;
    this.demourl = data.demourl;
    this.discussionEnd = data.discussionEnd;
    this.ifcfilename = data.ifcfilename;
    this.ifcguid = data.ifcguid;
    this.lastMessageFromUser = data.lastMessageFromUser;
    this.messages = data.messages;
    this.name = data.name;
    this.apartmentId = data.apartmentId || data.apartment_id;
    this.promoImage = data.promoImage || data.promo_image;
    this.comment = data.comment;
    this.cost = data.cost;
  }

  @action
  public toggleSelected = (selected?: boolean) => {
    this.selected = selected !== undefined ? selected : !this.selected;
  };

  @computed
  get bundles() {
    return materialSelectorStore.bundles.get(this.id) || [];
  }

  @computed
  get lockedBundles() {
    return this.bundles.filter(isLocked);
  }

  @computed
  get deadlineGoneBundles() {
    return this.bundles.filter(b => b.state === BundleState.DEADLINE_GONE);
  }

  @computed
  get openBundles() {
    return this.bundles.filter(isOpen);
  }

  @computed
  get roomDeadlineNear() {
    return (
      !!this.bundles.length &&
      this.bundles.find(b => b.deadlineNear && !b.deadlineGone && isOpen(b))
    );
  }

  @computed
  get roomDeadlines() {
    return this.bundles.reduce((pre, cur) => {
      if (cur.deadline) pre.push(cur.deadline);
      return pre;
    }, [] as string[]);
  }

  @computed
  get bundlesWithNoDl() {
    return this.bundles.filter(b => !b.deadline);
  }

  @computed
  get openBundlesWithNoDl() {
    return this.openBundles.filter(b => !b.deadline);
  }

  @computed
  get openBundlesWithNoDlExcludingOffers() {
    return this.openBundlesWithNoDl.filter(b => !b.isPartOfOffer);
  }

  @computed
  get openBundlesWithDl() {
    return this.openBundles.filter(b => b.deadline);
  }

  @computed
  get openAndLockableBundles() {
    return this.openBundles.filter(b => !b.deadline || !b.deadlineGone);
  }

  @computed
  get lockedBundlesWithNoDl() {
    return this.lockedBundles.filter(b => !b.deadline);
  }

  @computed
  get unreadMessages() {
    const messages = (messagesStore.messages.get(this.id) || [])
      .slice()
      .reverse();
    const index = messages.findIndex(m => m.isFromUser === 1);

    return index !== -1 ? index : 0;
  }

  @computed
  get intlCostFormat() {
    return this.cost !== undefined
      ? intlCurrencyFormat(
          this.cost,
          appStore.currencyCode,
          appStore.environmentLocale
        )
      : undefined;
  }
}
