import styled from 'styled-components/macro';
import { mediaLargeMax, mediaSmallMax } from '../Styled/media';
import { addSizes } from '../../utils';

export const footerLinksContainerHeight = '4.5rem';
export const footerLinksContainerHeightMobile = '3.25rem';

export const SubFooterHeight = '1.5rem';
export const SubFooterHeightMobile = '1.5rem';

export const footerHeight = addSizes(
  footerLinksContainerHeight,
  SubFooterHeight,
  'rem'
);
export const footerHeightMobile = addSizes(
  footerLinksContainerHeightMobile,
  SubFooterHeightMobile,
  'rem'
);

export const footerContentMaxWidth = '1440px';

export const FooterLinksContainer = styled.div`
  margin: 0 auto;
  max-width: ${footerContentMaxWidth};
  min-height: ${footerLinksContainerHeight};
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  @media ${mediaSmallMax} {
    min-height: ${footerLinksContainerHeightMobile};
  }
`;

export const FooterLinkText = styled.p`
  font-size: 18px;
  color: var(--custom-colorAccent2);
  font-family: var(--custom-fontNameLight);

  @media ${mediaLargeMax} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const FooterBtn = styled.button`
  text-decoration: none;
  background-color: var(--color-lightGray);
  transition: background-color 0.2s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    background-color: var(--custom-colorHover);
  }

  outline: none;

  @media ${mediaSmallMax} {
    ${FooterLinkText} {
      display: none;
    }

    justify-content: center;
  }
`;

export const FooterBtnImgContainer = styled.div`
  margin: 0.5rem 0;
  max-height: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  position: relative;

  img {
    max-height: 32px;

    @media ${mediaSmallMax} {
      max-height: 24px;
    }
  }
`;

export const SubFooterContainer = styled.div`
  background-color: var(--color-darkGray);
  color: white;
  min-height: ${SubFooterHeight};
`;

export const SubFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: var(--color-darkGray);
  color: white;
  margin: 0 auto;
  max-width: ${footerContentMaxWidth};
  min-height: ${SubFooterHeight};
  font-size: 14px;

  @media ${mediaSmallMax} {
    justify-content: space-around;
  }
`;

export const FooterPrivacyLink = styled.button`
  color: white;
  font-family: var(--custom-fontNameLight);
  font-size: 14px;
  text-decoration-line: underline;
  margin-right: 2.6rem;

  @media ${mediaSmallMax} {
    margin-right: 0;
  }
`;

export const StyledFooter = styled.footer`
  background-color: var(--color-lightGray);
  min-height: ${footerHeight};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  transition: transform 0.5s ease-in-out;

  &.hidden {
    transform: translateY(100%);
  }

  &.lift-up {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 20%
    );
    &:not(.hidden) {
      transform: translateY(-100%);
    }
  }

  @media ${mediaSmallMax} {
    min-height: ${footerHeightMobile};
    max-width: 100vw;
  }
`;

export const FooterBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
