import { observable, action, computed } from 'mobx';
import { d } from '../../utils';

export interface MaterialData {
  description: null | string;
  isDefault: boolean;
  isDefaultInProject: boolean;
  name: string;
  projectMaterialId: number | null;
  totalCost: string | null | undefined;
  imageUrlSmall: string;
  imageUrlLarge: string;
  url: string | null;
  isInPackages: number[];
  isDefaultInPackages: number[];
  manufacturer: string;
}

export default class Material implements MaterialData {
  @observable public description: null | string;
  @observable public isDefault: boolean;
  @observable public isDefaultInProject: boolean;
  @observable public name: string;
  @observable public projectMaterialId: number | null;
  @observable public totalCost: string | null | undefined;
  @observable public imageUrlSmall: string;
  @observable public imageUrlLarge: string;
  @observable public url: string | null;
  @observable public isInPackages: number[];
  @observable public isDefaultInPackages: number[];
  @observable public manufacturer: string;

  @observable public targeted = false;

  constructor(data: MaterialData) {
    this.description = data.description;
    this.isDefault = data.isDefault;
    this.isDefaultInProject = data.isDefaultInProject;
    this.name = data.name;
    this.projectMaterialId = data.projectMaterialId;
    this.totalCost = data.totalCost;
    this.imageUrlSmall = data.imageUrlSmall;
    this.imageUrlLarge = data.imageUrlLarge;
    this.url = data.url;
    this.isInPackages = data.isInPackages;
    this.isDefaultInPackages = data.isDefaultInPackages;
    this.manufacturer = data.manufacturer;
  }

  @action public toggleTargeted = (targeted?: boolean) => {
    this.targeted = targeted !== undefined ? targeted : !this.targeted;
  };

  @computed
  get price() {
    return this.totalCost ? d(this.totalCost) : null;
  }
}
