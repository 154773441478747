import React from 'react';
import { observable, action } from 'mobx';
import { text } from '../utils';
import ExclamationIcon from '../components/Icons/Exclamation';

class AlertStore {
  @observable public isOpen = false;
  @observable public title: string = text('errors.commonTitle');
  @observable public icon: JSX.Element = <ExclamationIcon fill="white" />;
  @observable public message: string = text('errors.commonMessage');
  @observable public color: string = 'var(--custom-colorNotif1)';

  @action public show = (
    title?: string,
    message?: string,
    duration = 10000,
    icon?: JSX.Element,
    color?: string
  ) => {
    this.isOpen = true;
    this.title = title || text('errors.commonTitle');
    this.message =
      message !== undefined ? message : text('errors.commonMessage');
    this.icon = icon !== undefined ? icon : <ExclamationIcon fill="white" />;
    this.color = color !== undefined ? color : 'var(--custom-colorNotif1)';

    setTimeout(() => {
      this.hide();
    }, duration);
  };

  @action public hide = () => {
    this.isOpen = false;
    this.title = text('errors.commonTitle');
    this.message = text('errors.commonMessage');
    this.icon = <ExclamationIcon fill="white" />;
    this.color = 'var(--custom-colorNotif1)';
  };
}

const alertStore = new AlertStore();

export default alertStore;
