import React, { useEffect, useState } from 'react';

import { VersionList, VersionListItem, MISSING_CAPTION } from './VersionList';
import moment from 'moment';
import { fetchVersionInfo, baseURL } from '../../../axios';
import alertStore from '../../../store/AlertStore';
import appStore from '../../../store/AppStore';
import authStore from '../../../store/AuthStore';
import { StartUrlParameters } from '../../../store/enums/UrlParameters';

interface VersionInfo {
  build_time: string;
  commit_hash: string | null;
  commit_time: string | null;
  branch: string | null;
  version: string;
  mode: string;
}

const formatTime = (time: string | null) => {
  if (!time) {
    return MISSING_CAPTION;
  }

  return `${moment(time).format('YYYY-MM-DD HH:mm')} (${moment(time).from(
    moment()
  )})`;
};

const formatCommit = (
  commitHash: string | null,
  commitTime: string | null,
  repositoryUrl: string
) => {
  if (!commitHash) {
    return MISSING_CAPTION;
  }

  return (
    <>
      <a href={`${repositoryUrl}/commit/${commitHash}`}>{commitHash}</a> on{' '}
      {formatTime(commitTime)}
    </>
  );
};

const VersionsView = () => {
  const [versionInfo, setVersionInfo] = useState<VersionInfo | undefined>();

  useEffect(() => {
    fetchVersionInfo()
      .then(data => setVersionInfo(data))
      .catch(e => alertStore.show('Failed to fetch version info'));
  }, []); // eslint-disable-line

  if (!versionInfo) {
    return <p>Fetching version info...</p>;
  }

  return (
    <div
      style={{
        margin: '10px'
      }}
    >
      <h4>Environment information</h4>
      <VersionList title="">
        <VersionListItem
          caption="Organization"
          text={appStore.organisationId}
        />
        <VersionListItem caption="Tenant" text={appStore.tenantId} />
        <VersionListItem caption="Project ID" text={appStore.projectId} />
      </VersionList>
      <h4>Version information</h4>
      <VersionList title="CustomerJourney">
        <VersionListItem
          caption="Version"
          text={`${UI_VERSION} (${NODE_ENV})`}
        />
        <VersionListItem
          caption="Commit"
          text={formatCommit(
            GIT_COMMIT_HASH,
            GIT_COMMIT_TIME,
            'https://github.com/GroupBuilderOy/GB-CustomerJourney'
          )}
        />
        <VersionListItem
          caption="Build time"
          text={formatTime(UI_BUILD_DATE)}
        />
        <VersionListItem caption="Branch" text={GIT_BRANCH} />
      </VersionList>
      <VersionList title="GB-API">
        <VersionListItem caption="API URL" text={baseURL} />
        <VersionListItem
          caption="Version"
          text={`${versionInfo.version} (${versionInfo.mode})`}
        />
        <VersionListItem
          caption="Commit"
          text={formatCommit(
            versionInfo.commit_hash,
            versionInfo.commit_time,
            'https://github.com/GroupBuilderOy/GB-API'
          )}
        />
        <VersionListItem
          caption="Build time"
          text={formatTime(versionInfo.build_time)}
        />
        <VersionListItem caption="Branch" text={versionInfo.branch} />
      </VersionList>
      <button
        onClick={() => {
          const link = `${window.location.origin}?
          ${StartUrlParameters.projectId}=${appStore.projectId}&
          ${StartUrlParameters.tenantId}=${appStore.tenantId}&
          ${StartUrlParameters.organisationId}=${appStore.organisationId}&
          ${StartUrlParameters.gbToken}=${authStore.token}&
          ${StartUrlParameters.apartmentId}=${appStore.apartmentId}`;
          try {
            navigator.clipboard.writeText(link);
          } catch (e) {
            alertStore.show(`Failed to copy: ${e}`);
          }
        }}
      >
        Copy direct link with your login access
      </button>
    </div>
  );
};

export default VersionsView;
