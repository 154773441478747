import React from 'react';
import { observer } from 'mobx-react-lite';
import OfferIcon from '../Icons/offer.svg';
import {
  FooterBtn,
  FooterBtnImgContainer,
  FooterLinkText,
} from './FooterStyledComponents';
import { OffersOrDeadlines } from './Footer';
import materialSelectorStore from '../../store/MaterialSelectorStore';
import routingStore from '../../store/RoutingStore';
import { text } from '../../utils';

const ConfirmationAndOffersFooterBtn = (props: { separated: boolean }) => {
  const { pendingBundleOffers, upComingDeadlines } = materialSelectorStore;
  const { separated } = props;

  if (separated) {
    return (
      <>
        <FooterBtn onClick={() => routingStore.push('/order-confirmation')}>
          <FooterBtnImgContainer>
            {!!pendingBundleOffers.length && (
              <OffersOrDeadlines>
                {pendingBundleOffers.length}
              </OffersOrDeadlines>
            )}
            <img src={OfferIcon} alt="offer-icon" />
          </FooterBtnImgContainer>
          <FooterLinkText>{text('offers')}</FooterLinkText>
        </FooterBtn>

        <FooterBtn onClick={() => routingStore.push('/order-confirmation')}>
          <FooterBtnImgContainer>
            {!!upComingDeadlines.length && (
              <OffersOrDeadlines>{upComingDeadlines.length}</OffersOrDeadlines>
            )}
            <img src={OfferIcon} alt="confirmation-icon" />
          </FooterBtnImgContainer>
          <FooterLinkText>{text('confirmations')}</FooterLinkText>
        </FooterBtn>
      </>
    );
  } else {
    return (
      <FooterBtn onClick={() => routingStore.push('/order-confirmation')}>
        <FooterBtnImgContainer>
          {(!!pendingBundleOffers.length || !!upComingDeadlines.length) && (
            <OffersOrDeadlines>
              {pendingBundleOffers.length + upComingDeadlines.length}
            </OffersOrDeadlines>
          )}
          <img src={OfferIcon} alt="offer-icon" />
        </FooterBtnImgContainer>
        <FooterLinkText>{text('confirmationsAndOffers')}</FooterLinkText>
      </FooterBtn>
    );
  }
};

export default observer(ConfirmationAndOffersFooterBtn);
