import React from 'react';
import styled from 'styled-components/macro';
import { Contact } from '../../store/interfaces/Contact';
import { mediaSmallMax } from '../Styled/media';

const StyledContacts = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 15rem);
  grid-auto-flow: row;
  justify-content: space-between;
  max-width: 100%;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  padding: 2.25rem;

  @media ${mediaSmallMax} {
    padding: 1rem;
    grid-template-columns: repeat(auto-fill, calc(100vw - 2rem));
  }
`;

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${mediaSmallMax} {
    flex-direction: row;
  }
`;

const ContactImageContainer = styled.div`
  min-height: 10rem;
  min-width: 10rem;
  max-height: 10rem;
  max-width: 10rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 1rem;

  @media ${mediaSmallMax} {
    min-height: 6.75rem;
    min-width: 6.75rem;
    max-height: 6.75rem;
    max-width: 6.75rem;
  }
`;

const ContactImage = styled.img`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
`;

const ContactName = styled.h3`
  font-size: 25px;
  font-weight: 700;
  font-family: var(--custom-fontNameBold);
  margin-top: 0.5rem;
  line-height: 2;

  @media ${mediaSmallMax} {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 8px;
  }
`;

const ContactTitle = styled.p`
  line-height: 2rem;
  font-weight: 400;
  font-family: var(--custom-fontName);

  @media ${mediaSmallMax} {
    line-height: 17px;
  }
`;

const ContactEmail = styled.a`
  border-bottom: none;
  font-weight: 300;
  font-family: var(--custom-fontNameLight);
  line-height: 2rem;

  @media ${mediaSmallMax} {
    line-height: 17px;
  }
`;

const ContactPhone = styled.a`
  border-bottom: none;
  font-family: var(--custom-fontNameLight);
  font-weight: 300;
  line-height: 2rem;

  @media ${mediaSmallMax} {
    line-height: 17px;
  }
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface ContactsProps {
  contacts: Contact[];
}

const Contacts = ({ contacts }: ContactsProps) => (
  <StyledContacts>
    {contacts.map((contact, i) => (
      <ContactCard key={`${contact.name}-${i}`}>
        <ContactImageContainer>
          {contact.imageUrlLarge && (
            <ContactImage
              src={contact.imageUrlLarge}
              alt={`${contact.name}-${i}`}
            />
          )}
        </ContactImageContainer>
        <ContactInfoContainer>
          <ContactName>{contact.name}</ContactName>
          <ContactTitle>{contact.jobTitle}</ContactTitle>
          <ContactEmail href={`mailto:${contact.email}`}>
            {contact.email}
          </ContactEmail>
          <ContactPhone href={`tel:${contact.phoneNumber}`}>
            {contact.phoneNumber}
          </ContactPhone>
        </ContactInfoContainer>
      </ContactCard>
    ))}
  </StyledContacts>
);

export default Contacts;
