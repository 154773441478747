import React from 'react';
import styled from 'styled-components/macro';
import themeStore from '../../store/ThemeStore';
import { text } from '../../utils';
import Progress from '../Progress';
import {
  mediaSmallMin,
  mediaMediumMin,
  mediaLargeMin,
  mediaSmallMax,
} from '../Styled/media';

const ThemeSelectorDescription = styled.div`
  background-color: var(--color-lightGray);
  padding: 2.6rem;

  @media ${mediaSmallMin} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2.5rem;
  }

  @media ${mediaMediumMin} {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2.5rem;
  }

  @media ${mediaLargeMin} {
    grid-column-gap: 5rem;
  }

  display: grid;
  justify-content: space-around;
  grid-row-gap: 1.25rem;
  margin-top: 2.4rem;
  grid-template-columns: 1fr;

  @media ${mediaSmallMax} {
    padding: 1rem;
    margin-top: 0;
  }

  @media ${mediaSmallMax} {
    h2 {
      font-size: 24px;
      line-height: 26px;

      font-family: var(--custom-fontNameLight);
    }
  }
`;

const Description = styled.p`
  grid-column: span 2;
  line-height: 16px;
`;

export default () => {
  const title = text('firstDescriptionTitle');
  const desc = text('firstDescriptionText');

  const doNotRender =
    (title === '-' && desc === '-') || !themeStore.themesFetched;
  return !themeStore.themesFetched ? (
    <ThemeSelectorDescription>
      <Progress />
    </ThemeSelectorDescription>
  ) : !doNotRender ? (
    <ThemeSelectorDescription>
      <h2 style={{ whiteSpace: !desc ? 'nowrap' : 'normal' }}>{title}</h2>
      <Description>{desc}</Description>
    </ThemeSelectorDescription>
  ) : null;
};
