import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import RoomsList from './RoomsList';
import materialSelectorStore from '../../store/MaterialSelectorStore';
import appStore from '../../store/AppStore';
import { text } from '../../utils';
import Button from '../Button/Button';
import MaterialsGrid from './MaterialsGrid';
import routingStore from '../../store/RoutingStore';
import MaterialSelector from './MaterialSelector/MaterialSelector';
import { withRouter } from 'react-router';
import { mediaSmallMax } from '../Styled/media';
import roomsStore from '../../store/RoomsStore';
import RoomSelectorMobile from './RoomSelectorMobile';
import { intlCurrencyFormat } from '../../utils';
import Progress from '../Progress';

const MaterialSectionAside = styled.aside`
  display: flex;
  flex-direction: column;

  & > *:last-child {
    margin-bottom: 5rem;

    @media ${mediaSmallMax} {
      margin: 0;
    }
  }

  @media ${mediaSmallMax} {
    .visible-on-desktop {
      display: none;
    }

    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
`;

const StyledMaterialSection = styled.div<{ roomsColumn: boolean }>`
  display: grid;
  grid-template-columns: ${({ roomsColumn }) =>
    roomsColumn ? '15rem 1fr' : '1fr'};
  padding: 2rem 2.6rem 0;

  @media ${mediaSmallMax} {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;

const Cost = styled.h3`
  margin-top: 1.3rem;
  margin-bottom: 2.4rem;

  @media ${mediaSmallMax} {
    margin: 0;
    font-family: var(--custom-fontNameLight);
  }
`;

const StyledButton = styled(Button)`
  margin: 1rem 1.5rem 1rem 0;

  @media ${mediaSmallMax} {
    margin: 0;
  }
`;

const MaterialSection = () => {
  return (
    <StyledMaterialSection
      roomsColumn={!!appStore.tenantSettings.customerJourney2_showRooms}
    >
      {appStore.tenantSettings.customerJourney2_showRooms && (
        <>
          <MaterialSectionAside>
            <RoomsList
              className="visible-on-desktop"
              rooms={roomsStore.rooms}
              onSelect={materialSelectorStore.selectRoom}
              generalDeadlineNear={materialSelectorStore.apartmentDeadlineNear}
            />
            {appStore.apartment ? (
              <Cost>{`${text('total')}: ${intlCurrencyFormat(
                appStore.apartment.cost!,
                appStore.currencyCode,
                appStore.environmentLocale
              )}`}</Cost>
            ) : null}
            <StyledButton
              tabIndex={0}
              onClick={() => routingStore.push('/order-confirmation')}
            >
              {text('readyToOrder')}
            </StyledButton>
          </MaterialSectionAside>
          <RoomSelectorMobile
            rooms={roomsStore.rooms}
            onSelect={materialSelectorStore.selectRoom}
            generalDeadlineNear={materialSelectorStore.apartmentDeadlineNear}
            selectedRoomName={
              roomsStore.selectedRoom
                ? roomsStore.selectedRoom.name
                : text('generalAppearance')
            }
          />
        </>
      )}
      {!materialSelectorStore.showingSpinner ? (
        <MaterialsGrid
          bundles={materialSelectorStore.defaultBundles}
          accessoryBundles={materialSelectorStore.accessoryBundles}
          showGeneralMaterials={roomsStore.selectedRoom !== undefined}
          showRooms={appStore.tenantSettings.customerJourney2_showRooms}
        />
      ) : (
        <Progress />
      )}
      <MaterialSelector />
    </StyledMaterialSection>
  );
};

export default (withRouter as any)(observer(MaterialSection));
