import styled from 'styled-components/macro';
import Button from '../Button/Button';
import { mediaSmallMax } from '../Styled/media';

export const OrderConfirmationButton = styled(Button)`
  margin: 2rem 0;
  align-self: center;
  background-color: white @media ${mediaSmallMax} {
    margin: 1rem;
    align-self: stretch;

    border: 1px solid;
  }
`;
