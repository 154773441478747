import { action } from 'mobx';
import { fetchUserRights, getUserLevel } from '../axios';
import appStore from './AppStore';
import shiroTrie from 'shiro-trie';

class UserRightsStore {
  public shiroTrie: shiroTrie.ShiroTrie = shiroTrie.newTrie();
  public userRights: string[] = [];
  public userRightsFetched: boolean = false;
  public userLevel: number = -1;

  public fetchUserRights = async () => {
    if (!this.userRightsFetched) {
      this.shiroTrie.reset();

      const { data } = await fetchUserRights(
        appStore.organisationId,
        appStore.tenantId,
        appStore.projectId
      );

      this.shiroTrie.add(data.userRights);

      this.userRightsFetched = true;
    }
  };

  public getUserLevel = async () => {
    const { data } = await getUserLevel(
      appStore.organisationId,
      appStore.tenantId,
      appStore.projectId
    );
    this.userLevel = data.userLevel;
  };

  @action
  public check = (permission: string) => {
    return this.shiroTrie.check(permission);
  };
}

const userRightsStore = new UserRightsStore();

export default userRightsStore;
