import styled from 'styled-components/macro';
import React, { useEffect, useState } from 'react';
import CalendarIcon from '../Icons/calendar.svg';
import ContactIcon from '../Icons/contact.svg';
import MessagesIcon from '../Icons/messages.svg';
import HelpIcon from '../Icons/info.svg';
import FolderIcon from '../Icons/folder.svg';
import { text } from '../../utils';
import { changeParams } from '../../store/RoutingStore';
import messagesStore from '../../store/MessagesStore';
import CircleBadge from '../CircleBadge/CircleBadge';
import { observer } from 'mobx-react-lite';
import {
  StyledFooter,
  FooterLinksContainer,
  FooterBtn,
  FooterBtnImgContainer,
  FooterLinkText,
  SubFooterContainer,
  SubFooter,
  FooterPrivacyLink,
} from './FooterStyledComponents';
import classnames from 'classnames';
import appStore from '../../store/AppStore';
import schedules from '../../routes/Schedule/schedules';
import debounce from 'lodash.debounce';
import modalStore from '../../store/ModalStore';
import calendarStore from '../../store/CalendarStore';
import ConfirmationAndOffersFooterBtn from './ConfirmationAndOffersFooterBtn';
import attachmentsStore from '../../store/AttachmentsStore';

const UnreadMessages = styled(CircleBadge)`
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--custom-colorNotif3);
  transform: translateX(50%);
  font-family: var(--custom-fontNameLight);
`;

export const OffersOrDeadlines = styled(CircleBadge)`
  position: absolute;
  top: -6px;
  right: 5px;
  background-color: var(--custom-colorNotif1);
  transform: translateX(50%);
  font-family: var(--custom-fontNameLight);
`;

const Footer = () => {
  const [footerHidden, setFooterHidden] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const checkDirection = debounce((ev: Event) => {
      const st = window.pageYOffset || document.documentElement.scrollTop;

      const scrollingDown = st > lastScrollTop;

      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;

      const siteHeightTooLow =
        window.innerHeight * 1.7 > document.documentElement.scrollHeight;

      setFooterHidden(!scrolledToBottom && scrollingDown && !siteHeightTooLow);

      setLastScrollTop(st <= 0 ? 0 : st);
    }, 50);

    window.addEventListener('scroll', checkDirection);
    return () => window.removeEventListener('scroll', checkDirection);
  });

  useEffect(() => {
    setFooterHidden(modalStore.isOpen);
  }, [modalStore.isOpen]); // eslint-disable-line

  useEffect(() => {
    if (!messagesStore.messagesFetched) messagesStore.fetchAllMessages();
    if (!attachmentsStore.signableAttachmentsFetched)
      attachmentsStore.fetchSignableAttachments();
  }, []);

  return (
    <StyledFooter className={classnames({ hidden: footerHidden })}>
      <FooterLinksContainer>
        <ConfirmationAndOffersFooterBtn separated={false} />
        {(!!schedules[appStore.projectIdentifier] ||
          !!calendarStore.calendarEvents.length) && (
          <FooterBtn onClick={() => changeParams({ schedule: 'true' })}>
            <FooterBtnImgContainer>
              <img src={CalendarIcon} alt="schedule-icon" />
            </FooterBtnImgContainer>
            <FooterLinkText>{text('orderSchedule')}</FooterLinkText>
          </FooterBtn>
        )}
        <FooterBtn
          onClick={() => {
            messagesStore.selectRoom();
            changeParams({ messages: 'true' });
          }}
        >
          <FooterBtnImgContainer>
            {messagesStore.unreadMessages !== 0 && (
              <UnreadMessages>{messagesStore.unreadMessages}</UnreadMessages>
            )}
            <img src={MessagesIcon} alt="messages-icon" />
          </FooterBtnImgContainer>
          <FooterLinkText>{text('messages')}</FooterLinkText>
        </FooterBtn>
        <FooterBtn onClick={() => changeParams({ contact: 'true' })}>
          <FooterBtnImgContainer>
            <img src={ContactIcon} alt="contact-icon" />
          </FooterBtnImgContainer>
          <FooterLinkText>{text('contactInformation')}</FooterLinkText>
        </FooterBtn>
        {appStore.tenantSettings.customerJourney2_instructionsUrl && (
          <FooterBtn onClick={() => changeParams({ help: 'true' })}>
            <FooterBtnImgContainer>
              <img src={HelpIcon} alt="help-icon" />
            </FooterBtnImgContainer>
            <FooterLinkText>{text('help')}</FooterLinkText>
          </FooterBtn>
        )}
        <FooterBtn onClick={() => changeParams({ documents: 'true' })}>
          <FooterBtnImgContainer>
            {attachmentsStore.getUnsignedDocumentsCount() > 0 && (
              <OffersOrDeadlines data-testid="notication-badge">
                {attachmentsStore.getUnsignedDocumentsCount()}
              </OffersOrDeadlines>
            )}
            <img src={FolderIcon} alt="folder-icon" />
          </FooterBtnImgContainer>
          <FooterLinkText>{text('brochuresAndAttachments')}</FooterLinkText>
        </FooterBtn>
      </FooterLinksContainer>
      <SubFooterContainer>
        <SubFooter>
          <FooterPrivacyLink
            onClick={() => changeParams({ termsOfUse: 'true' })}
          >
            {text('termsOfUse')}
          </FooterPrivacyLink>
          <FooterPrivacyLink
            onClick={() => changeParams({ privacyPolicy: 'true' })}
          >
            {text('privacyPolicy')}
          </FooterPrivacyLink>
          <FooterPrivacyLink
            onClick={() => window.open('https://gbuilder.com/', '_blank')}
          >
            {text('copyright')}
          </FooterPrivacyLink>
        </SubFooter>
      </SubFooterContainer>
    </StyledFooter>
  );
};

export default observer(Footer);
