import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Materials from '../../components/Materials/Materials';
import Themes from '../../components/Themes/Themes';
import FrontpageDescription from '../../components/FrontpageDescription/FrontpageDescription';
import routingStore from '../../store/RoutingStore';
import Header from '../../components/Header/Header';
import { text, scrollTo } from '../../utils';
import PageHeading from '../../components/PageHeading/PageHeading';
import appStore from '../../store/AppStore';
import { Switch, Route } from 'react-router';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button/Button';
import lightBoxStore from '../../store/LightBoxStore';
import themeStore from '../../store/ThemeStore';
import ConfirmationFooter from '../../components/ConfirmationFooter/ConfirmationFooter';
import {
  mediaSmallMax,
  mediaMediumMax,
  mediaMediumMin,
} from '../../components/Styled/media';
import UpButton from '../../components/UpButton/UpButton';
import materialSelectorStore from '../../store/MaterialSelectorStore';
import classnames from 'classnames';
import CircleBadge from '../../components/CircleBadge/CircleBadge';
import { modalView, triggerGlobalEvent } from '../../services';
import { ModalView } from '../../services/ModalView';
import { EventCategory } from '../../services/EventCategory';
import { EventAction } from '../../services/EventAction';
import Progress from '../../components/Progress';

const Content = styled.div`
  max-width: 1440px;
  background-color: white;
  z-index: 100;
  margin: 0 auto;
  padding: 1.8rem 0;
  margin-bottom: 5rem;
  position: relative;

  @media ${mediaSmallMax} {
    padding: 0;
    margin-bottom: 3.25rem;
  }
`;

const UpButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 1rem;

  &.invisible {
    display: none;
  }
`;

const StyledUpButton = styled(UpButton)`
  outline: none;

  @media ${mediaMediumMin} {
    display: none;
    pointer-events: none;
  }
`;

const StyledButton = styled(Button)`
  padding: 0.75rem 1.5rem;
  align-self: center;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;

  @media ${mediaSmallMax} {
    padding: 0;
  }
`;

const ApartmentImage = styled.div`
  max-height: 4.5rem;
  max-width: 4.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  @media ${mediaMediumMax} {
    margin-right: 0.5rem;
    max-height: 2.5rem;
    max-width: 2.5rem;
    min-height: 2.5rem;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const StyledCircleBadge = styled(CircleBadge)<{ size?: number }>`
  position: absolute;
  z-index: 1;
  max-width: unset;
  width: unset;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 13px;

  right: 0;
  bottom: 0;
  background-color: var(--custom-colorNotif4);
`;

export default observer(() => {
  const {
    themesFetched,
    fetchThemeSets,
    themeSets,
    selectedTheme,
    startedEditingThemeSet,
  } = themeStore;
  const {
    apartment,
    apartmentImages,
    apartmentName,
    floorPlan,
    noThemesToSelect,
    tenantSettings,
  } = appStore;
  const { allBundlesFetched } = materialSelectorStore;

  useEffect(() => {
    if (!themesFetched && apartment) fetchThemeSets();
  }, [apartment]); // eslint-disable-line

  const [visualisationUrlFetched, setVisualisationUrlFetched] = useState(true);

  const imagesToShow = apartmentImages
    ? apartmentImages.filter(i => i.name !== 'pohjakuva')
    : [];
  const showApartmentImages = imagesToShow && imagesToShow[0];
  const imagesAmount =
    showApartmentImages && imagesToShow!.length > 1
      ? `+${imagesToShow!.length}`
      : '';

  return (
    <>
      <Header />
      <Content>
        <PageHeading
          className="page-title"
          title={text('yourHome')}
          text={apartmentName}
          resizeEffectListeners={[imagesToShow, floorPlan, apartment]}
          resizeable
          whiteBg={routingStore.location.pathname === '/'}
        >
          {showApartmentImages && (
            <ImageContainer>
              <ApartmentImage
                key={imagesToShow[0].imageDisplayFilename}
                onClick={() => {
                  modalView(ModalView.APARTMENT_IMAGES);
                  lightBoxStore.open(
                    imagesToShow.map(ai => ai.url),
                    0
                  );
                }}
              >
                <img
                  src={imagesToShow[0].thumbnailUrl}
                  alt={imagesToShow[0].name}
                />
                {imagesAmount && (
                  <StyledCircleBadge size={26}>
                    {imagesAmount}
                  </StyledCircleBadge>
                )}
              </ApartmentImage>
            </ImageContainer>
          )}
          {floorPlan && (
            <StyledButton
              onClick={() => {
                modalView(ModalView.FLOOR_PLAN);
                lightBoxStore.open([floorPlan as string]);
              }}
            >
              {text('floorPlan')}
            </StyledButton>
          )}
          {apartment && appStore.tenantSettings.gb4dIsActive !== false && (
            <a
              style={{ border: 'none', alignSelf: 'center', display: 'flex' }}
              href={apartment.threeDUrl}
              onClick={async e => {
                if (!apartment.threeDUrl) {
                  e.preventDefault();
                  setVisualisationUrlFetched(false);
                  await appStore.fetchThreeDUrl();
                  setVisualisationUrlFetched(true);
                  window.open(
                    apartment.threeDUrl,
                    '_blank',
                    'noopener,noreferrer'
                  );
                }
                triggerGlobalEvent({
                  category: EventCategory.USER,
                  action: EventAction.VIEW_IN_3D,
                });
              }}
              rel="noopener noreferrer"
              target="_blank"
            >
              <StyledButton>{text('viewIn3d')}</StyledButton>
              {!visualisationUrlFetched && <Progress />}
            </a>
          )}
        </PageHeading>
        {!noThemesToSelect && (
          // Dont show FrontPageDescription if there are no themes to select
          <Switch>
            <Route path="/" exact component={FrontpageDescription} />
          </Switch>
        )}
        {themesFetched && !!themeSets.length && (
          <Themes isFrontPage={routingStore.location.pathname === '/'} />
        )}
        {
          // Show materials if there are no themes to select, even in frontpage
          noThemesToSelect && routingStore.location.pathname === '/' && (
            <Materials openAtStart />
          )
        }
        <Switch>
          <Route path="/materials" component={Materials} />
        </Switch>
        <UpButtonContainer
          className={classnames({ invisible: !allBundlesFetched })}
        >
          <StyledUpButton onClick={() => scrollTo('root', true)} />
        </UpButtonContainer>
      </Content>
      {tenantSettings.customerJourney2_usePackageSets &&
        tenantSettings.customerJourney2_usePackageBundlesConfirm &&
        selectedTheme &&
        startedEditingThemeSet && (
          <ConfirmationFooter
            onButtonClick={themeStore.onCheckThemeSet}
            selectedTheme={selectedTheme}
            liftUp={tenantSettings.customerJourney2_showFooter}
          />
        )}
    </>
  );
});
