import React from 'react';
import styled from 'styled-components/macro';
import {
  mediaMediumMax,
  mediaSmallMax,
  mediaLargeMax,
  mediaXSmallMax
} from '../../Styled/media';
import { observer } from 'mobx-react-lite';

const StyledMaterialSelectorGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding-bottom: 1rem;
  min-width: 30rem;
  width: 100%;

  &:last-child {
    padding-bottom: 3rem;
  }

  @media ${mediaLargeMax} {
    min-width: 15rem;
  }

  @media ${mediaMediumMax} {
    min-width: 10rem;
  }

  @media ${mediaSmallMax} {
    min-width: 0;
  }
`;

const Title = styled.h3`
  border-bottom: 1px solid var(--custom-colorAccent3);
  font-size: 24px;
  font-weight: 300;
  font-family: var(--custom-fontNameLight);
  align-self: stretch;
  line-height: 3rem;

  @media ${mediaSmallMax} {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 8px;
    padding-top: 1rem;
  }
`;

const Grid = styled.div`
  display: grid;
  margin-top: 1rem;
  grid-template-columns: repeat(auto-fit, 11rem);
  grid-template-rows: repeat(auto-fill, 17rem);
  justify-content: flex-start;
  grid-auto-flow: row;
  grid-gap: 2rem;

  @media ${mediaMediumMax} {
    justify-content: center;
  }

  @media ${mediaSmallMax} {
    display: none;
  }
`;

const MobileGridContainer = styled.div`
  display: none;
  overflow: auto;

  @media ${mediaSmallMax} {
    display: block;
  }
`;

const MobileGrid = styled.div`
  margin-top: 1rem;
  display: none;
  justify-content: space-between;
  & > * {
    flex-shrink: 0;
    flex-grow: 0;
  }

  @media ${mediaSmallMax} {
    grid-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${mediaXSmallMax} {
    grid-template-columns: 1fr 1fr;
  }
`;

interface MaterialSelectorGridProps {
  title: string;
  children: JSX.Element | JSX.Element[];
}

const MaterialSelectorGrid = ({
  title,
  children
}: MaterialSelectorGridProps) => (
  <StyledMaterialSelectorGrid>
    <Title>{title}</Title>
    <Grid>{children}</Grid>
    <MobileGridContainer>
      <MobileGrid>{children}</MobileGrid>
    </MobileGridContainer>
  </StyledMaterialSelectorGrid>
);

export default observer(MaterialSelectorGrid);
