import React from 'react';
import styled from 'styled-components/macro';
import Bell from '../Icons/Bell';
import { text } from '../../utils';
import { changeParams } from '../../store/RoutingStore';
import { CalendarEventInterface } from '../../store/interfaces/CalendarEvent';
import { Offer } from '../../store/models/Offer';
import offersStore from '../../store/OffersStore';
import routingStore from '../../store/RoutingStore';
import calendarStore from '../../store/CalendarStore';
import appStore from '../../store/AppStore';

const EventBoxContainer = styled.div`
  width: 530px;
  max-width: 40%;
  height: 230px;
  position: absolute;
  top: 30%;
  right: 50px;
  background: white;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1000px) {
    flex-direction: column !important;
    width: 35% !important;
    height: 230px !important;
  }

  @media screen and (max-width: 855px) {
    display: none;
  }
`;

const EventBoxDeadLine = styled.p`
  font-size: 22px;
  color: #455f6a;

  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }
`;

const OfferDeadLine = styled.p`
  cursor: pointer;
  font-size: 22px;
  color: #455f6a;
  text-decoration: underline;

  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }
`;

const EventBoxSideLine = styled.div`
  height: 100%;
  width: 8%;
  background-color: #db4d69;
  text-align: center;
  padding-top: 15px;

  @media screen and (max-width: 1000px) {
    width: 100%;
    height: 20%;
  }
`;

const EventBoxDeadLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
  padding-bottom: 1%;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1000px) {
    height: 230px !important;
  }
`;

const EventBoxHeader = styled.p`
  font-weight: 100;
  font-size: 30px;
  height: 20% !important;
  color: #455f6a;

  @media screen and (max-width: 1000px) {
    font-size: 27px !important;
  }
`;

const ReadMore = styled.a`
  border: none;
  font-size: 17px;
  color: #294754;
  font-weight: bold;

  @media screen and (max-width: 1000px) {
    margin-left: 0;
    text-align: right;
  }
`;

const ReadMoreContainer = styled.p`
  text-align: right;
  &:hover {
    cursor: pointer;
    filter: brightness(2);
  }
`;

interface EventBoxProps {
  deadlines: {
    items: CalendarEventInterface[];
    showMax: number;
  };

  offers: {
    items: Offer[];
    showMax: number;
  };
}

const EventBox = (props: EventBoxProps) => {
  const redirectToOffer = (offerId: number) => {
    offersStore.selectedOffer = offerId;
    routingStore.push('/order-confirmation');
  };

  const deadlinesToShow = props.deadlines.items.slice(
    0,
    props.deadlines.showMax
  );
  const offersToShow = props.offers.items.slice(0, props.offers.showMax);

  return (
    <EventBoxContainer>
      <EventBoxSideLine>
        <Bell fill="white" />
      </EventBoxSideLine>
      <EventBoxDeadLineContainer>
        <EventBoxHeader>{text('eventBoxHeader')}</EventBoxHeader>
        {offersToShow.map(offer => {
          return (
            <OfferDeadLine
              key={offer.id}
              onClick={() => redirectToOffer(offer.id)}
            >
              {`${text('offer')}: ${offer.name} ${offer.formatedDeadline}`}
            </OfferDeadLine>
          );
        })}
        {deadlinesToShow.map((deadline, i) => {
          return (
            <EventBoxDeadLine key={i}>
              {`${deadline.name} ${deadline.eventDate}`}
            </EventBoxDeadLine>
          );
        })}
        <ReadMoreContainer>
          <ReadMore
            onClick={() => {
              if (
                appStore.tenantSettings.customerJourney2_showFooter &&
                !!calendarStore.calendarEvents.length
              )
                changeParams({ schedule: 'true' });
              else routingStore.push('/order-confirmation');
            }}
          >
            {text('seeMore')}
          </ReadMore>
        </ReadMoreContainer>
      </EventBoxDeadLineContainer>
    </EventBoxContainer>
  );
};

export default EventBox;
