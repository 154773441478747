import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { text } from '../../utils';
import arrowLeft from '../../components/Icons/arrow_left.svg';
import attachmentsStore from '../../store/AttachmentsStore';
import { SignableAttachment } from '../../store/interfaces/Attachment';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import Progress from '../../components/Progress';
import appStore from '../../store/AppStore';
import { signDocument } from '../../axios';
import { GBButton } from '../../components/Button/GBButton';
import alertStore from '../../store/AlertStore';
import Done from '../../components/Icons/Done';

const StyledContainer = styled.div`
  margin: 20px;

  .header {
    margin: 20px 0;
    display: flex;
    position: relative;
    justify-content: center;
  }
  
  h4 {
    margin: 10px 0 0;
    opacity: 0.8;
  }

  .back-button {
    position: absolute;
    padding-top: 11px;
    left: 0;
  }

  .heading-container {
    text-align: center;
  }

  .web-viewer {
    height: calc(100vh - 30.5rem);

    &.loading: {
      display: none;
    }
  }

  .bottom-buttons {
    text-align: center;
    margin: 15px 0;

    button {
      text-transform: uppercase;
    }
  }
'`;

const viewerConfig = {
  path: '/webviewer/lib',
  licenseKey: process.env.PDFTRON_LICENSE_KEY,
  fullAPI: true,
  disabledElements: [
    'ribbons',
    'textFieldToolGroupButton',
    'header',
    'toolsHeader',
  ],
};

const SignableDocument: React.FC = () => {
  const document: SignableAttachment =
    attachmentsStore.signableDocument as SignableAttachment;
  const { name, url, xfdf, id, signed } = document;

  const viewerDiv = useRef<HTMLDivElement | null>(null);
  const [instance, setInstance] = useState<WebViewerInstance | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [signature, setSignature] = useState<string | null>(null);
  const [signedDocumentUrl, setSignedDocumentUrl] = useState<string | null>(
    null
  );
  const [signing, setSigning] = useState<boolean>(false);

  const documentLoaded = (instance: WebViewerInstance) => {
    instance.Core.annotationManager
      .importAnnotations(xfdf)
      .then(() => setLoading(false));
  };

  const annotationChanged = (instance: WebViewerInstance, action: string) => {
    if (action === 'delete') {
      setSignature(null);
      return;
    }
    instance.Core.annotationManager
      .exportAnnotations()
      .then(async (annotXfdf: string) => {
        if (signed) {
          // document loaded and its signed
          setSignedDocumentUrl(await createDownloadUrlWithSignature(instance));
        } else if (annotXfdf !== xfdf) {
          // signature changed
          setSignature(annotXfdf);
        }
        setInstance(instance);
      });
  };

  const signButtonClicked = async () => {
    if (!signature) return;
    setSigning(true);

    const { tenantId, organisationId, projectId } = appStore;
    try {
      await signDocument(organisationId, tenantId, projectId, id, signature);
    } catch (e) {
      setSigning(false);
      alertStore.show(text('errors.signingFailed'));
      return;
    }

    setSignedDocumentUrl(
      await createDownloadUrlWithSignature(instance as WebViewerInstance)
    );
    setSigning(false);

    alertStore.show(
      text('signingSuccess'),
      '',
      7000,
      <Done />,
      'var(--custom-colorNotif3)'
    );
  };

  const createDownloadUrlWithSignature = async (
    instance: WebViewerInstance
  ) => {
    const { annotationManager, documentViewer } = instance.Core;

    const xfdfString = await annotationManager.exportAnnotations();
    const data = await documentViewer.getDocument().getFileData({ xfdfString });
    const arr = new Uint8Array(data);
    const url = URL.createObjectURL(
      new Blob([arr], { type: 'application/pdf' })
    );

    return url;
  };

  const renderButtons = () => {
    // No buttons, if document not signed yet
    if (!signedDocumentUrl && !signature) return null;

    if (signedDocumentUrl) {
      return (
        <GBButton onClick={() => window.open(signedDocumentUrl)}>
          {text('download')}
        </GBButton>
      );
    }
    return (
      <GBButton loading={signing} onClick={async () => signButtonClicked()}>
        {text('sign')}
      </GBButton>
    );
  };

  useEffect(() => {
    if (instance || !viewerDiv.current || loading) return;

    setLoading(true);

    WebViewer(viewerConfig, viewerDiv.current).then(
      (instance: WebViewerInstance) => {
        setInstance(instance);

        const { Core, UI } = instance;
        const { annotationManager, documentViewer } = Core;

        UI.loadDocument(url, { filename: name });

        documentViewer.addEventListener('documentLoaded', () =>
          documentLoaded(instance)
        );

        annotationManager.addEventListener(
          'annotationChanged',
          (annotations: any[], action: string) => {
            const annot = annotations.length ? annotations[0] : null;
            if (!annot || annot.Subject !== 'Signature') return;
            annotationChanged(instance, action);
          }
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <StyledContainer className={loading ? 'loading' : ''}>
      <div className="header">
        <GBButton
          className="back-button"
          onClick={() => {
            attachmentsStore.signableDocument = null;
            if (signature) attachmentsStore.fetchSignableAttachments();
          }}
        >
          <img src={arrowLeft} alt="left-arrow" />
        </GBButton>
        <div className="heading-container">
          <h2>
            {signedDocumentUrl ? text('signedDocument') : text('signDocument')}
          </h2>
          <h4>{name}</h4>
        </div>
      </div>

      {loading && <Progress />}

      <div
        ref={viewerDiv}
        className={`web-viewer${loading ? ' loading' : ''}`}
        data-testid="viewer-div"
      />

      <div className="bottom-buttons">{renderButtons()}</div>
    </StyledContainer>
  );
};

export default SignableDocument;
