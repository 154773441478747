import styled from 'styled-components/macro';
import { mediaSmallMax } from '../Styled/media';

export default styled.button<{ color?: string; invertColors?: boolean }>`
  --btnColor: ${({ color }) => color || 'var(--custom-colorAccent)'};
  color: ${({ invertColors }) => (!invertColors ? 'var(--btnColor)' : 'white')};
  background-color: ${({ invertColors }) =>
    invertColors ? 'var(--btnColor)' : 'white'};
  padding: 1.5rem;
  border: 2px solid var(--btnColor);
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  font-family: var(--custom-fontNameMedium);
  font-size: 18px;
  line-height: 22px;
  transition: all 0.2s ease-in-out;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.2s ease-in-out;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: white;
      background-color: var(--btnColor);

      &:after {
        background-color: ${({ invertColors }) =>
          invertColors ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0)'};
      }
    }
  }

  &.visible-on-mobile {
    display: none;
  }

  @media ${mediaSmallMax} and (hover: hover) and (pointer: fine) {
    font-size: 12px;
    line-height: 15px;
    padding: 9px;

    border: 1px solid;

    &.larger-mobile {
      padding: 1rem;
    }

    &.visible-on-mobile {
      display: block;
    }

    &:hover {
      color: ${({ invertColors }) =>
        !invertColors ? 'var(--btnColor)' : 'white'};
      background-color: ${({ invertColors }) =>
        invertColors ? 'var(--btnColor)' : 'white'};

      &:after {
        background-color: ${({ invertColors }) =>
          !invertColors ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0)'};
      }
    }
  }
`;
