import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import offersStore from '../../store/OffersStore';
import OffersConfirmationDropdown from './OffersConfirmationDropdown';
import { text } from '../../utils';
import { Offer } from '../../store/models/Offer';
import { scrollTo } from '../../utils';
import { Element } from 'react-scroll';
import { changeParams } from '../../store/RoutingStore';
import InfoBox from '../InfoBox/InfoBox';
import appStore from '../../store/AppStore';
import { tenantsUsingInfoBoxes } from '../../utils/tenantsUtils';

interface OffersConfirmationProps {
  offers: Offer[];
  hideConfirmationGuide?: boolean;
}
const OffersConfirmation = ({
  offers,
  hideConfirmationGuide,
}: OffersConfirmationProps) => {
  useEffect(() => {
    if (offersStore.selectedOffer)
      scrollTo(`offer${offersStore.selectedOffer}`, true);
    offersStore.selectedOffer = null;
  });
  return (
    <>
      {!!offers.length &&
        !hideConfirmationGuide &&
        tenantsUsingInfoBoxes.includes(appStore.organisationId) && (
          <div data-testid="info-box">
            <InfoBox
              styles={{ margin: '1rem 3rem' }}
              title={text('confirmationGuideTitle')}
              description={text('confirmationGuideDescription')}
              link={text('readTermsAndConditions')}
              onClickLink={() =>
                changeParams({ offersTermsAndConditions: 'true' })
              }
            />
          </div>
        )}
      {offers
        .sort((a, b) => {
          if ((a.isOpen && !b.isOpen) || (a.deadline && !b.deadline)) return -1;
          if ((b.isOpen && !a.isOpen) || (b.deadline && !a.deadline)) return 1;
          if (a.deadline && b.deadline) {
            const aDate = new Date(a.deadline);
            const bDate = new Date(b.deadline);

            return aDate < bDate ? -1 : 1;
          }
          return 1;
        })
        .map(offer => {
          return (
            <Element key={offer.id} name={`offer${offer.id}`}>
              <OffersConfirmationDropdown
                offersStore={offersStore}
                openAtStart={
                  offer.id === offersStore.selectedOffer ? true : false
                }
                offer={offer}
                key={offer.id}
              />
            </Element>
          );
        })}
    </>
  );
};

export default observer(OffersConfirmation);
