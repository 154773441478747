import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  StyledPopUp,
  StyledHeading,
  StyledContainer,
  StyledButton,
  StyledImage,
  StyledMessage
} from './CookiePopUpStyledComponent';
import appStore from '../../store/AppStore';
import { text } from '../../utils';

const CookiePopUP = () => {
  return !appStore.userHasAcceptedCookies ? (
    <StyledPopUp>
      <StyledContainer>
        <StyledImage />
        <StyledHeading>{text('cookieDialogHeading')}</StyledHeading>
        <StyledMessage>{text('cookieDialogBodyText')}</StyledMessage>
        <StyledButton
          onClick={() =>
            appStore.setCookie(appStore.userHasAcceptedCookiesKey, 'true')
          }
        >
          {text('confirm')}
        </StyledButton>
      </StyledContainer>
    </StyledPopUp>
  ) : null;
};

export default observer(CookiePopUP);
