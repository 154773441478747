import { observable, computed, action } from 'mobx';
import { text } from '../../utils';
import { Decimal } from 'decimal.js';
import { OfferRow } from './OfferRow';
import offersStore from '../OffersStore';
import OfferState from '../enums/OfferState';
import { getFormattedDateBasedOnLocale } from '../../utils/dates';
import DateFormat from '../enums/DateFormat';

export interface OfferData {
  id: number;
  name: string;
  description: string | null;
  offerState: string | null;
  offerId: number | null;
  offerName: string | null;
  totalGrossPrice: string | null;
  offeredOn: string | null;
  deadline: string | null;
  rows: OfferRow[];
  state: OfferState;
  signabledocumentId?: number | null;
}

export class Offer {
  @observable public id: number;
  @observable public name: string;
  @observable public description: string | null;
  @observable public offerId: number | null;
  @observable public offerName: string | null;
  @observable public totalGrossPrice: string | null;
  @observable public offeredOn: string | null;
  @observable public deadline: string | null;
  @observable public rows: OfferRow[];
  @observable public state: OfferState;
  @observable public signabledocumentId: number | null;

  constructor({
    id,
    name,
    description,
    offerId,
    offerName,
    totalGrossPrice,
    offeredOn,
    deadline,
    rows,
    state,
    signabledocumentId,
  }: OfferData) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.offerId = offerId;
    this.offerName = offerName;
    this.totalGrossPrice = totalGrossPrice;
    this.offeredOn = offeredOn;
    this.deadline = deadline;
    this.rows = rows.map(r => new OfferRow(r));
    this.state = state;
    this.signabledocumentId = signabledocumentId || null;
  }

  @computed
  get formatedPrice() {
    return this.totalGrossPrice
      ? new Decimal(parseFloat(this.totalGrossPrice))
      : null;
  }

  @computed
  get acceptedRows() {
    return this.rows.filter(row => row.isAccepted);
  }

  @computed
  get hasChanges() {
    let res = false;
    this.rows.forEach(row => {
      if (!!row.changes.length) res = true;
    });
    return res;
  }

  @computed
  get formatedDeadline() {
    return this.deadline
      ? getFormattedDateBasedOnLocale(this.deadline, DateFormat.COMPACT)
      : '';
  }

  @computed
  get formatedOfferedOn() {
    return this.offeredOn
      ? getFormattedDateBasedOnLocale(this.offeredOn, DateFormat.COMPACT)
      : '';
  }

  @computed
  get isAccepted() {
    return this.state === OfferState.ACCEPTED_BY_CUSTOMER ||
      this.state === OfferState.APPLIED_TO_DATABASE
      ? true
      : false;
  }

  @computed
  get isOpen() {
    return this.state === OfferState.SENT_TO_CUSTOMER ? true : false;
  }

  @computed
  get isRejected() {
    return this.state === OfferState.CLOSED ? true : false;
  }

  @computed
  get isWaitingForSignature() {
    return this.state === OfferState.WAITING_FOR_SIGNATURE ? true : false;
  }

  @computed
  get normalizedState() {
    return this.isAccepted
      ? text('accepted')
      : this.isOpen
      ? text('openOffer')
      : this.isWaitingForSignature
      ? text('waitingForSignature')
      : text('rejected');
  }

  @action
  public getSelectedRowsPrice = () => {
    const ids = offersStore.getAcceptedRows(this.id)?.map(r => r.id);
    return new Decimal(
      this.rows.reduce((pre, cur) => {
        if (ids?.includes(cur.id)) {
          return pre + Number(cur.totalGrossPrice);
        } else {
          return pre;
        }
      }, 0)
    );
  };
}
