import React from 'react';
import { changeParams } from '../../store/RoutingStore';
import Modal from '../../components/Modal/Modal';
import ModalEmbed from '../../components/ModalEmbed/ModalEmbed';

const AttachmentModal = ({
  param,
  embed
}: {
  param: string;
  embed: string;
}) => (
  <Modal
    closeBtnOffset="3rem"
    onClose={() => changeParams({ [param]: undefined })}
  >
    <ModalEmbed src={embed} />
  </Modal>
);

export default AttachmentModal;
