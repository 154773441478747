import styled from 'styled-components/macro';
import { mediaSmallMax } from '../Styled/media';
import {
  SectionTitleDivider,
  SectionTitle,
} from '../Styled/SectionTitleDivider';
import SectionResizer from '../SectionResizer/SectionResizer';

export const RoomDivider = styled(SectionTitleDivider)`
  padding: 0;
  height: 92px;
  padding: 0 3rem;

  &:before {
    display: none;
  }

  @media ${mediaSmallMax} {
    height: auto;
  }

    @media (hover: hover) and (pointer: fine){
    &:hover {
      color: #ffff;
      & svg {
        fill: white !important;
      }
    }
  }
  }
`;

export const ConfirmationPhaseTitle = styled(SectionTitle)`
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  font-family: var(--custom-fontNameMedium);
  margin-right: 2rem;

  @media ${mediaSmallMax} {
    font-size: 18px;
    line-height: 22px;
    margin-right: 1rem;
  }
`;

export const StyledSectionResizer = styled(SectionResizer)`
  background-color: white;
`;

export const PhaseHeader = styled.div`
  margin-top: 1rem;
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
  overflow: hidden;
`;
