import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { text } from '../../utils';
import {
  Header,
  HeaderContent,
  HeaderContentWrapper,
  HeaderRow,
  HeaderLogo,
  HeaderBg,
} from './headerStyledComponents';
import appStore from '../../store/AppStore';
import { mediaMediumMax, mediaSmallMax } from '../Styled/media';
import { observer } from 'mobx-react-lite';
import EventBox from '../EventBox/EventBox';
import calendarStore from '../../store/CalendarStore';
import { CalendarEventInterface } from '../../store/interfaces/CalendarEvent';
import offersStore from '../../store/OffersStore';
import { gbDefaultLogo } from '../../store/models/TenantSettings';

const StyledMaterialsPageHeader = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  margin-top: 2rem;
  margin-bottom: 1rem;

  @media ${mediaMediumMax} {
    max-width: 30rem;
  }

  @media ${mediaSmallMax} {
    margin-top: 28px;
  }

  p {
    min-height: 8.5rem;

    @media ${mediaSmallMax} {
      min-height: 6rem;
    }
  }

  h1 {
    margin-bottom: 2rem;

    @media ${mediaSmallMax} {
      font-size: 24px;
      line-height: 26px;
    }
  }
`;

const MaterialsPageHeader = () => {
  useEffect(() => {
    calendarStore.fetchCalendarEvents();
  }, []);

  const deadlines: CalendarEventInterface[] = calendarStore.getEventDeadLinesForHeader();
  const { openOffers } = offersStore;

  return (
    <Header>
      <HeaderBg src={appStore.headerImg} alt="header" />
      <HeaderContent>
        <HeaderContentWrapper>
          <HeaderRow>
            {appStore.tenantSettings.logoUrls?.organisationLogoLightUrl &&
              !appStore.tenantSettings.customerJourney2_showHeader && (
                <HeaderLogo
                  src={
                    appStore.tenantSettings.logoUrls?.organisationLogoLightUrl
                  }
                  alt="header-logo"
                  onError={e => {
                    if (e.currentTarget.src !== gbDefaultLogo) {
                      e.currentTarget.src = gbDefaultLogo;
                    }
                  }}
                />
              )}
          </HeaderRow>
          <StyledMaterialsPageHeader>
            <h1>{text('headerTitle')}</h1>
            <p className="font-normal">{text('headerText')}</p>
          </StyledMaterialsPageHeader>
        </HeaderContentWrapper>
        {(!!deadlines.length || !!openOffers.length) &&
          appStore.tenantSettings.customerJourney2_showEventsBox && (
            <EventBox
              deadlines={{
                items: deadlines,
                showMax: 3,
              }}
              offers={{
                items: openOffers,
                showMax: 2,
              }}
            />
          )}
      </HeaderContent>
    </Header>
  );
};

export default observer(MaterialsPageHeader);
