import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import themeStore from '../../store/ThemeStore';
import ThemeCard from './ThemeCard';
import ThemeCarousel from '../ThemeCarousel/ThemeCarousel';
import ThemeSets from './ThemeSets';
import classnames from 'classnames';
import {
  mediaSmallMin,
  mediaMediumMin,
  mediaLargeMin,
  mediaSmallMax,
} from '../Styled/media';
import { withRouter } from 'react-router';
import appStore from '../../store/AppStore';
import { text } from '../../utils';
import confirmationDialogStore from '../../store/ConfirmationDialogStore';
import Theme from '../../store/models/Theme';
import { Element } from 'react-scroll';
import { triggerGlobalEvent, modalView } from '../../services';
import { EventAction } from '../../services/EventAction';
import { EventCategory } from '../../services/EventCategory';
import { ModalView } from '../../services/ModalView';

const StyledThemesGrid = styled.div`
  @media ${mediaSmallMin} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2.5rem;
  }

  @media ${mediaMediumMin} {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2.5rem;
  }

  @media ${mediaLargeMin} {
    grid-column-gap: 5rem;
  }

  display: grid;
  justify-content: space-around;
  grid-row-gap: 1.25rem;
  grid-template-columns: minmax(18rem, auto);

  margin-top: 1rem;

  &.useThemeSets {
  }
`;

const ThemeSetsDivider = styled(Element)`
  height: 4rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #e5e4e4 100%);
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: 1;
    background-color: white;
    width: 60px;
    height: 60px;
    content: '';

    @media ${mediaSmallMax} {
      width: 30px;
      height: 30px;
    }
  }

  @media ${mediaSmallMax} {
    margin-top: 1rem;
    height: 2rem;
    width: 100vw;
    margin-left: -1rem;
  }

  &.hidden {
    opacity: 0;
  }
`;

const ThemesLockedText = styled.p`
  width: 100%;
  text-align: center;

  &.top-margin {
    margin-top: 2rem;
  }
`;

interface ThemesGridProps {
  open: boolean;
  closeGrid?: () => void;
}

const canBeSelected = (theme: Theme) =>
  theme.canSelectComputed !== false || theme.selected;

const ThemesGrid = ({ open, closeGrid }: ThemesGridProps) => {
  const {
    themeSelectionOpen,
    changeThemeSelectionOpen,
    changeDisplayedTheme,
    displayedThemeIndex,
    themes,
    selectableThemes,
    themeSets,
    selectedTheme,
    deselectSubThemeSets,
    themesAreLocked,
    themesPassedDeadline,
    themesAreSelectable,
  } = themeStore;

  const onThemeCardClick = (themeId: number | null) => {
    changeDisplayedTheme(
      themeStore.selectableThemes.findIndex(t => t.id === themeId)
    );
    if (!themeSelectionOpen) changeThemeSelectionOpen(true);
  };

  const onThemeSelectionClose = (shouldCloseGrid?: boolean) => {
    if (shouldCloseGrid && closeGrid) closeGrid();
    changeThemeSelectionOpen(false);
  };

  const useThemeSets =
    appStore.tenantSettings.customerJourney2_usePackageSets &&
    themeSets.length > 1;

  const onChoose = (theme: Theme) => {
    const selectTheme = (firstSelection = false) => {
      onThemeSelectionClose(true);
      theme.onSelect(firstSelection);
    };

    const checkForConfirmation = (themeId?: number | null) => {
      if (themeId !== undefined && themeId !== theme.id) {
        const confirmationTexts = {
          confirmationText: text('confirmThemeChange'),
          okText: text('chooseNewTheme'),
          cancelText: text('cancel'),
        };

        return confirmationDialogStore.open(confirmationTexts, selectTheme);
      } else {
        return selectTheme(true);
      }
    };

    checkForConfirmation(selectedTheme?.id);
  };

  useEffect(() => {
    if (
      themeStore.selectableThemes.length === 1 &&
      themeStore.selectableThemes[0].id !== null &&
      themeStore.selectableThemes[0].canSelectComputed &&
      !themeStore.selectableThemes[0].selected &&
      !themeStore.selectedSubThemeSet?.canBeIgnored
    )
      themeStore.selectableThemes[0].isSubTheme
        ? themeStore.onSelectSubTheme(themeStore.selectableThemes[0].id)
        : themeStore.onSelectTheme(themeStore.selectableThemes[0].id);
  }, [themeStore.selectableThemes]); // eslint-disable-line

  return open ? (
    <>
      {useThemeSets && (
        <>
          <ThemeSets />
          <ThemeSetsDivider
            name="themes-grid"
            className={classnames({ hidden: themes.length === 0 })}
          />
        </>
      )}
      {themesAreLocked ? (
        <ThemesLockedText
          className={classnames({ 'top-margin': !useThemeSets })}
        >
          {text('someThemesLocked_ConfirmedBundles')}
        </ThemesLockedText>
      ) : null}

      {themesPassedDeadline && (
        <ThemesLockedText
          className={classnames({ 'top-margin': !useThemeSets })}
        >
          {text('someThemesLocked_DeadlinePassed')}
        </ThemesLockedText>
      )}

      {themesAreSelectable ? (
        <StyledThemesGrid
          className={classnames({
            useThemeSets,
          })}
        >
          {themes.map((theme, i) => (
            <ThemeCard
              title={theme.id}
              key={theme.name}
              image={theme.masterImage ? theme.masterImage.thumbnailUrl : ''}
              name={theme.name}
              canSelectComputed={canBeSelected(theme)}
              onClick={() => {
                deselectSubThemeSets();
                if (canBeSelected(theme)) {
                  modalView(ModalView.PACKAGE_SELECTOR);
                  onThemeCardClick(theme.id);
                } else {
                  triggerGlobalEvent({
                    action: EventAction.CLICK_UNSELECTABLE_PACKAGE,
                    category: EventCategory.USER,
                  });
                }
              }}
              className={classnames({
                selected: theme.selected,
                dimmed: theme.canSelectComputed === false && !theme.selected,
              })}
            />
          ))}
          {themeSelectionOpen && (
            <ThemeCarousel
              onClose={onThemeSelectionClose}
              themes={themes}
              selectableThemes={selectableThemes}
              changeDisplayedTheme={changeDisplayedTheme}
              displayedThemeIndex={displayedThemeIndex}
              onChoose={onChoose}
            />
          )}
        </StyledThemesGrid>
      ) : null}
    </>
  ) : null;
};

export default (withRouter as any)(observer(ThemesGrid));
