import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { text, scrollTo } from '../../utils';
import {
  SectionTitle,
  SectionTitleDivider,
  SectionArrow,
} from '../Styled/SectionTitleDivider';
import MaterialSection from './MaterialSection';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import materialSelectorStore from '../../store/MaterialSelectorStore';
import SectionResizer from '../SectionResizer/SectionResizer';
import themeStore from '../../store/ThemeStore';
import appStore from '../../store/AppStore';
import { Element } from 'react-scroll';
import roomsStore from '../../store/RoomsStore';
import { getParams } from '../../store/RoutingStore';
import offersStore from '../../store/OffersStore';

const materialsElementName = 'material-selector';

const StyledMaterials = styled.section`
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
  overflow: hidden;
  margin-bottom: 2rem;
`;

interface MaterialsProps {
  openAtStart?: boolean;
}

export const scrollToMaterialSelector = () =>
  scrollTo(materialsElementName, false, { offset: -88 });

export const Materials = ({ openAtStart }: MaterialsProps) => {
  const {
    materialsOpen,
    toggleMaterialsOpen,
    bundlesToShow,
    fetchBundles,
    fetchBundlesByTheme,
    allBundlesFetched,
  } = materialSelectorStore;

  const { roomId, bundleId } = getParams();

  const { selectedRoom } = roomsStore;

  const {
    selectedTheme,
    allSelectedThemeIdsFromSelectedSets,
    allSelectedThemeIds,
    themesFetched,
    selectedThemeSet,
    selectedSubThemeSet,
  } = themeStore;
  const [maxHeight, setMaxHeight] = useState('88px');

  useEffect(() => {
    if (openAtStart && !materialsOpen) toggleMaterialsOpen(true);
  }, [openAtStart]); // eslint-disable-line

  /* Material list is not shown if:
  1 - showOnlyPackageBundles is enabled and user has not selected any theme
  2 - there are available themes to select (deadline has not passed and no locked bundles conflicts) and user has not selected any theme yet.
  */

  const canShowMaterials = !(
    (appStore.tenantSettings.customerJourney2_showOnlyPackageBundles &&
      !allSelectedThemeIdsFromSelectedSets.length) ||
    (!appStore.noThemesToSelect &&
      !!themeStore.selectedThemeSet &&
      themeStore.themesAreSelectable &&
      !selectedTheme)
  );
  useEffect(() => {
    if (!materialsOpen && canShowMaterials) {
      toggleMaterialsOpen(true);
    }
    if (!canShowMaterials) {
      toggleMaterialsOpen(false);
    }
  }, [canShowMaterials, selectedTheme, themesFetched]); // eslint-disable-line

  const doFetchBundles = ({
    force = false,
    scrollToMaterials = true,
    skipParams = false,
  }) => {
    materialSelectorStore.showSpinnerFlag(true);
    if (!allBundlesFetched || force) {
      fetchBundles(
        bundleId && !skipParams ? Number(bundleId) : undefined,
        roomId && !skipParams ? Number(roomId) : undefined,
        scrollToMaterials
      );
    }
  };

  useEffect(() => {
    offersStore.fetchApartmentOffers();
    doFetchBundles({ force: true, scrollToMaterials: true });
  }, [roomId]); // eslint-disable-line

  useEffect(() => {
    fetchBundlesByTheme(allSelectedThemeIdsFromSelectedSets, true, true);
  }, [allSelectedThemeIds.length, selectedSubThemeSet, selectedThemeSet]); // eslint-disable-line

  return canShowMaterials ? (
    <Element name={materialsElementName}>
      <StyledMaterials
        id="materials"
        style={{ maxHeight, minHeight: maxHeight }}
      >
        <SectionResizer
          effectListeners={[
            materialsOpen,
            bundlesToShow,
            canShowMaterials,
            selectedRoom,
          ]}
          adjustment={materialsOpen ? '1.5rem' : '1rem'}
          setNewHeight={setMaxHeight}
        >
          <SectionTitleDivider
            open={materialsOpen}
            onClick={() => toggleMaterialsOpen()}
          >
            <SectionTitle>
              <h3>{text('materials')}</h3>
              {!materialsOpen && appStore.apartment && (
                <p>{`${text('total')}: ${appStore.apartment.cost} ${
                  appStore.currencySymbol
                }`}</p>
              )}
            </SectionTitle>
            <SectionArrow open={materialsOpen} />
          </SectionTitleDivider>
          {materialsOpen && <MaterialSection />}
        </SectionResizer>
      </StyledMaterials>
    </Element>
  ) : null;
};

export default (withRouter as any)(observer(Materials));
