import Bundle from './models/Bundle';

export const bundlesFilteredByShowRooms = (
  bundlesToFilter: Bundle[],
  showRooms?: boolean
) => (roomBundleIds: number[]) => {
  return showRooms
    ? bundlesToFilter.filter(b => roomBundleIds.includes(b.id))
    : bundlesToFilter;
};
