import React from 'react';

export default (props: any) => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16.7027 12.6602C16.0571 12.0462 15.5082 11.4129 15.1961 10.152C15.1209 9.84844 14.8105 9.66181 14.5042 9.72458C14.2119 9.78445 13.9844 10.0564 14.0534 10.3467C14.4133 11.8602 15.0731 12.7112 15.9019 13.5009C16.2679 13.8488 16.0069 14.4432 15.4924 14.4432H2.7362C2.2216 14.4432 1.9643 13.8452 2.32669 13.5009C3.58781 12.2978 4.47567 10.9497 4.47567 7.26419C4.47567 4.82167 6.54855 2.84665 9.11427 2.84665C9.62452 2.84665 10.1134 2.92495 10.5713 3.07004C10.7663 3.1318 10.9802 3.08467 11.1336 2.94949C11.4366 2.68252 11.4269 2.16353 11.0445 2.0336C10.6138 1.88724 10.1602 1.78811 9.69048 1.74135V1.10717C9.69048 0.788265 9.42955 0.527344 9.11065 0.527344C8.79175 0.527344 8.53082 0.788265 8.53082 1.10717V1.74498C5.60633 2.02764 3.3124 4.38318 3.3124 7.26419C3.3124 10.6852 2.53688 11.6962 1.52219 12.6602C1.01846 13.1422 0.859012 13.8706 1.11993 14.5156C1.38448 15.1752 2.01866 15.6028 2.7362 15.6028H15.4924C16.2099 15.6028 16.8477 15.1752 17.1086 14.5156C17.3695 13.8706 17.2101 13.1422 16.7027 12.6602Z" />
  </svg>
);
