import { observable, action, computed } from 'mobx';
import {
  fetchApartmentOffers,
  printOffer,
  updateOfferRowStates,
  getEsignDocumentFileContent,
} from '../axios';
import { Offer, OfferData } from './models/Offer';
import alertStore from './AlertStore';
import { text } from '../utils';
import { triggerGAError } from '../services';
import appStore from './AppStore';
import { OfferRow } from './models/OfferRow';
import { OfferRowChange } from './models/OfferRowChange';
import Bundle from './models/Bundle';
import { saveAs } from 'file-saver';

export class OffersStore {
  @observable public selectedOffer: number | null = null;
  @observable public apartmentOffers: Offer[] = [];
  @observable public apartmentOffersFetched: boolean = false;
  @observable public acceptedRows: Map<number | string, OfferRow[]> = new Map<
    number | string,
    any[]
  >();
  @observable public rejectedRows: Map<number | string, OfferRow[]> = new Map<
    number | string,
    any[]
  >();
  @observable public flag: boolean = false;

  @action
  public fetchApartmentOffers = async () => {
    try {
      const { data } = await fetchApartmentOffers(
        appStore.organisationId,
        appStore.tenantId,
        appStore.projectId,
        appStore.apartmentId
      );
      this.acceptedRows.clear();
      this.rejectedRows.clear();
      this.apartmentOffers = data.items.map((offer: OfferData) => {
        const newOffer = new Offer(offer);
        if (newOffer.isOpen) {
          //  Rows which belong to open offer are accepted (on client side) by default.
          newOffer.rows.forEach(r => this.acceptOfferRow(newOffer.id, r));
        }
        return newOffer;
      });
    } catch (e) {
      triggerGAError('fetching apartment offers failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.fetchingApartmentOffersFailed'));
    } finally {
      this.apartmentOffersFetched = true;
    }
  };

  @action
  public printOffer = async (offer: Offer): Promise<boolean> => {
    try {
      const resp = offer.signabledocumentId
        ? await getEsignDocumentFileContent(offer.signabledocumentId)
        : await printOffer(
            appStore.organisationId,
            appStore.tenantId,
            appStore.projectId,
            offer.id
          );

      const blob = new Blob([resp], { type: 'application/pdf' });
      saveAs(blob, `${offer.name}.pdf`);

      return true;
    } catch (e) {
      triggerGAError('printing offer', e.toString());
      console.error(e);
      alertStore.show(text('errors.printingOfferFailed'));
    }
    return false;
  };

  @action
  public updateOfferRowStates = async (offerId: string | number) => {
    const data = {
      acceptedRowIds: this.getAcceptedRows(offerId)?.map(r => r.id),
      rejectedRowIds: this.getRejectedRows(offerId)?.map(r => r.id),
    };
    try {
      await updateOfferRowStates(
        appStore.organisationId,
        appStore.tenantId,
        appStore.projectId,
        offerId,
        data
      );
    } catch (e) {
      triggerGAError('updating offer row state failed', e.toString());
      console.error(e);
      alertStore.show(text('errors.editingOfferRowFailed'));
      return false;
    }
    return true;
  };

  @action
  public acceptOfferRow = (offerId: string | number, offerRow: OfferRow) => {
    const arr = this.acceptedRows.get(offerId);
    if (arr) {
      if (!arr.includes(offerRow)) arr.push(offerRow);
    } else {
      this.acceptedRows.set(offerId, [offerRow]);
    }

    //  Delete from rejeted rows if exists
    const rejectedArr = this.rejectedRows.get(offerId);
    if (rejectedArr && rejectedArr.indexOf(offerRow) >= 0) {
      rejectedArr.splice(rejectedArr.indexOf(offerRow), 1);
    }
    this.setFlag();
  };

  @action
  public rejectOfferRow = (offerId: string | number, offerRow: OfferRow) => {
    //  Assign id to rejected offer rows
    const arr = this.rejectedRows.get(offerId);
    if (arr) {
      if (!arr.includes(offerRow)) arr.push(offerRow);
    } else {
      this.rejectedRows.set(offerId, [offerRow]);
    }
    //  Delete from accepted rows if exists

    const acceptedArr = this.acceptedRows.get(offerId);
    if (acceptedArr && acceptedArr.indexOf(offerRow) >= 0) {
      acceptedArr.splice(acceptedArr.indexOf(offerRow), 1);
    }
    this.setFlag();
  };

  @action
  public getAcceptedRows = (offerId: string | number) => {
    return this.acceptedRows.get(offerId) ? this.acceptedRows.get(offerId) : [];
  };

  @action
  public getRejectedRows = (offerId: string | number) => {
    return this.rejectedRows.get(offerId) ? this.rejectedRows.get(offerId) : [];
  };

  @action
  public setFlag = () => {
    this.flag = !this.flag;
  };

  @action
  public isOfferReadyToAccept = (
    offerId: string | number,
    offerRows: OfferRow[]
  ) => {
    let res = true;
    const acceptedOfferRowsIds = this.acceptedRows.get(offerId);
    const rejectedOfferRowsIds = this.rejectedRows.get(offerId);

    for (const row of offerRows) {
      if (
        !acceptedOfferRowsIds?.includes(row) &&
        !rejectedOfferRowsIds?.includes(row)
      ) {
        res = false;
        break;
      }
    }

    return res;
  };

  @action
  public resetSelection = (offerId: string | number) => {
    this.acceptedRows.delete(offerId);
    this.rejectedRows.delete(offerId);
    this.setFlag();
  };

  @action
  public getFirstMaterial = (
    offerId: string | number,
    bundleId: string | number
  ) => {
    return this.apartmentOffers
      .find(o => o.id === offerId)
      ?.rows.find(r => r.changes.find(c => c.bundle.id === bundleId))
      ?.changes.find(c => c.bundle.id === bundleId)?.projectMaterial;
  };

  @action
  public getRepeatedBundles(offer: Offer) {
    const rowsToCheck = this.getAcceptedRows(offer.id);
    const allBundleChanges = rowsToCheck
      ? rowsToCheck?.reduce((pre, cur) => {
          for (const change of cur.changes) {
            if (!!change.bundle) {
              pre.push(change);
            }
          }
          return pre;
        }, [] as OfferRowChange[])
      : [];

    const repeatedBundleChanges = allBundleChanges
      ? allBundleChanges.reduce((pre: any, cur: any) => {
          for (const change of allBundleChanges) {
            if (cur.id !== change.id && cur.bundle.id === change.bundle.id) {
              pre.push(cur.bundle);
            }
          }
          return pre;
        }, [] as Bundle[])
      : [];

    return repeatedBundleChanges;
  }

  @action setSelectedOffer(offer: Offer) {
    this.selectedOffer = offer.id;
  }

  @computed
  get openOffers() {
    const SHOW_OFFERS = 2;
    return this.apartmentOffers
      .filter(o => o.isOpen)
      .sort((a, b) => {
        if (a.deadline && b.deadline) {
          const aDate = new Date(a.deadline);
          const bDate = new Date(b.deadline);

          return aDate < bDate ? -1 : 1;
        }
        return 1;
      })
      .slice(0, SHOW_OFFERS);
  }

  @computed
  get totallyRejectedOffers() {
    return this.apartmentOffers.filter(o => o.isRejected);
  }

  @computed
  get acceptedAndOpenOffers() {
    return this.apartmentOffers.filter(o => !o.isRejected);
  }
}

const offersStore = new OffersStore();

export default offersStore;
