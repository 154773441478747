import React from 'react';

export default (props: any) => (
  <svg
    width="93"
    height="70"
    viewBox="0 0 93 70"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M83.4547 0.592407H9.30172C4.57625 0.592407 0.745605 4.42305 0.745605 9.14853V60.4852C0.745605 65.2107 4.57625 69.0414 9.30172 69.0414H83.4547C88.1802 69.0414 92.0109 65.2107 92.0109 60.4852V9.14853C92.0109 4.42305 88.1802 0.592407 83.4547 0.592407ZM86.3068 60.4852C86.3068 62.0578 85.0273 63.3373 83.4547 63.3373H9.30172C7.72918 63.3373 6.44968 62.0578 6.44968 60.4852V9.14853C6.44968 7.57598 7.72918 6.29649 9.30172 6.29649H83.4547C85.0273 6.29649 86.3068 7.57598 86.3068 9.14853V60.4852ZM20.7099 30.5388C26.2229 30.5388 30.692 26.0697 30.692 20.5567C30.692 15.0437 26.2229 10.5745 20.7099 10.5745C15.1969 10.5745 10.7277 15.0437 10.7277 20.5567C10.7277 26.0697 15.1969 30.5388 20.7099 30.5388ZM20.7099 16.2786C23.0689 16.2786 24.9879 18.1977 24.9879 20.5567C24.9879 22.9157 23.0689 24.8347 20.7099 24.8347C18.3509 24.8347 16.4318 22.9157 16.4318 20.5567C16.4318 18.1977 18.3509 16.2786 20.7099 16.2786ZM57.6133 20.3836L40.6742 37.3229L35.1432 31.7919C33.4724 30.1212 30.7637 30.1212 29.0931 31.7919L13.4069 47.4781C13.0096 47.8754 12.6945 48.347 12.4794 48.866C12.2644 49.385 12.1538 49.9413 12.1538 50.5031V55.4942C12.1538 56.6754 13.1115 57.6332 14.2928 57.6332H78.4637C79.645 57.6332 80.6027 56.6754 80.6027 55.4942V39.0949C80.6027 37.9604 80.1519 36.8721 79.3498 36.0698L63.6636 20.3836C61.9928 18.713 59.2841 18.713 57.6133 20.3836ZM74.8986 51.9291H17.8578V51.0938L32.118 36.8336L40.6742 45.3897L60.6384 25.4255L74.8986 39.6857V51.9291Z" />
  </svg>
);
