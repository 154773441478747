import { observable, action, computed } from 'mobx';
import { setAuthToken } from '../axios';
import jwtDecode from 'jwt-decode';
import { StartUrlParameters } from './enums/UrlParameters';

export class AuthStore {
  @observable public token: string = '';

  constructor() {
    const tokenFromLS = window.localStorage.getItem(
      StartUrlParameters.accessToken
    );
    if (tokenFromLS) this.setToken(tokenFromLS);
  }

  @action public setToken = (token: string) => {
    this.token = token;
    setAuthToken(this.token);
  };

  @computed
  get decodedToken() {
    return this.token ? jwtDecode(this.token) : undefined;
  }
}

const authStore = new AuthStore();

export default authStore;
